import classnames from 'classnames';
import styles from '../../AndMe/PaymentNetwork/PaymentNetworkSider/MerchantContractDrawer/ContractForm/PaymentMethods/PaymentMethods.module.scss';

const Draggable = ({ children, dataFormat, data, draggable }) => {
  const handleDragStart = (event) => {
    event.dataTransfer.setData(dataFormat, data);
  };

  return (
    <div
      draggable={draggable}
      onDragStart={draggable && handleDragStart}
      className={classnames({ [styles.draggable]: draggable })}
    >
      {children}
    </div>
  );
};

export default Draggable;
