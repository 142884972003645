import React, {useEffect, useState} from 'react';
import {
  Button,
  Steps,
  Upload,
  message,
  Skeleton,
  Popconfirm,
  DatePicker,
  Form,
  Tooltip,
  Switch,
  Input,
  Select, Space
} from 'antd';
import {LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
import {useMutation, useQuery} from '@apollo/client';
import {SiderContainer, Block} from '../../../../Common/Sider';
import {certificateQuery, removeCertificateMutation, updateCertificateMutation} from './query';
import SiderTitle from './SiderTitle/SiderTitle';
import styles from './CertificateSider.module.scss';
import Loader from '../../../../Common/Loader/Loader';
import {DATE_FORMAT} from '../../../../../../constants/DATES';
import {COUNTRY_LIST} from '../../../../../../constants/COUNTRIES';

const CertificateSider = ({ certificateId }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { data: { certificate } = { certificate: null }, loading: loadingCertificate } = useQuery(certificateQuery, {
    variables: {
      id: certificateId,
    },
    nextFetchPolicy: 'cache-and-network'
  });

  const [updateCertificate, { loadingUpdate }] = useMutation(updateCertificateMutation, {
    variables: {
      id: certificateId,
    },
  });

  const [removeCertificate, { loading: loadingRemove }] = useMutation(removeCertificateMutation, {
    variables: {
      id: certificateId,
    },
    update: (cache) => {
      const normalizedId = cache.identify({
        id: certificateId,
        __typename: 'Certificate',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  useEffect(() => {
    if (certificate) {
      setCurrentStep(certificate.status === 'new' ? 0 : 1);
    }
  }, [certificateId, certificate])

  const DeleteButton = () => (
    <Popconfirm
      title='Delete the certificate'
      description='Are you sure to delete this certificate?'
      onConfirm={removeCertificate}
      okButtonProps={{loading: loadingRemove}}
    >

      <Tooltip
        title={certificate.used ? 'This certificate is used by a merchant contract.' : null}
      >
        <Button
          block
          disabled={certificate?.used}
        >
          Delete certificate
        </Button>
      </Tooltip>
    </Popconfirm>
  );

  if (loadingCertificate) {
    return (
      <SiderContainer
        title={<SiderTitle certificate={certificate} />}
      >
        <Block
          title={(
            <Skeleton.Button
              title
              active
              size="small"
              shape="square"
              style={{
                width: 200,
              }}
            />
          )}
        >
          <Loader
            className={styles.loader}
          />
        </Block>
      </SiderContainer>
    );
  }

  if (certificate.pem) {
    return (
      <SiderContainer
        title={<SiderTitle certificate={certificate} />}
      >
        <Block
          title='Certificate'
        >
          <Form
            layout='vertical'
          >
            <Form.Item
              label='Enabled'
            >
              <Tooltip
                title={(() => {
                  if (certificate.used)
                    return 'This certificate is used by a merchant contract.';
                  if (!certificate.merchantIdentifier || !certificate.countryCode || !certificate.displayName || !certificate.initiativeContext) {
                    return 'Please fill all the required fields below before activating the certificate.';
                  }
                  return null;
                })()}
              >
                <Switch
                  checked={certificate.status === 'active'}
                  disabled={certificate.used || !certificate.merchantIdentifier || !certificate.countryCode || !certificate.displayName || !certificate.initiativeContext}
                  onChange={(value) => updateCertificate({
                    variables: {
                      input: {
                        status: value ? 'active' : 'deactivated',
                      }
                    }
                  })}
                />
              </Tooltip>
            </Form.Item>
            <Form.Item
              label='Expiry date'
            >
              <DatePicker
                value={certificate.expireAt ? moment(certificate.expireAt) : null}
                format={DATE_FORMAT}
                onChange={(value) => updateCertificate({
                  variables: {
                    input: {
                      expireAt: value
                    },
                  }
                })}
              />
            </Form.Item>
            <Form.Item
              label='Merchant identifier'
              required
            >
              <Input
                value={certificate?.merchantIdentifier}
                loading={!certificate}
                placeholder={certificate ? 'merchantIdentifier' : 'Loading...'}
                disabled={certificate.status === 'active'}
                onChange={(e) => updateCertificate({
                  variables: {
                    input: {
                      merchantIdentifier: e.target.value
                    }
                  },
                  context: {
                    debounceKey: `updateCertificateMerchantIdentifier-${certificate.id}`,
                    debounceTimeout: 1000,
                  },
                  optimisticResponse: {
                    updateCertificate: {
                      ...certificate,
                      merchantIdentifier: e.target.value,
                    }
                  }
                })}
              />
            </Form.Item>
            <Form.Item
              label='Country code'
              required
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                value={certificate.countryCode}
                filterOption={(input, option) =>
                  option.children.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={certificate.status === 'active'}
                onChange={(value) => updateCertificate({
                  variables: {
                    input: {
                      countryCode: value
                    }
                  },
                  optimisticResponse: {
                    updateCertificate: {
                      ...certificate,
                      countryCode: value,
                    }
                  }
                })}
              >
                {COUNTRY_LIST.map((c) => (
                  <Select.Option key={c.value} value={c.alpha2}>
                    <Space>
                      <img src={c.img} alt={c.value} width={24} />
                      {c.label}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='Display name'
              required
            >
              <Input
                value={certificate?.displayName}
                loading={!certificate}
                placeholder={certificate ? 'displayName' : 'Loading...'}
                disabled={certificate.status === 'active'}
                onChange={(e) => updateCertificate({
                  variables: {
                    input: {
                      displayName: e.target.value
                    }
                  },
                  context: {
                    debounceKey: `updateCertificateDisplayName-${certificate.id}`,
                    debounceTimeout: 1000,
                  },
                  optimisticResponse: {
                    updateCertificate: {
                      ...certificate,
                      displayName: e.target.value,
                    }
                  }
                })}
              />
            </Form.Item>
            <Form.Item
              label='InitiativeContext'
              required
            >
              <Input
                value={certificate?.initiativeContext}
                loading={!certificate}
                placeholder={certificate ? 'initiativeContext' : 'Loading...'}
                disabled={certificate.status === 'active'}
                onChange={(e) => updateCertificate({
                  variables: {
                    input: {
                      initiativeContext: e.target.value
                    }
                  },
                  context: {
                    debounceKey: `updateCertificateInitiativeContext-${certificate.id}`,
                    debounceTimeout: 1000,
                  },
                  optimisticResponse: {
                    updateCertificate: {
                      ...certificate,
                      initiativeContext: e.target.value,
                    }
                  }
                })}
              />
            </Form.Item>
          </Form>
          <DeleteButton />
        </Block>
      </SiderContainer>
    )
  }

  return (
    <SiderContainer
      title={<SiderTitle certificate={certificate} />}
    >
      <Block
        title='New certificate'
      >
        <Steps
          className={styles.steps}
          current={currentStep}
          size='small'
          direction='vertical'
          onChange={(value) => setCurrentStep(value)}
          items={[
            {
              title: <b>Download your CSR file</b>,
              description: <>
                <div>
                  The certificate signing request is utilized to obtain a secure certificate from Apple, enabling the initiation of payment acceptance through Apple Pay.
                </div>
                <a
                  href={null}
                  onClick={() => {
                    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${certificate.csr}`);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', `uploadMe.csr`);
                    document.body.appendChild(link);
                    link.click();
                    updateCertificate({
                      variables: {
                        input: {
                          status: 'incomplete',
                        }
                      }
                    })
                    setCurrentStep(1);
                  }}
                >
                  Download your certificate signing request
                </a>
              </>
            },
            {
              title: 'Visit the Apple Developer Center page',
              description: <>
                <p>
                  If you haven&apos;t already, join the <a href='https://developer.apple.com/programs/enroll/' onClick={() => setCurrentStep(2)} target='_blank' rel='noreferrer'>Apple Developer Program</a>
                </p>
                <p>
                  Within your Apple developer account, navigate to Certificates, Identifiers, and Profiles. Under Identifiers, choose Merchant IDs.
                </p>
                <p>
                  Select the specific Merchant ID to which you want to add the certificate. Click on &quot;Edit&quot; and proceed to create the certificate.                </p>
                <p>
                  Adhere to the provided instructions to upload the CSR file obtained in the preceding step.                </p>
                <p>
                  Generate the certificate by clicking on the &quot;Generate&quot; button, download it.                </p>
                <p>
                  To learn more, please visit <a href='https://developer.apple.com/documentation/passkit/apple_pay/setting_up_apple_pay' target='_blank' rel='noreferrer'>Apple&apos;s documentation</a>
                </p>
              </>
            },
            {
              title: 'Upload your certificate',
              description: (
                <>
                  <p>
                    Please upload the certificate provided by Apple
                  </p>
                  <div
                    className={styles.uploadContainer}
                  >
                    <Upload.Dragger
                      action={null}
                      status={loadingUpdate ? 'uploading' : 'done'}
                      disabled={loadingUpdate}
                      customRequest={(value) => {
                        updateCertificate({
                          variables: {
                            input: {
                              cer: value.file
                            }
                          },
                          onError: () => {
                            value.onError(null, 'An error occurred, please check your cer file')
                            message.error('An error occurred, please check your cer file')
                          },
                          onCompleted: () => message.success('Certificate ready')
                        });
                      }}
                    >
                      <div
                        style={{
                          margin: 8,
                          padding: 8,
                          color: '#777',
                        }}
                      >
                        {false ? <LoadingOutlined /> : <UploadOutlined style={{fontSize: 40}} />}
                        <div style={{ marginTop: 8 }}>Drag & Drop your certificate (*.cer) or <u>Browse</u></div>
                      </div>
                    </Upload.Dragger>
                  </div>
                </>
              )
            }
          ].map((step, index) => ({
            ...step,
            description: index === currentStep ? step.description : null,
          }))}
        />
          <DeleteButton />
      </Block>
    </SiderContainer>
  )
};

export default CertificateSider;