import React from "react";
import {useTranslation} from 'react-i18next';
import Img from "../../../../../assets/images/logo/norbr-white.svg";
import Other from './Other/Other';
import VerticalLine from './VerticalLine/VerticalLine';
import Branch from './Branch/Branch';
import styles from './Splitter.module.scss';
import {useRuleEditor} from '../ruleEditor.context';
import SplitterContent from './Content/SplitterContent/SplitterContent';

const Splitter = (props) => {
  const { splitter, col, isParentActive } = props;
  const { editedItem, setEditedItem, setIsSiderOpen } = useRuleEditor();
  const { t } = useTranslation();
  const isActive = editedItem?.edition === 'splitter' && editedItem?.splitter?.id === splitter?.id;

  return (
    <>
      <div
        key={splitter.id}
        className={styles.splitter}
        style={{
          gridColumnStart: col,
        }}
      >
        <div
          role='presentation'
          className={styles.contentContainer}
          onClick={splitter.id === 'splitterTemp' ? () => null : () => {
            setEditedItem({
              splitter,
              edition: 'splitter',
            });
            setIsSiderOpen(true)
          }}
        >
          <SplitterContent
            splitter={splitter}
            id={splitter.id}
            img={Img}
            title={t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${splitter.type}.label`)}
            type={splitter.type}
            isActive={isActive}
            isParentActive={isParentActive}
          />
        </div>
        {splitter.id !== 'splitterTemp' && (
          <VerticalLine
            splitter={splitter}
            isActive={isActive || isParentActive}
          />
        )}
      </div>
      <Other
        splitter={splitter}
        col={col+2}
        isParentActive={isActive || isParentActive}
      />
      { splitter.branches.map((branch) =>
        <Branch
          key={branch.id}
          branch={branch}
          col={col}
          splitter={{
            ...splitter,
            branch,
          }}
          isParentActive={isActive || isParentActive}
        />
      )}
    </>
  )
};

export default Splitter;