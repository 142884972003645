import React, { useContext, useState } from 'react';
import { Input, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MerchantContractContext } from '../MerchantContractDrawer';
import { ContractEditionContext } from '../../../PaymentNetwork';
import styles from './SiderTitle.module.scss';

const SiderTitle = () => {
  const { merchantContract, updateMerchantContract } = useContext(MerchantContractContext);
  const { isEdited } = useContext(ContractEditionContext);

  const [edit, setEdit] = useState(false);

  if (!merchantContract) {
    return (
      <div className={styles.root}>
        <Skeleton.Input
          active
          loading
          style={{
            width: 100,
            height: '22px',
            verticalAlign: 'middle',
          }}
        />
      </div>
    );
  }

  const merchantContractName = merchantContract?.name === '' ? 'Not named contract' : merchantContract?.name;

  return (
    <div className={styles.root}>
      {edit && isEdited ? (
        <Input
          value={merchantContract?.name}
          onChange={(event) =>
            updateMerchantContract({
              variables: {
                id: merchantContract?.id,
                input: {
                  name: event.target.value,
                },
              },
              context: {
                debounceKey: `updateMerchantContractName-${merchantContract.id}`,
                debounceTimeout: 1000,
              },
              optimisticResponse: {
                updateContract: {
                  ...merchantContract,
                  name: event.target.value,
                },
              },
            })
          }
          onBlur={() => setEdit(false)}
        />
      ) : (
        merchantContractName
      )}
      {isEdited && <EditOutlined className={styles.icon} onClick={() => setEdit(!edit)} />}
    </div>
  );
};

export default SiderTitle;
