import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Col, List, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SourceBranchIcon from '@2fd/ant-design-icons/lib/SourceBranch';
import { useQuery } from '@apollo/client';
import fallbackErrorCodes from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/codes';
import { isEqual } from '../../../../../util/object';
import { useCan } from '../../../../../contexts/ability.context';
import Page from '../../../Common/Page/Page';
import Loader from '../../../Common/Loader/Loader';
import MerchantAccountSelector from '../../MerchantAccountSelector/MerchantAccountSelector';
import { useMerchantAccount } from '../../merchantAccount.context';
import NavButtons from '../NavButtons';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsQuery } from '../query';
import DrawerForm from './DrawerForm';
import { formatEndUnderline } from '../../../Brainpower/Common/utils';

const AuthorizationFallbacks = () => {
  const can = useCan();
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data, loading } = useQuery(FallbackSettingsQuery, {
    variables: { id: selectedMerchantAccount },
    skip: !selectedMerchantAccount,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fallbackSettings = data?.merchantAccount.fallbackSettings;

  const description = useMemo(() => {
    const countDiff = fallbackSettings?.authorization_error_codes
      ? Object.keys(fallbackSettings.authorization_error_codes).reduce(
          (count, code) =>
            !isEqual(fallbackSettings.authorization_error_codes[code], fallbackErrorCodes[code].default)
              ? count + 1
              : count,
          0,
        )
      : 0;
    if (countDiff === 0) return 'Default settings';
    if (countDiff === 1) return '1 error code rule modified';
    return `${countDiff} error codes rules modified`;
  }, [fallbackSettings]);

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <SourceBranchIcon />
          {formatEndUnderline('Optimizer')}
        </Space>
      }
      headerContent={<MerchantAccountSelector />}
      centerHeader
    >
      <div className={classNames({ [styles.noMerchantPage]: !selectedMerchantAccount })}>
        {!selectedMerchantAccount && (
          <div className={styles.noMerchantMessage}>Please select a merchant account in the selector above</div>
        )}
        {!data ? (
          <div className={styles.loader}>
            <Loader size="large" />
          </div>
        ) : (
          [
            <NavButtons key="nav-buttons" />,
            <Row key="body" className={styles.rowList} justify="space-around">
              <Col md={16} lg={12} xl={8}>
                {can('update', 'fallback-settings') && (
                  <Button className={styles.editButton} icon={<EditOutlined />} size="large" block onClick={handleOpen}>
                    Edit settings
                  </Button>
                )}
                <List loading={loading}>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="If authorization failed"
                      description={`Fallback activated : ${fallbackSettings.authorization_failed ? 'Yes' : 'No'}`}
                    />
                  </List.Item>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta title="If authorization is declined" description={description} />
                  </List.Item>
                </List>
              </Col>
            </Row>,
          ]
        )}
      </div>
      <DrawerForm onClose={handleClose} open={open} />
    </Page>
  );
};

export default AuthorizationFallbacks;
