import React, { useState } from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import TextInputElem from '../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import ButtonElem from '../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { addRuleMutation } from './query';
import useNavigateWithSearch from "../../../../../../util/navigate";

const RuleForm = ({ ...props }) => {
  const navigate = useNavigateWithSearch();

  const { selectedMerchantAccount } = useMerchantAccount();

  const [addRule, {loading: loadingAdd}] = useApolloMutation(addRuleMutation);

  const [label, setLabel] = useState('');

  return (
    <Column id="ruleFormColumn" {...props}>
      <TextInputElem
        autoFocus
        id="ruleLabel"
        key="ruleLabel"
        title="Rule label"
        value={label}
        handleChange={(e) => setLabel(e.target.value)}
      />
      {label && (
        <ButtonElem
          handleClick={() =>
            addRule({
              variables: {
                id: selectedMerchantAccount,
                input: {
                  name: label,
                },
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify({
                    id: selectedMerchantAccount,
                    __typename: 'MerchantAccount',
                  }),
                  fields: {
                    rules() {
                      return data.addRule.rules;
                    },
                  },
                });
              },
            }).then(({ data }) => {
              navigate(
                `/router/payment-network/rule/${data.addRule.rules[data.addRule.rules.length - 1].id}`,
              );
            })
          }
          title="Create"
          disabled={loadingAdd}
        />
      )}
    </Column>
  );
};

export default RuleForm;
