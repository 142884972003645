import React from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import Column from '../../../../../Common/NavigationContainer/Column/Column';
import TextInputElem from '../../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import { updateMerchantContractConfiguration } from '../query';
import Elem from '../../../../../Common/NavigationContainer/Column/Elem/Elem';
import {useMerchantAccount} from '../../../../merchantAccount.context';
import SelectElem from '../../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import BoolElem from '../../../../../Common/NavigationContainer/Column/BoolElem/BoolElem';

const PartnerConfigurationForm = ({ merchantContract, ...props }) => {
  const { merchantAccount } = useMerchantAccount();

  const partnerConfiguration = merchantContract?.partner?.partner_data_configuration ?? {};

  const contractConfiguration = merchantContract?.configuration ?? {};

  const [updateContract] = useApolloMutation(updateMerchantContractConfiguration);

  const updateContractConfiguration = (configuration) => updateContract({
    variables: {
      id: merchantContract.id,
      input: {
        configuration,
      },
    },
    optimisticResponse: {
      updateContract: {
        ...merchantContract,
        configuration,
      },
    },
    context: {
      debounceKey: `updateMerchantContractConfiguration-${merchantContract.id}`,
      debounceTimeout: 500,
    },
  });

  return (
    <Column id="partnerConfigurationForm" key="partnerConfigurationForm" {...props}>
      {Object.keys(partnerConfiguration).filter(fieldKey => {
        const field = partnerConfiguration[fieldKey];
        return field.sources[merchantAccount.source] !== 'excluded';
      }).map((fieldKey, index) => {
        const field = partnerConfiguration[fieldKey];
        const commonProps = {
          autoFocus: index === 0,
          key: fieldKey,
          title: field.sources[merchantAccount.source] === 'required' ? (
            <>
              {fieldKey}&nbsp;
              <span
                style={{
                  color: '#B40505',
                }}
              >*</span>
            </>
          ) : fieldKey,
          value: contractConfiguration?.[fieldKey],

        };
        switch (field.type) {
          case 'String':
            return (
              <TextInputElem
                {...commonProps}
                handleChange={(event) => {
                  updateContractConfiguration({
                    ...contractConfiguration,
                    [fieldKey]: event.target.value,
                  })
                }}
              />
            );
          case 'Boolean':
            return (
              <BoolElem
                {...commonProps}
                handleChange={(value) => {
                  updateContractConfiguration({
                    ...contractConfiguration,
                    [fieldKey]: value,
                  })
                }}
              />
            );
          case 'Enum':
            return (
              <SelectElem
                {...commonProps}
                options={field.enum.map(elem => ({
                  id: elem,
                  name: elem,
                }))}
                handleChange={(value) => {
                  updateContractConfiguration({
                    ...contractConfiguration,
                    [fieldKey]: value,
                  })
                }}
              />
            );
          default:
            return (
              <Elem
                title='Field type not handled yet'
              />
            )
        }
      })}
    </Column>
  );
};

export default PartnerConfigurationForm;
