import React, { useContext, useEffect } from 'react';
import { useQuery as useApolloQuery } from '@apollo/client';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Block } from '../../../../../Common/Sider';
import styles from './PaymentMethodForm.module.scss';
import { paymentMethodQuery } from './query';
import { MerchantContractContext } from '../MerchantContractDrawer';
import RoutesForm from './RoutesForm/RoutesForm';
import usePaymentMethods from '../../../../../../../hooks/common/usePaymentMethods';
import Item from '../../../../../Common/SiderList/Item';

export const PaymentMethodContext = React.createContext(null);

const PaymentMethodForm = ({ editedPaymentMethod, setEditedPaymentMethod }) => {
  const { t } = useTranslation();
  const paymentMethods = usePaymentMethods();

  const { merchantContract } = useContext(MerchantContractContext);

  useEffect(() => {
    if (!merchantContract?.payment_methods?.find((paymentMethod) => paymentMethod.id === editedPaymentMethod)) {
      setEditedPaymentMethod(false);
    }
  }, [merchantContract?.payment_methods]);

  const { data, loading } = useApolloQuery(paymentMethodQuery, {
    variables: {
      apiName: editedPaymentMethod,
      partnerRouteFilters: {
        channel: merchantContract?.channels,
        partner: merchantContract?.partner.id,
        settlement_currency: merchantContract?.settlement_currency,
      },
    },
  });

  return (
    <Block title="Payment methods" description="Detailed configuration" className>
      <PaymentMethodContext.Provider value={data?.paymentMethodByApiName}>
        <div className={styles.returnRow}>
          <ArrowLeftOutlined className={styles.returnArrow} onClick={() => setEditedPaymentMethod(false)} />
          <div className={styles.paymentMethodName}>
            <Item
              id={editedPaymentMethod}
              label={t(`andMe.paymentNetwork.merchantContract.payment_methods.${editedPaymentMethod}`)}
              color="rgba(0, 0, 0, 0)"
              icon={() => <img src={paymentMethods[editedPaymentMethod]?.imgUrl} alt="" width={51} />}
              onClick={() => setEditedPaymentMethod(editedPaymentMethod)}
              noPadding
            />
          </div>
        </div>
        <RoutesForm loading={loading} />
      </PaymentMethodContext.Provider>
    </Block>
  );
};

export default PaymentMethodForm;
