import React, { useContext, useEffect } from 'react';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Elem from '../../../../../Common/NavigationContainer/Column/Elem/Elem';
import { contractsByPartnerQuery } from './query';
import { useMerchantAccount } from '../../../../merchantAccount.context';
import MerchantContracts from './MerchantContracts/MerchantContracts';
import { ContractEditionContext } from '../../../PaymentNetwork';

export const PartnerContext = React.createContext(null);

const Partner = ({ partner, closeAddPartner, type, ...props }) => {
  const { t } = useTranslation();
  const { selectedMerchantAccount } = useMerchantAccount();
  const { setEditedContractId } = useContext(ContractEditionContext);

  const [
    getContractsByPartner,
    {
      data: { merchantAccount: { merchant_contracts: merchantContracts } } = {
        merchantAccount: {
          merchant_contracts: [],
        },
      },
      loading: loadingContracts,
    },
  ] = useApolloLazyQuery(contractsByPartnerQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      getContractsByPartner({
        variables: {
          partner: partner.id,
          merchantAccountId: selectedMerchantAccount,
        },
        fetchPolicy: 'cache-first',
      });
    }
  }, [selectedMerchantAccount]);

  return (
    <PartnerContext.Provider value={partner}>
      <Elem
        title={partner.name}
        value={
          loadingContracts
            ? 'Contracts loading'
            : t('andMe.paymentNetwork.merchantContract.count', { count: merchantContracts?.length })
        }
        subColumn={
          <MerchantContracts
            merchantContracts={merchantContracts}
            partner={partner}
            loading={loadingContracts}
            type={type}
          />
        }
        handleClick={() => {
          closeAddPartner();
          setEditedContractId(null)
        }}
        {...props}
      />
    </PartnerContext.Provider>
  );
};

export default Partner;
