import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Link } from '../../../../util/navigate';
import styles from './CustomNavTabs.module.scss';

const NavTab = ({ to, active, children }) => (
  <Link to={to}>
    <div className={classnames(styles.navBtn, { [styles.activeNavBtn]: active })}>
      <div>{children}</div>
    </div>
  </Link>
);

const CustomNavTabs = ({ items = [] }) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.navContainer}>
      {items.map(({ path, label }) => (
        <NavTab key={path} to={`../${path}`} active={pathname.includes(path)}>
          {label}
        </NavTab>
      ))}
    </div>
  );
};

export default CustomNavTabs;
