import React, { useContext } from 'react';
import Column from '../../../../../../../../Common/NavigationContainer/Column/Column';
import { ContractEditionContext } from '../../../../../../PaymentNetwork';
import ActionButtonElem from '../../../../../../../../Common/NavigationContainer/Column/ActionButtonElem/ActionButtonElem';

const MerchantContractButtons = ({ ...props }) => {
  const { isEdited, setIsEdited, isSaving } = useContext(ContractEditionContext);

  const getTitle = () => {
    if (isSaving) {
      return 'Saving...';
    }
    if (isEdited) {
      return 'Saved !';
    }
    return 'Edit';
  };

  return (
    <Column {...props}>
      <ActionButtonElem handleClick={() => setIsEdited(!isEdited)} title={getTitle()} />
    </Column>
  );
};

export default MerchantContractButtons;
