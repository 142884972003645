import React, {useContext, useEffect, useState} from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import Column from '../../../../../../Common/NavigationContainer/Column/Column';
import MerchantContract from './MerchantContract/MerchantContract';
import LoadingElem from '../../../../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';
import { createContractMutation } from '../../../query';
import { useCan } from '../../../../../../../../contexts/ability.context';
import ButtonElem from '../../../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';
import {useMerchantAccount} from '../../../../../merchantAccount.context';
import {PartnerContext} from '../Partner';

const MerchantContracts = ({ loading, merchantContracts, type, ...props }) => {
  const can = useCan();
  const [searchParams] = useSearchParams();
  const selectedMerchantContract = searchParams.get('pn').split('-').slice(-1)[0];
  const { selectedMerchantAccount } = useMerchantAccount();
  const partner = useContext(PartnerContext);

  const [activeOnCreate, setActiveOnCreate] = useState(false);

  useEffect(() => {
    if (merchantContracts) {
      setActiveOnCreate(true);
    }
  }, [merchantContracts]);

  const [createContract, { loading: loadingAdd }] = useApolloMutation(createContractMutation);

  return (
    <Column id="merchantContracts" {...props}>
      {can('create', 'merchant_contract') && (
        <ButtonElem
          title="Add new contract"
          handleClick={() =>
            createContract({
              variables: {
                id: selectedMerchantAccount,
                partner: partner?.id,
              },
            })
          }
        />
      )}
      {(loading || loadingAdd) && <LoadingElem />}
      {[...merchantContracts]
        ?.sort((a, b) => (b.status !== a.status ? (b.status === 'active' ? 1 : -1) : b.name < a.name ? 1 : -1))
        ?.map((merchantContract) => (
          <MerchantContract
            id={merchantContract.id}
            key={merchantContract.id}
            merchantContract={merchantContract}
            activeOnCreate={selectedMerchantContract === merchantContract.id && activeOnCreate}
            type={type}
          />
        )) ?? <LoadingElem id="loadingContracts" key="loadingContracts" {...props} />}
    </Column>
  );
};

export default MerchantContracts;
