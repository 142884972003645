import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethodBranchTitle from './PaymentMethodBranchTitle/PaymentMethodBranchTitle';
import AuthorizationCurrencyBranchTitle from './AuthorizationCurrencyBranchTitle/AuthorizationCurrencyBranchTitle';
import CountryBranchTitle from './CountryBranchTitle/CountryBranchTitle';
import IssuerBranchTitle from './IssuerBranchTitle/IssuerBranchTitle';
import ListBranchTitle from './ListBranchTitle/ListBranchTitle';
import PaymentChannelBranchTitle from './PaymentChannelBranchTitle/PaymentChannelBranchTitle';
import {capitalize} from '../../../../../../../../util/string';

const BranchTitle = (props) => {
  const { branch, type, splitter } = props;
  const { t } = useTranslation();
  switch (type) {
    case 'amount':
      switch (branch.condition[0]) {
        case 'min':
          return `< ${branch.condition[1]}`;
        case 'max':
          return `>= ${branch.condition[1]}`;
        default:
          return `${branch.condition[0]} to ${branch.condition[1]}`;
      }
    case 'authorizationCurrency':
      return <AuthorizationCurrencyBranchTitle branch={branch} />;
    case 'issuerCountry':
    case 'customerIpCountry':
      return <CountryBranchTitle branch={branch} />;
    case 'issuer':
      return <IssuerBranchTitle branch={branch} />;
    case 'list':
      return <ListBranchTitle branch={branch} />;
    case 'merchantData':
      switch (splitter.merchant_data_type) {
        case 'boolean':
          return capitalize(branch.condition[0]);
        case 'string':
          return branch.condition.reduce((acc, condition) => `${acc}${condition}, `, '').slice(0, -2);
        case 'number':
          switch (branch.condition[0]) {
            case 'min':
              return `< ${branch.condition[1]}`;
            case 'max':
              return `>= ${branch.condition[1]}`;
            default:
              return `${branch.condition[0]} to ${branch.condition[1]}`;
          }
        case 'date':
          switch (branch.condition[0]) {
            case 'from':
              return `>= ${branch.condition[1]}`;
            case 'to':
              return `< ${branch.condition[1]}`;
            default:
              if (['hours', 'days', 'months'].includes(branch.condition[0])) {
                return `${parseFloat(branch.condition[1]) >= 0 ? '<' : '>='} ${Math.abs(branch.condition[1])} ${Math.abs(branch.condition[1]) === 1 ? branch.condition[0].slice(0, -1) : branch.condition[0]} ago`;
              }
              return `${branch.condition[0]} ${branch.condition[1]}`;
          }
        default:
          return branch.condition[0];
      }
    case 'merchantStoreId':
      return branch.condition.reduce((acc, condition) => `${acc}${condition}, `, '').slice(0, -2);
    case 'paymentChannel':
      return <PaymentChannelBranchTitle branch={branch} />;
    case 'paymentMethodName':
      return <PaymentMethodBranchTitle branch={branch} />;
    case 'splitter':
      return `${branch.condition[0]} %`;
    default:
      return branch.condition
        .reduce(
          (acc, condition) =>
            `${acc}${t(
              `andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${type}.options.${condition}`,
            )}, `,
          '',
        )
        .slice(0, -2);
  }
};

export default BranchTitle;
