import React from 'react';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import {Button, message} from 'antd';
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import { useTranslation } from 'react-i18next';
import styles from './SiderSplitter.module.scss';
import {Block} from '../../../../../Common/Sider';
import {removeSplitterMutation} from './query';
import {useRuleEditor} from '../../../ruleEditor.context';
import MerchantDataTypeSelector from './MerchantDataTypeSelector/MerchantDataTypeSelector';

const SiderSplitter = ({splitter}) => {
  const { t } = useTranslation();
  const { setEditedItem, setIsSiderOpen } = useRuleEditor();


  const [removeSplitter, { loading }] = useApolloMutation(removeSplitterMutation, {
    variables: {
      id: splitter.id,
    },
    update(cache, result) {
      if (splitter?.parent?.branch) {
        const normalizedId = cache.identify({ id: splitter.parent.branch.id, __typename: 'SplitterBranch' });
        cache.modify({
          id: normalizedId,
          fields: {
            next() {
              return null
            },
          },
        });
      } else if (splitter.parent?.id) {
        const normalizedId = cache.identify({ id: splitter.parent.id, __typename: 'Splitter' });
        cache.modify({
          id: normalizedId,
          fields: {
            other() {
              return null
            },
          },
        });
      } else {
        const toRemove = cache.identify({ id: splitter.id, __typename: 'Splitter' });
        cache.evict({
          id: toRemove,
        });
        cache.gc();
      }
      // Close drawer only on optimistic response, not onCompleted
      if (!result.data.removeSplitter.__typename) {
        setIsSiderOpen(false);
        setEditedItem(null);
      }
    },
    optimisticResponse: {
      removeSplitter: {
        id: splitter.id,
      }
    },
    onError: (error) => {
      if (error?.graphQLErrors?.find((e) => e.extensions.code === '404')) {
        message.error('An error has occurred, please try again later.');
      }
    },
  });

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${splitter.type}.label`)} condition`}
    >
      <div
        className={styles.blockContent}
      >
        {splitter.type === 'merchantData' && (
          <MerchantDataTypeSelector splitter={splitter} />
        )}
        <Button
          loading={loading || splitter.id === 'splitterTemp'}
          disabled={loading || splitter.id === 'splitterTemp'}
          onClick={() => removeSplitter()}
        >
          <TrashCanIcon />
          REMOVE CONDITION
        </Button>
        {splitter.type === 'merchantData' && (
          <small
            className={styles.infoText}
          >
            If the key is not found in merchant data, the condition will be ignored.
          </small>
        )}
      </div>
    </Block>
  )
};

export default SiderSplitter;