import React from 'react';
import {Input, Skeleton} from 'antd';
import {useMutation} from '@apollo/client';
import styles from './SiderTitle.module.scss';
import {updateCertificateNameMutation} from './query';

const SiderTitle = ({ certificate }) => {
  const [updateCertificateName] = useMutation(updateCertificateNameMutation);

  return (
    <div
      className={styles.root}
    >
      <Input
        value={certificate?.name}
        loading={!certificate}
        placeholder={certificate ? 'Your certificate name' : 'Loading...'}
        onChange={(e) => updateCertificateName({
          variables: {
            id: certificate.id,
            name: e.target.value,
          },
          context: {
            debounceKey: `updateCertificateName-${certificate.id}`,
            debounceTimeout: 1000,
          },
          optimisticResponse: {
            updateCertificate: {
              ...certificate,
              name: e.target.value,
            }
          }
        })}
      />
    </div>
  );
};

export default SiderTitle;