import React from 'react';
import CustomNavTabs from '../../Common/CustomNavTabs/CustomNavTabs';

const NavButtons = () => (
  <CustomNavTabs
    items={[
      { path: 'general-settings', label: 'General settings' },
      { path: 'sca-rules', label: 'SCA rules & exemptions' },
      { path: 'authorization-fallbacks', label: 'Fallbacks based on authorization results' },
      { path: 'authentication-fallbacks', label: 'Fallbacks based on authentication results' },
    ]}
  />
);

export default NavButtons;
