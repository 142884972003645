import React from 'react';
import classnames from "classnames";
import {Button, Layout, Space, message} from "antd";
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import {PageHeader} from "@ant-design/pro-layout";
import ForwardburgerIcon from "@2fd/ant-design-icons/lib/Forwardburger";
import BackburgerIcon from "@2fd/ant-design-icons/lib/Backburger";
import RoutesIcon from "@2fd/ant-design-icons/lib/Routes";
import {useParams} from "react-router-dom";
import {useQuery as useApolloQuery} from "@apollo/client/react/hooks/useQuery";
import {SettingOutlined} from '@ant-design/icons';
import {createDraftMutation, ruleQuery, saveRuleMutation, updateRuleMutation} from "./query";
import brainpowerStyles from "../../Brainpower/Brainpower.module.scss";
import {useLayoutMenu} from "../../../layout.context";
import {formatEndUnderline} from '../../Brainpower/Common/utils';
import styles from './RuleEditor.module.scss';
import SiderPage from '../../Common/SiderPage/SiderPage';
import Splitter from './Splitter/Splitter';
import HorizontalLine from './Splitter/HorizontalLine/HorizontalLine';
import RuleEditorProvider, {RuleEditorContext} from './ruleEditor.context';
import Loader from '../../Common/Loader/Loader';
import Footer from './Footer/Footer';
import Sider from './Sider/Sider';
import AddSplitterButton from './Splitter/AddSplitterButton/AddSplitterButton';
import MerchantContract from './Splitter/MerchantContract/MerchantContract';
import MerchantAccountContractsProvider from './merchantAccountContracts.context';
import SlashForwardIcon from '@2fd/ant-design-icons/lib/SlashForward';
import useNavigateWithSearch from '../../../../util/navigate';

export const getDepth = (next, depth = 0) => {
  let maxBranches = 0;
  let maxOther = 0;
  if (next?.branches?.length) {
    maxBranches = next?.branches.reduce((acc, curr) => Math.max(getDepth(curr.next, depth), acc), 0);
  }
  if (next?.other) {
    maxOther = getDepth(next?.other, depth);
  }
  return depth + Math.max(maxBranches, maxOther) + (next ? 1 : 0);
};

export const getNbRows = (splitter, currentSplitter = false) => {
  if (!splitter || (currentSplitter && !splitter.branches.length)) {
    return 0;
  }
  return (splitter.other ? getNbRows(splitter.other) : 0) + splitter.branches.reduce((acc, branch, index) => {
    if (currentSplitter && index === splitter.branches.length - 1) {
      return acc + 1;
    }
    return acc + (branch.next ? getNbRows(branch.next) + 1 : 1)
  }, 0)
}

const RuleEditor = () => {
  const [isMenuCollapsed, toggleMenu] = useLayoutMenu();
  const navigate = useNavigateWithSearch();
  const { id, v } = useParams();

  const { data: { rule } = { rule: undefined }, loading: isFetchingRule } = useApolloQuery(ruleQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });


  const [saveRule, { loading: loadingSaveRule }] = useApolloMutation(saveRuleMutation, {
    variables: {
      id: rule?.id,
      name: rule?.name,
    },
  });


  const [updateRule] = useApolloMutation(updateRuleMutation, {
    variables: {
      id: rule?.id,
    }
  });
  const [createDraft, { loading: loadingCreateDraft }] = useApolloMutation(createDraftMutation, {
    variables: {
      id: rule?.id,
    },
  });

  const icon = rule?.merchant_account?.icon ?? rule?.merchant_account?.company?.theme?.icon;

  return (
    <MerchantAccountContractsProvider>
      <RuleEditorProvider>
        <RuleEditorContext.Consumer>
          { ({ isSiderOpen, isEditMode, setIsSiderOpen, setEditedItem, editedItem }) => {
            let splitter;
            if (v && rule) {
              const foundVersion = rule?.previous?.find(version => version.__v.toString() === v)
              if (foundVersion) {
                splitter = foundVersion?.fullEntrySplitter
              } else if (rule?.v?.toString() === v) {
                splitter = isEditMode ? rule?.draft_splitter : rule?.fullEntrySplitter;
              } else {
                message.error('The requested version was not found');
                navigate(`/router/payment-network/rule/${rule.id}`);
              }
            } else {
              splitter = isEditMode ? rule?.draft_splitter : rule?.fullEntrySplitter;
            }
            return (
              <Layout>
                <PageHeader
                  className={brainpowerStyles.pageHeader}
                  backIcon={isMenuCollapsed ? <ForwardburgerIcon /> : <BackburgerIcon />}
                  onBack={toggleMenu}
                  title={
                    <Space>
                      <RoutesIcon />
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/router/payment-network?pn=routingMatrix-${rule.id}`)}>
                        {formatEndUnderline('Routing')}
                      </span>
                      <SlashForwardIcon />
                      <span style={{ fontSize: 16 }}>{rule?.name}</span>
                    </Space>
                  }
                  extra={isEditMode ? (
                    <Button
                      type='text'
                      className={styles.conditionsButton}
                      onClick={() => {
                        setIsSiderOpen(editedItem?.edition !== 'rule' || !isSiderOpen);
                        setEditedItem({
                          edition: 'rule',
                        })
                      }}
                    >
                      Rule settings <SettingOutlined />
                    </Button>
                  ) : null}
                />
                <Layout
                  id='ruleEditor'
                >
                  <Layout.Content
                    className={styles.content}
                  >
                    <SiderPage
                      hideToggle
                      className={classnames(styles.root, { [styles.locked]: isFetchingRule })}
                      defaultOpen
                      isSiderHidden={!isSiderOpen}
                      siderContent={
                        <Sider
                          rule={rule}
                        />
                      }
                      canOverflowX
                    >
                      <Layout.Content
                        style={{
                          overflow: 'auto',
                        }}
                      >
                        {
                          rule ? (
                            <div
                              className={styles.grid}
                              style={{
                                display: 'grid',
                                gridTemplateColumns: `${icon ? '58px' : '120px'} minmax(58px,auto) repeat(${Math.max(1, getDepth(splitter))}, minmax(152px, 200px) minmax(58px,auto)) minmax(152px, 200px) 40px`,
                                gridAutoRows: `1fr .5fr`,
                                gridRowGap: '48px',
                              }}
                            >
                              <div
                                className={styles.mAccount}
                              >
                                {
                                  icon ? (
                                    <img src={icon} width={58} height={58} alt={rule?.merchant_account?.name}/>
                                  ) : (
                                    <div
                                      className={styles.mAccountName}
                                    >
                                      { rule?.merchant_account?.name }
                                    </div>
                                  )
                                }
                              </div>
                              {splitter ? (
                                <>
                                  <HorizontalLine />
                                  <Splitter
                                    splitter={splitter}
                                    col={3}
                                    row={1}
                                  />
                                </>
                              ) : (
                                <>
                                  <AddSplitterButton
                                    col={3}
                                    handleAdd={(splitterType) => updateRule({
                                      variables: {
                                        input: {
                                          draft_splitter: {
                                            type: splitterType,
                                            color: '#000000',
                                            branches: [],
                                            other: null,
                                          },
                                        },
                                      },
                                      optimisticResponse: {
                                        updateRule: {
                                          ...rule,
                                          draft_splitter: {
                                            type: splitterType,
                                            color: '#000000',
                                            branches: [],
                                            other: null,
                                            blocking: false,
                                            id: 'splitterTemp',
                                            __typename: 'Splitter',
                                            contract: null,
                                          }
                                        }
                                      }
                                    }).then((result) => {
                                      setEditedItem({
                                        edition: 'splitter',
                                        splitter: result.data.updateRule.draft_splitter
                                      });
                                      setIsSiderOpen(true);
                                    })}
                                  />
                                  <MerchantContract />
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              className={styles.loaderContainer}
                            >
                              <Loader size='large' />
                            </div>
                          )
                        }
                      </Layout.Content>
                      <Footer
                        rule={rule}
                        loadingRule={isFetchingRule}
                        saveRule={saveRule}
                        createDraft={createDraft}
                        loadingCreateDraft={loadingCreateDraft}
                        loadingSaveRule={loadingSaveRule}
                      />
                    </SiderPage>
                  </Layout.Content>
                </Layout>
              </Layout>
            )
          }}
        </RuleEditorContext.Consumer>
      </RuleEditorProvider>
    </MerchantAccountContractsProvider>
  );
};

export default RuleEditor;