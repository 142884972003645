import React from 'react';
import LordIcon from '../../../../../../../assets/icons/LordIcon';
import styles from './IconsContainer.module.scss';

const IconsContainer = ({ isError, isValid, isWarning, locked, contrast, custom }) => (
  <div className={styles.root}>
    {isError && <LordIcon key="errorIcon" type="error" isStopped={false} autoplay loop height={32} />}
    {isWarning && (
      <LordIcon
        key="warningIcon"
        classes={{ [styles.warningIcon]: true }}
        type="error"
        isStopped={false}
        autoplay
        loop
        height={32}
      />
    )}
    {isValid && (
      <LordIcon
        key="validIcon"
        classes={{ [styles.validIcon]: true }}
        type="check"
        isStopped={false}
        autoplay
        loop={false}
        height={32}
      />
    )}
    {!!locked && (
      <LordIcon
        key="lockedIcon"
        classes={{ [styles.lockedIcon]: true, [styles.white]: contrast }}
        type="locked-before-outline"
      />
    )}
    {custom}
  </div>
);

export default IconsContainer;
