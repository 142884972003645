import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as SidebarIcon } from './sidebar_icon.svg';
import styles from './SiderPage.module.scss';

const SiderPage = ({ id, hideToggle, className, defaultOpen, isSiderHidden, siderContent, children, canOverflowX = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = useCallback(() => setIsOpen((v) => !v), [setIsOpen]);

  // dispatch fake resize event, used by dashboard react-grid-layout
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [isOpen]);

  return (
    <div id={id} className={classnames(className, styles.root)}>
      <div
        className={styles.contentContainer}
        style={canOverflowX ? { overflowX: 'auto' } : {}}
      >
        <div className={styles.content}>{children}</div>
      </div>
      {!isSiderHidden && (
        <div className={styles.siderContainer}>
          {!hideToggle && (
            <Button
              className={classnames(styles.button, { [styles.buttonOpen]: isOpen })}
              type="text"
              size="small"
              onClick={toggle}
            >
              <Icon component={SidebarIcon} />
            </Button>
          )}
          <div className={classnames(styles.sider, { [styles.open]: isOpen })}>
            {isOpen && siderContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default SiderPage;
