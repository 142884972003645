import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {useMutation} from '@apollo/client';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {updateSplitterMutation} from './query';
import {useRuleEditor} from '../../../../ruleEditor.context';

const MerchantDataTypeSelector = ({ splitter }) => {
  const [form] = Form.useForm();
  const { setEditedItem } = useRuleEditor();

  const [hasChanged, setHasChanged] = useState(false);

  const [updateSplitter, { loading }] = useMutation(updateSplitterMutation, {
    variables: {
      id: splitter.id,
    }
  });

  useEffect(() => {
    form.setFieldValue('merchantDataKey', splitter?.merchant_data_key ?? '');
    form.setFieldValue('merchantDataType', splitter?.merchant_data_type ?? null);
    setHasChanged(false);
  }, [splitter])

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        merchantDataKey: splitter?.merchant_data_key ?? '',
        merchantDataType: splitter?.merchant_data_type ?? null,
      }}
      onFinish={(values) => {
        updateSplitter({
          variables: {
            input: {
              merchant_data_key: values.merchantDataKey,
              merchant_data_type: values.merchantDataType,
            },
          }
        }).then((result) => setEditedItem({
          splitter: result.data.updateMerchantDataSplitter,
          edition: 'splitter',
        }));
        setHasChanged(false);
      }}
      onValuesChange={() => setHasChanged(true)}
    >
      <Form.Item
        name='merchantDataKey'
        required
        rules={[
          { required: true, message: 'Key is required' },
          ({ getFieldValue }) => ({
            validator() {
              const validValue = getFieldValue('merchantDataKey').replaceAll(/[^a-zA-Z0-9_-]/gi, '');
              if (validValue !== getFieldValue('merchantDataKey').replaceAll(' ', '')) {
                return Promise.reject(new Error('Characters should be alphanumeric (A-Z, a-z, 0-9), _ or -'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input autoFocus placeholder='Merchant data key' allowClear />
      </Form.Item>
      <Form.Item
        shouldUpdate
        noStyle
      >
        {() => (
          <Form.Item
            name='merchantDataType'
            required
            shouldUpdate
            help={
              splitter.branches.length && splitter.merchant_data_type && splitter.merchant_data_type !== form.getFieldValue('merchantDataType')
                ? <div
                    style={{
                      color: 'red',
                      padding: '8px 0'
                    }}
                >
                    <ExclamationCircleOutlined /> The conditions will be deleted
                  </div>
                : null
            }
          >
            <Select
              placeholder="Data type"
              onChange={() => form.setFieldsValue({ condition: null })}
              options={[
                { value: 'boolean', label: 'Boolean' },
                { value: 'date', label: 'Date' },
                { value: 'number', label: 'Number' },
                { value: 'string', label: 'String' },
              ]}
            />
          </Form.Item>
        )}
      </Form.Item>
      {hasChanged && (
        <Button
          type='primary'
          htmlType='submit'
          disabled={loading || splitter.id === 'splitterTemp'}
          loading={loading || splitter.id === 'splitterTemp'}
          style={{
            display: 'block',
            width: '100%',
            marginBottom: 24,
          }}
        >
          Save
        </Button>
      )}
    </Form>
  );
};

export default MerchantDataTypeSelector;