import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Col, List, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import SourceBranchIcon from '@2fd/ant-design-icons/lib/SourceBranch';
import { useQuery } from '@apollo/client';
import challengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences';
import exemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons';
import { useCan } from '../../../../../contexts/ability.context';
import Page from '../../../Common/Page/Page';
import SiderPage from '../../../Common/SiderPage/SiderPage';
import Loader from '../../../Common/Loader/Loader';
import MerchantAccountSelector from '../../MerchantAccountSelector/MerchantAccountSelector';
import { useMerchantAccount } from '../../merchantAccount.context';
import NavButtons from '../NavButtons';
import styles from '../Optimizer.module.scss';
import SiderForm from './SiderForm';
import { FallbackSettingsQuery } from '../query';
import { formatEndUnderline } from '../../../Brainpower/Common/utils';

const GeneralSettings = () => {
  const can = useCan();
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data, loading } = useQuery(FallbackSettingsQuery, {
    variables: { id: selectedMerchantAccount },
    skip: !selectedMerchantAccount,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fallbackSettings = data?.merchantAccount.fallbackSettings;

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <SourceBranchIcon />
          {formatEndUnderline('Optimizer')}
        </Space>
      }
      headerContent={<MerchantAccountSelector stackOnly />}
      centerHeader
    >
      <SiderPage defaultOpen isSiderHidden={!open} siderContent={<SiderForm onClose={handleClose} />} hideToggle>
        <div className={classNames({ [styles.noMerchantPage]: !selectedMerchantAccount })}>
          {!selectedMerchantAccount && (
            <div className={styles.noMerchantMessage}>Please select a merchant account in the selector above</div>
          )}
          {!data ? (
            <div className={styles.loader}>
              <Loader size="large" />
            </div>
          ) : (
            [
              <NavButtons key="nav-buttons" />,
              <Row key="body" className={styles.rowList} justify="space-around">
                <Col md={16} lg={12} xl={8}>
                  {can('update', 'fallback-settings') && (
                    <Button
                      className={styles.editButton}
                      icon={<EditOutlined />}
                      size="large"
                      block
                      onClick={handleOpen}
                    >
                      Edit settings
                    </Button>
                  )}
                  <List loading={loading}>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        title="Fallback service"
                        description={fallbackSettings.status === 'active' ? 'Active' : 'Inactive'}
                      />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta title="Max. number of attempts" description={fallbackSettings.max_attempts} />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta title="Max. number of retries" description={fallbackSettings.max_retries} />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        title="Include PSP downtimes in count"
                        description={fallbackSettings.count_psp_unavailability ? 'Yes' : 'No'}
                      />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        title="Default value for ask_3DS"
                        description={challengePreferences[fallbackSettings.ask_3ds_default.value].label}
                      />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        title="Default value for force_3DS"
                        description={challengePreferences[fallbackSettings.force_3ds_default.value].label}
                      />
                    </List.Item>
                    <List.Item className={styles.listItem}>
                      <List.Item.Meta
                        title="Default value for no_3DS"
                        description={exemptionReasons[fallbackSettings.no_3ds_default.value].label}
                      />
                    </List.Item>
                  </List>
                </Col>
              </Row>,
            ]
          )}
        </div>
      </SiderPage>
    </Page>
  );
};

export default GeneralSettings;
