import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
} from '@apollo/client';
import { cmsList } from '../../../../../constants/CMSS';
import SelectElem from '../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import TextInputElem from '../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import Column from '../../../Common/NavigationContainer/Column/Column';
import { useCan } from '../../../../../contexts/ability.context';
import { useMerchantAccount } from '../../merchantAccount.context';
import { merchantAccountIntegrationMutation, merchantAccountIntegrationQuery, integrationTypesQuery } from './query';

const Integration = (props) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const can = useCan();

  const { t } = useTranslation();

  const [fetchIntegration, { data: { merchantAccount } = { merchantAccount: null }, loading }] = useApolloLazyQuery(
    merchantAccountIntegrationQuery,
  );

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchIntegration({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  const [updateMerchantAccount] = useApolloMutation(merchantAccountIntegrationMutation);

  const { data: { integrationTypes } = { integrationTypes: [] } } = useApolloQuery(integrationTypesQuery);

  const locked = !can('update', 'integration');

  const updateMerchantAccountField = (input) =>
    updateMerchantAccount({
      variables: {
        id: merchantAccount.id,
        input,
      },
      optimisticResponse: {
        updateMerchantAccountIntegration: {
          id: merchantAccount.id,
          __typename: 'MerchantAccount',
          ...input,
        },
      },
    });

  return (
    <Column id="integrationSubCol" {...props}>
      <SelectElem
        id="cms"
        title="CMS"
        value={merchantAccount?.cms_name}
        options={cmsList.map(({ value, label }) => ({ id: value, name: label }))}
        handleChange={(value) => {
          if (value !== merchantAccount?.cms_name) {
            updateMerchantAccountField({ cms_name: value });
          }
        }}
        allowClear={false}
        locked={locked}
        isLoading={loading || !selectedMerchantAccount}
      />
      <TextInputElem
        id="cmsVersion"
        title="CMS version"
        defaultValue={merchantAccount?.cms_version}
        handleValid={(value) => {
          if (value !== merchantAccount?.cms_version) {
            updateMerchantAccountField({ cms_version: value });
          }
        }}
        locked={locked}
        isLoading={loading || !selectedMerchantAccount}
      />
      <SelectElem
        id="integrationType"
        title="Integration type"
        options={
          integrationTypes?.map((integrationType) => ({
            id: integrationType,
            name: t(`andMe.notifications.input.integrationType.${integrationType}`),
          })) ?? []
        }
        value={merchantAccount?.integration_type}
        handleChange={(value) => {
          if (value !== merchantAccount?.integration_type) {
            updateMerchantAccountField({ integration_type: value });
          }
        }}
        allowClear={false}
        locked={locked}
        isLoading={loading || !selectedMerchantAccount}
      />
    </Column>
  );
};

export default Integration;
