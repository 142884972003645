import React from 'react';
import {useQuery} from '@apollo/client';
import {Link} from '../../../../../../../util/navigate';
import {merchantContractQuery} from './query';

const TestRouterResult = ({ data }) => {

  if (data === null) {
    return (
      <div>
        No route found for this configuration
      </div>
    )
  }
  const {data: { merchantContract } = { merchantContract: null }} = useQuery(merchantContractQuery, {
    variables: {
      id: data?.[0]?.merchant_contract,
    }
  });

  switch (data[0].type) {
    case 'block_process':
      return <div>
        Payment is blocked
        <br/>
        Reason : rule <Link to={`/router/payment-network/rule/${data[0].reason[0].rule_id}`}>{data[0].reason[0].rule} ({data[0].reason[0].rule_id})</Link>
      </div>
    case 'fallback':
      return <div>
        Routed to merchant contract :&nbsp;
        <Link to={`/router/payment-network?maccount=${merchantContract?.merchant_account?.id}&pn=serviceProviders-payment-${merchantContract?.partner?.id}-${merchantContract?.id}`}>{merchantContract?.name?.length > 0 ? merchantContract?.name : data[0].merchant_contract}</Link>
        <br/>
        Reason : Smart routing
      </div>
    case 'recommendation':
      return <div>
        Routed to merchant contract :&nbsp;
        <Link to={`/router/payment-network?maccount=${merchantContract?.merchant_account?.id}&pn=serviceProviders-payment-${merchantContract?.partner?.id}-${merchantContract?.id}`}>{merchantContract?.name?.length > 0 ? merchantContract?.name : data[0].merchant_contract}</Link>
        &nbsp;({merchantContract?.partner?.name})
        <br/>
        Reason : rule <Link to={`/router/payment-network/rule/${data[0].reason[0].rule_id}`}>{data[0].reason[0].rule} ({data[0].reason[0].rule_id})</Link>
      </div>
    default:
      return <div>An error occurred</div>
  }
};

export default TestRouterResult;
