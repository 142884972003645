import React from 'react';
import {useRuleEditor} from '../ruleEditor.context';
import SiderContent from './SiderContent/SiderContent';
import { SiderContainer } from '../../../Common/Sider';

const Sider = ({ rule }) => {
  const { setEditedItem, editedItem, setIsSiderOpen } = useRuleEditor();
  return (
    <>
      {editedItem ? (
        <SiderContainer
          title={editedItem.edition === 'rule' ? 'Rule settings' : 'Conditions settings'}
          handleClose={() => {
            setIsSiderOpen(false);
            setEditedItem(null);
          }}
        >
          <SiderContent
            editedItem={editedItem}
            rule={rule}
          />
        </SiderContainer>
      ) : (
        <SiderContent
          editedItem={editedItem}
          rule={rule}
        />
      )}
    </>
  )
};

export default Sider;