import React, { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import {useQuery as useApolloQuery} from '@apollo/client/react/hooks/useQuery';
import {merchantAccountContractsQuery} from './query';

export const MerchantAccountContractsContext = createContext(undefined);

export const useMerchantAccountContracts = () => useContext(MerchantAccountContractsContext);

const MerchantAccountContractsProvider = ({ children }) => {
  const [searchParams] = useSearchParams();

  const { data: { merchantAccount } = { merchantAccount: null } } = useApolloQuery(merchantAccountContractsQuery, {
    variables: {
      id: searchParams.get('maccount'),
      filters: {
        status: { in: ['active'] },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <MerchantAccountContractsContext.Provider
      value={{
        merchantAccount,
      }}
    >
      {children}
    </MerchantAccountContractsContext.Provider>
  );
};

export default MerchantAccountContractsProvider;
