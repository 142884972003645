import React, {useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import Column from '../../../Common/NavigationContainer/Column/Column';
import SelectElem from '../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import NumberInputElem from '../../../Common/NavigationContainer/Column/NumberInputElem/NumberInputElem';
import ButtonElem from '../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';
import {merchantAccountProceduresQuery, updateMerchantAccountProceduresMutation} from './query';
import {useMerchantAccount} from '../../merchantAccount.context';
import {useCan} from '../../../../../contexts/ability.context';

const periodTypes = [
  {
    id: 'minutes',
    name: 'Minutes',
    factor: 1,
  },
  {
    id: 'hours',
    name: 'Hours',
    factor: 60,
  },
  {
    id: 'days',
    name: 'Days',
    factor: 24 * 60,
  },
];

const PaymentProcedures = ({...props}) => {
  const can = useCan();

  const { selectedMerchantAccount } = useMerchantAccount();
  const [fetchProcedures, { data: { merchantAccount } = { merchantAccount: null } }] = useLazyQuery(merchantAccountProceduresQuery);

  const [captureType, setCaptureType] = useState(merchantAccount?.scheduled_capture?.status ?? 'inactive');
  const [periodType, setPeriodType] = useState('minutes');
  const [period, setPeriod] = useState();
  const [isButtonVisible, setIsButtonVisible] = useState(false)

  const [updateProcedures] = useMutation(updateMerchantAccountProceduresMutation, {
    variables: {
      id: selectedMerchantAccount,
    }
  });

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchProcedures({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  useEffect(() => {
    setCaptureType(merchantAccount?.scheduled_capture?.status ?? 'inactive');
    if (merchantAccount?.scheduled_capture?.delay) {
      let maxPeriodType;
      periodTypes.forEach(type => {
        if (merchantAccount?.scheduled_capture?.delay / type.factor % 1 === 0) {
          maxPeriodType = type;
        }
      })
      setPeriodType(maxPeriodType.id);
      setPeriod(merchantAccount?.scheduled_capture?.delay / maxPeriodType.factor)
    }
  }, [merchantAccount])

  useEffect(() => {
    if (captureType !== 'active' || !periodType || !period) {
      setIsButtonVisible(false);
    } else {
      const selectedDelay = period * periodTypes.find(elem => elem.id === periodType).factor;
      setIsButtonVisible(merchantAccount?.scheduled_capture?.delay !== selectedDelay)
    }
  }, [merchantAccount, period, periodType, captureType])

  return (
    <Column id="procedures" {...props}>
      <SelectElem
        title="Capture"
        options={[
          {
            name: 'Capture on demand',
            id: 'inactive',
          },
          {
            name: 'Scheduled capture',
            id: 'active',
          },
        ]}
        value={captureType}
        handleChange={(value) => {
          if (value === 'inactive') {
            updateProcedures({
              variables: {
                input: {
                  status: value,
                  delay: null,
                }
              }
            });
            setPeriod(null);
          }
          setCaptureType(value)
        }}
        placeholder="Select public or private"
        locked={!can('update', 'payment-procedure')}
      />
      {captureType === 'active' && (
        <NumberInputElem
          title='After'
          value={period}
          handleChange={(value) => setPeriod(value)}
          locked={!can('update', 'payment-procedure')}
        />
      )}
      {captureType === 'active' && (
        <SelectElem
          title='Period type'
          value={periodType}
          options={periodTypes}
          handleChange={(value) => setPeriodType(value)}
          locked={!can('update', 'payment-procedure')}
        />
      )}
      {isButtonVisible && (
        <ButtonElem
          // Can't select more than 7 days
          disabled={period * periodTypes.find(elem => elem.id === periodType).factor >= 10080}
          title={period * periodTypes.find(elem => elem.id === periodType).factor >= 10080 ? (
            <span style={{color: 'red'}}>Must be lower than 7 days</span>
          ): 'Save'}
          handleClick={() => {
            updateProcedures({
              variables: {
                input: {
                  status: 'active',
                  delay: period * periodTypes.find(elem => elem.id === periodType).factor,
                }
              }
            })
          }}
        />
      )}
    </Column>
  )
};

export default PaymentProcedures;