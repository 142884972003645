import React, { useEffect } from 'react';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import Column from '../../../Common/NavigationContainer/Column/Column';
import Elem from '../../../Common/NavigationContainer/Column/Elem/Elem';
import Credentials from './Credentials/Credentials';
import { useMerchantAccount } from '../../merchantAccount.context';
import { merchantAccountSecurityQuery } from './query';

const Security = ({ ...props }) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const [fetchSecurity, { data: { merchantAccount } = { merchantAccount: null } }] =
    useApolloLazyQuery(merchantAccountSecurityQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchSecurity({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  return (
    <Column id="securitySubCol" {...props}>
      <Elem id="credentials" key="credentials" title="API credentials" subColumn={<Credentials />} />
      <Elem id="secretKey" key="secretKey" title="Secret key" value={merchantAccount?.secret_key} canCopy />
    </Column>
  );
};

export default Security;
