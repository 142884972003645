import React, { useEffect, useState } from 'react';
import { Button, Col, List, Row, Space } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import ClipboardTextIcon from '@2fd/ant-design-icons/lib/ClipboardText';
import { useTranslation } from 'react-i18next';
import Page from '../../Common/Page/Page';
import styles from './Lists.module.scss';
import SearchInput from '../../Teams/SearchInput';
import { useCan } from '../../../../contexts/ability.context';
import SiderPage from '../../Common/SiderPage/SiderPage';
import { addCompanyListMutation, companyListsQuery } from './query';
import { useCompany } from '../../../../contexts/app.context';
import ListFormDrawer from './ListFormDrawer/ListFormDrawer';
import useToggle from '../../../../hooks/useToggle';
import ListDetailsSider from './ListDetailsSider/ListDetailsSider';
import { compareByProp } from '../../../../util/array';
import EmptyListSider from './EmptyListSider/EmptyListSider';
import useNavigateWithSearch from '../../../../util/navigate';
import { formatEndUnderline } from '../../Brainpower/Common/utils';

const Lists = () => {
  const can = useCan();
  const [company] = useCompany();
  const { listId } = useParams();
  const navigate = useNavigateWithSearch();
  const { t } = useTranslation();

  const [loadLists, { data: { merchantCompany: { lists } } = { merchantCompany: { lists: [] } }, loading }] =
    useLazyQuery(companyListsQuery);

  useEffect(() => {
    if (company) {
      loadLists({
        variables: {
          id: company,
        },
      });
    }
  }, [company]);

  useEffect(() => {
    // activate first item
    if (!listId && lists.length > 0) {
      navigate(`/router/lists/${lists[0].id}`);
    }
  }, [listId, lists]);

  const [searchValue, setSearchValue] = useState('');
  const [isFormDrawerOpen, { toggle: toggleFormDrawer }] = useToggle(false);

  useEffect(() => {
    const elem = document.getElementById(listId);
    elem?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [listId]);

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <ClipboardTextIcon />
          {formatEndUnderline('Lists')}
        </Space>
      }
    >
      <SiderPage defaultOpen siderContent={listId ? <ListDetailsSider id={listId} /> : <EmptyListSider />} hideToggle>
        <Row className={styles.rowList} justify="space-around">
          <Col md={16} lg={12} xl={8}>
            {!!lists.length && <SearchInput placeholder="List name" onChange={(e) => setSearchValue(e.target.value)} />}
            {can('create', 'list') && (
              <Button
                className={styles.createButton}
                icon={<PlusCircleFilled />}
                size="large"
                block
                onClick={toggleFormDrawer}
              >
                Create list
              </Button>
            )}
            <List
              loading={loading}
              dataSource={[...lists]
                .sort(compareByProp('name', 'asc', 'string'))
                .filter((elem) => elem.name.toLowerCase().indexOf(searchValue) > -1)}
              renderItem={(list) => (
                <List.Item
                  key={list.id}
                  id={list.id}
                  onClick={() => navigate(`/router/lists/${list.id}`)}
                  className={classNames(styles.listItem, { [styles.active]: list.id === listId })}
                >
                  <List.Item.Meta title={list.name} description={t(`andMe.lists.types.${list.type}`)} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </SiderPage>
      <ListFormDrawer visible={isFormDrawerOpen} onClose={toggleFormDrawer} />
    </Page>
  );
};

export default Lists;
