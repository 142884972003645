import React, {useState} from "react";
import classnames from 'classnames';
import PlusCircleOutlineIcon from "@2fd/ant-design-icons/lib/PlusCircleOutline";
import {Dropdown} from 'antd';
import {useRuleEditor} from '../../ruleEditor.context';
import styles from './AddSplitterButton.module.scss';
import types from '../../constants/splitterTypes';

const AddSplitterButton = (props) => {
  const { col, isParentActive, handleAdd } = props;
  const { isEditMode } = useRuleEditor();
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);


  return (
    <>
      <div
        className={styles.root}
        style={{
          gridColumnStart: col-1,
        }}
      >
        <div
          className={classnames(styles.addSplitterButton, {[styles.edit]: isEditMode}, {[styles.active]: isParentActive})}
        />
        {
          isEditMode ? (
            <Dropdown
              arrow={false}
              autoAdjustOverflow
              autoFocus
              trigger='click'
              placement='bottom'
              open={isDropdownOpen}
              onOpenChange={(open) => setIsDropDownOpen(open)}
              overlayClassName={styles.dropdown}
              dropdownRender={() => (
                <div>
                  <div
                    className={styles.title}
                  >
                    Add a condition
                  </div>
                  <div>
                    {types.filter(type => type.id !== 'authenticationLevel').map(({id, icon, name}) => (
                      <div
                        key={id}
                        role='presentation'
                        className={styles.typeRow}
                        onClick={() => {
                          handleAdd(id);
                          setIsDropDownOpen(false);
                        }}
                      >
                        {React.cloneElement(icon, {className: styles.icon})}
                        <span
                          className={styles.type}
                        >
                          {name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            >
              <PlusCircleOutlineIcon
                className={classnames(styles.addIcon, {[styles.open]: isDropdownOpen}, {[styles.active]: isParentActive})}
              />
            </Dropdown>
          ) : null
        }
      </div>
    </>
  )
};

export default AddSplitterButton;