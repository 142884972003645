import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, message, Select } from "antd";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { addCompanyListMutation } from "./query";
import { useCompany } from "../../../../../contexts/app.context";
import { compareByProp } from "../../../../../util/array";
import styles from './ListFormDrawer.module.scss';
import useNavigateWithSearch from "../../../../../util/navigate";

const ListFormDrawer = ({ visible, onClose }) => {
  const [addList, {loading}] = useMutation(addCompanyListMutation);
  const [company] = useCompany();
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const [form] = Form.useForm();

  const listTypes = [
    'bin',
    'product_sku',
    'customer_id',
    'customer_email',
    'customer_ip',
    'customer_zip_code',
  ];

  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Drawer
      title="Create list"
      open={visible}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 350, width: '80%' }} // responsive width
      width="100%"
    >
      <Form
        form={form}
        layout="vertical"
        name="listForm"
        onFinish={
          (input) => addList(
            {
              variables: {
                id: company,
                input
              }
            }
          ).then((response) => {
            message.success('List created');
            const companyLists = response.data.addMerchantCompanyList.lists;
            navigate(`/router/lists/${companyLists[companyLists.length - 1].id}`);
            onClose()
            form.resetFields()
          })}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="List type" initialValue={[]} rules={[{ required: true }]}>
          <Select
            options={listTypes.map((type) => ({
                value: type,
                label: t(`andMe.lists.types.${type}`),
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }} shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.submitButton}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Proceed
            </Button>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ListFormDrawer;