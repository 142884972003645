import React, { useEffect } from 'react';
import { useLazyQuery as useApolloLazyQuery, useMutation as useApolloMutation } from '@apollo/client';
import TextInputElem from '../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import Column from '../../../Common/NavigationContainer/Column/Column';
import { useCan } from '../../../../../contexts/ability.context';
import { useMerchantAccount } from '../../merchantAccount.context';
import { merchantAccountUrlsMutation, merchantAccountUrlsQuery } from './query';

const Urls = ({ ...props }) => {
  const { selectedMerchantAccount } = useMerchantAccount();
  const can = useCan();

  const [fetchUrls, { data: { merchantAccount } = { merchantAccount: null }, loading }] =
    useApolloLazyQuery(merchantAccountUrlsQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchUrls({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  const [updateMerchantAccount] = useApolloMutation(merchantAccountUrlsMutation);

  const updateMerchantAccountUrl = (input) =>
    updateMerchantAccount({
      variables: {
        id: merchantAccount.id,
        input,
      },
      optimisticResponse: {
        updateMerchantAccountUrls: {
          id: merchantAccount.id,
          __typename: 'MerchantAccount',
          ...input,
        },
      },
    });

  const locked = !can('update', 'redirection_url');

  // The regexp checks if the tested String has a correct URL format.
  const urlRegexp = new RegExp(/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/);

  return (
    <Column id="urls" {...props}>
      <TextInputElem
        id="acceptUrl"
        title="Accept URL"
        defaultValue={merchantAccount?.accept_url}
        handleValid={(value) => {
          if (value !== merchantAccount?.accept_url) {
            updateMerchantAccountUrl({ accept_url: value });
          }
        }}
        regexp={urlRegexp}
        locked={locked}
        isLoading={loading}
      />
      <TextInputElem
        id="declineUrl"
        title="Decline URL"
        defaultValue={merchantAccount?.decline_url}
        handleValid={(value) => {
          if (value !== merchantAccount?.decline_url) {
            updateMerchantAccountUrl({ decline_url: value });
          }
        }}
        regexp={urlRegexp}
        locked={locked}
        isLoading={loading}
      />
      <TextInputElem
        id="pendingUrl"
        title="Pending URL"
        defaultValue={merchantAccount?.pending_url}
        handleValid={(value) => {
          if (value !== merchantAccount?.pending_url) {
            updateMerchantAccountUrl({ pending_url: value });
          }
        }}
        regexp={urlRegexp}
        locked={locked}
        isLoading={loading}
      />
      <TextInputElem
        id="exceptionUrl"
        title="Exception URL"
        defaultValue={merchantAccount?.exception_url}
        handleValid={(value) => {
          if (value !== merchantAccount?.exception_url) {
            updateMerchantAccountUrl({ exception_url: value });
          }
        }}
        regexp={urlRegexp}
        locked={locked}
        isLoading={loading}
      />
    </Column>
  );
};

export default Urls;
