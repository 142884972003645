import React, { useEffect } from 'react';
import { message } from 'antd';
import { useLazyQuery as useApolloLazyQuery, useMutation as useApolloMutation } from '@apollo/client';
import Elem from '../../../Common/NavigationContainer/Column/Elem/Elem';
import Column from '../../../Common/NavigationContainer/Column/Column';
import NewNotificationForm from './NewNotificationForm/NewNotificationForm';
import Notification from './Notification/Notification';
import { useCan } from '../../../../../contexts/ability.context';
import { useMerchantAccount } from '../../merchantAccount.context';
import { merchantAccountNotificationsQuery, removeNotificationMutation } from './query';
import LoadingElem from '../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';

const Notifications = ({ ...props }) => {
  const can = useCan();

  const { selectedMerchantAccount } = useMerchantAccount();

  const [fetchNotifications, { data: { merchantAccount } = { merchantAccount: null }, loading }] = useApolloLazyQuery(
    merchantAccountNotificationsQuery,
  );

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchNotifications({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  const [removeNotification] = useApolloMutation(removeNotificationMutation);

  const notifications = merchantAccount?.webhooks?.length ? [...merchantAccount?.webhooks] : [];

  return (
    <Column id="notificationsColumn" {...props}>
      {can('create', 'webhook') && (
        <Elem id="addNotification" title="Add notification" subColumn={<NewNotificationForm />} />
      )}
      {!notifications.length && (loading || !selectedMerchantAccount) && <LoadingElem />}
      {notifications
        .sort((a, b) => {
          if (a.status !== b.status) {
            return a.status === 'disabled' ? 1 : -1;
          }
          if (!(a.url && a.trigger.length) && b.url && b.trigger.length) {
            return 1;
          }
          if (a.url && a.trigger.length && !(b.url && b.trigger.length)) {
            return -1;
          }
          if (a.name === b.name) {
            return a.createdAt < b.createdAt ? -1 : 1;
          }
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        })
        ?.map((webhook) => (
          <Elem
            id={webhook.id}
            key={webhook.id}
            title={webhook.name}
            isWarning={!(webhook.status === 'active')}
            isValid={!!(webhook.url && webhook.trigger.length && webhook.status === 'active')}
            isError={!(webhook.url && webhook.trigger.length) && webhook.status === 'active'}
            dropdownActions={[
              {
                label: 'Delete',
                call: () =>
                  removeNotification({
                    variables: {
                      id: webhook.id,
                    },
                    optimisticResponse: {
                      removeWebhook: {
                        id: webhook.id,
                      },
                    },
                    update: (cache, { data }) => {
                      cache.modify({
                        id: cache.identify({
                          id: selectedMerchantAccount,
                          __typename: 'MerchantAccount',
                        }),
                        fields: {
                          webhooks(existingWebhooks, { readField }) {
                            return existingWebhooks.filter(
                              (webhookRef) => data.removeWebhook.id !== readField('id', webhookRef),
                            );
                          },
                        },
                      });
                    },
                  }).then(() => message.success('Webhook successfully removed')),
              },
            ]}
            subColumn={<Notification webhook={webhook} />}
          />
        ))}
    </Column>
  );
};

export default Notifications;
