import React from 'react';
import classnames from 'classnames';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import styles from '../Content.module.scss';
import splitterTypes from '../../../constants/splitterTypes';
import MerchantDataSplitterContent from './MerchantDataSplitterContent/MerchantDataSplitterContent';

const SplitterContent = (props) => {
  const { splitter, isActive, isParentActive } = props;
  const { t } = useTranslation();

  if (splitter.type === 'merchantData') {
    return (
      <MerchantDataSplitterContent
        {...props}
      />
    );
  }

  return (
    <div
      key={splitter.id}
      role='presentation'
      className={classnames(styles.root, styles.splitter, {[styles.active]: isActive}, {[styles.parentActive]: isParentActive})}
    >
      <div
        className={styles.icon}
      >
        {splitterTypes.find(type => type.id === splitter.type).icon}
      </div>
      <div
        className={styles.text}
      >
        <div
          className={styles.textContent}
        >
          <div
            className={styles.title}
          >
            { t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${splitter.type}.label`) }
          </div>
          <div
            className={styles.type}
          >
            Others
          </div>
        </div>
        {splitter.id === "splitterTemp" && (
          <Spin indicator={<LoadingOutlined />} />
        )}
      </div>
    </div>
  )
};

export default SplitterContent;