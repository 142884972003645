import React, {useContext, useEffect} from 'react';
import Elem from '../../../../../Common/NavigationContainer/Column/Elem/Elem';
import {CertificateEditionContext} from '../../../PaymentNetwork';

const Certificate = ({certificate, activeOnCreate, ...props}) => {
  const { setEditedCertificateId, editedCertificateId } = useContext(CertificateEditionContext);

  // Close merchantContract drawer on unmount
  useEffect(() => {
    if (activeOnCreate) {
      setEditedCertificateId(certificate.id);
    }
    return () => setEditedCertificateId(null);
  }, []);

  return (
    <Elem
      key={certificate.id}
      id={certificate.id}
      title={certificate.name ?? 'Not named certificate'}
      isWarning={certificate.status !== 'active'}
      handleClick={() => setEditedCertificateId(certificate.id)}
      {...props}
      active={editedCertificateId === certificate.id}
    />
  );
};

export default Certificate;