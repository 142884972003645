import React from 'react';
import MerchantContractDrawer from './MerchantContractDrawer/MerchantContractDrawer';
import CertificateSider from './CertificateSider/CertificateSider';

const PaymentNetworkSider = ({
  certificateId,
  merchantContractId,
  setForcedActiveColumns,
}) => {
  if (merchantContractId) {
    return (
      <MerchantContractDrawer
        merchantContractId={merchantContractId}
        setForcedActiveColumns={setForcedActiveColumns}
      />
    )
  }
  if (certificateId) {
    return (
      <CertificateSider
        certificateId={certificateId}
      />
    )
  }
  return null;
};

export default PaymentNetworkSider;