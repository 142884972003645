import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavigateWithSearch } from '../../../util/navigate';
import APINotifications from './APINotifications/APINotifications';
import PaymentNetwork from './PaymentNetwork/PaymentNetwork';
import RuleEditor from './RuleEditor/RuleEditor';
import RestrictedPage from '../RestrictedPage';
import MerchantAccountProvider from './merchantAccount.context';
import Lists from './Lists/Lists';
import Optimizer from './Optimizer/Optimizer';

const AndMe = () => (
  <MerchantAccountProvider>
    <Routes>
      <Route
        path="notifications"
        element={
          <RestrictedPage action="read" subject="api_notifications">
            <APINotifications />
          </RestrictedPage>
        }
      />

      <Route
        path="payment-network"
        element={
          <RestrictedPage action="read" subject="payment_networks">
            <PaymentNetwork />
          </RestrictedPage>
        }
      />
      <Route path="payment-network/rule/:id">
        <Route
          index
          element={
            <RestrictedPage action="read" subject="rule">
              <RuleEditor />
            </RestrictedPage>
          }
        />
        <Route
          path=":v"
          element={
            <RestrictedPage action="read" subject="rule">
              <RuleEditor />
            </RestrictedPage>
          }
        />
      </Route>
      <Route
        path="optimizer/*"
        element={
          <RestrictedPage action="read" subject="fallback-settings">
            <Optimizer />
          </RestrictedPage>
        }
      />
      <Route path="lists">
        <Route
          index
          element={
            <RestrictedPage action="read" subject="list">
              <Lists />
            </RestrictedPage>
          }
        />
        <Route
          path=":listId"
          element={
            <RestrictedPage action="read" subject="list">
              <Lists />
            </RestrictedPage>
          }
        />
      </Route>
      <Route path="*" element={<NavigateWithSearch to="notifications" />} />
    </Routes>
  </MerchantAccountProvider>
);

export default AndMe;
