import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { Avatar, List, Button, Tooltip, Col, Row } from 'antd';
import { LockFilled, PlusCircleFilled } from '@ant-design/icons';
import { getUserStatus } from '../../../../constants/USER_STATUSES';
import { useMe } from '../../../../contexts/me.context';
import { useCan } from '../../../../contexts/ability.context';
import { initials, search } from '../../../../util/string';
import SiderPage from '../../Common/SiderPage/SiderPage';
import SearchInput from '../SearchInput';
import NavButtons from '../NavButtons';
import styles from '../Teams.module.scss';
import UserDetail from './UserDetail/UserDetail';
import UserCreate from './UserCreate/UserCreate';
import { TeamUserListQuery } from './query';
import useNavigateWithSearch from "../../../../util/navigate";

const renderUserStatusIcon = (user) => {
  if (user.disabled) {
    return (
      <Tooltip title="Locked">
        <LockFilled key="lock" style={{ fontSize: 20, color: 'darkgrey' }} />
      </Tooltip>
    );
  }
  const userStatus = getUserStatus(user.status);
  const Icon = userStatus.icon;
  return (
    <Tooltip title={user.status === 'created' ? 'Requires email validation' : userStatus.label}>
      <Icon key="status" style={{ color: userStatus.color, fontSize: 20 }} />
    </Tooltip>
  );
};

// Sort user first active > disabled > full_name
const compareUserByStatus = (a, b) => {
  const aActive = a.user.status === 'active' && !a.user.disabled;
  const bActive = b.user.status === 'active' && !b.user.disabled;
  switch (true) {
    case aActive && !bActive:
      return -1;
    case !aActive && bActive:
      return 1;
    case a.user.disabled && !b.user.disabled:
      return -1;
    case !a.user.disabled && b.user.disabled:
      return 1;
    default:
      return a.user.full_name.toLowerCase() > b.user.full_name.toLowerCase() ? 1 : -1;
  }
};

const UserList = () => {
  const me = useMe();
  const can = useCan();
  const navigate = useNavigateWithSearch();
  const { userId } = useParams();

  const { data, loading } = useQuery(TeamUserListQuery, {
    variables: { companyId: me.companies[0].company.id },
  });

  // Manage search input adn filtered list
  const [searchValue, setSearchValue] = useState();
  const handleSearch = (e) => setSearchValue(e.target.value);
  const filteredList = useMemo(() => {
    if (data) {
      let list = [...data.merchantCompany.users];
      if (searchValue) {
        list = list.filter(({ user }) => search(searchValue, user.full_name) || search(searchValue, user.email));
      }
      list.sort(compareUserByStatus);
      return list;
    }
    return [];
  }, [data, searchValue]);

  useEffect(() => {
    // activate first item
    if (!userId && filteredList.length > 0) {
      navigate(`/teams/users/${filteredList[0]?.user.id}`);
    }
  }, [userId, filteredList]);

  // Manage creation modal
  const [visible, setVisible] = useState(false);
  const showCreationModal = () => setVisible(true);
  const hideCreationModal = () => setVisible(false);

  return (
    <SiderPage id="page-team-users" defaultOpen siderContent={<UserDetail key={userId} userId={userId} />} hideToggle>
      <NavButtons />
      <Row className={styles.rowList} justify="space-around">
        <Col md={16} lg={12} xl={8}>
          <SearchInput placeholder="User name or email" onChange={handleSearch} />
          {can('create', 'user') && (
            <Button
              id="create-user"
              className={styles.createButton}
              icon={<PlusCircleFilled />}
              size="large"
              block
              onClick={showCreationModal}
            >
              Create user
            </Button>
          )}
          <List
            id="team-users"
            loading={loading}
            dataSource={filteredList}
            renderItem={({ user, aclProfile }) => (
              <List.Item
                key={user.id}
                onClick={() => navigate(`/teams/users/${user.id}`)}
                className={classNames(styles.listItem, { [styles.active]: user.id === userId })}
                extra={[renderUserStatusIcon(user)]}
              >
                <List.Item.Meta
                  title={user.full_name || 'Unnamed'}
                  description={aclProfile.name}
                  avatar={<Avatar src={user.avatar}>{initials(user.full_name)}</Avatar>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <UserCreate visible={visible} onCancel={hideCreationModal} />
    </SiderPage>
  );
};

export default UserList;