import React from "react";
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import Splitter from "../Splitter";
import AddSplitterButton from '../AddSplitterButton/AddSplitterButton';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import MerchantContract from '../MerchantContract/MerchantContract';
import {updateOtherSplitterMutation} from './query';
import {useRuleEditor} from '../../ruleEditor.context';

const Other = (props) => {
  const { splitter, col, isParentActive } = props;
  const { setIsSiderOpen, setEditedItem } = useRuleEditor();

  const [updateOtherTemp] = useApolloMutation(updateOtherSplitterMutation);

  const updateOther = (splitterType) => {
    updateOtherTemp({
      variables: {
        id: splitter.id,
        input: {
          type: splitterType,
          color: '#000000',
        },
      },
      optimisticResponse: {
        updateOtherSplitter: {
          ...splitter,
          contract: null,
          other: {
            color: '#000000',
            branches: [],
            type: splitterType,
            contract: null,
            id: 'splitterTemp',
            other: splitter.other ?? null,
            blocking: false,
            __typename: 'Splitter',
          },
        },
      },
      onCompleted: (data) => {
        setEditedItem({
          edition: 'splitter',
          splitter: {
            ...data.updateOtherSplitter.other,
            parent: splitter,
          }
        });
        setIsSiderOpen(true);
      },
    });
  };

  if (!splitter?.other) {
    return (
      <>
        {
          // Not allowed  after a depth of 8
          col === 19 ||
          // Not allowed after a merchantData splitter without a key defined
          (splitter.type === 'merchantData' && !splitter.merchant_data_key) ? (
            <HorizontalLine />
          ) : (
          <AddSplitterButton
            col={col}
            splitter={splitter}
            isParentActive={isParentActive}
            handleAdd={updateOther}
          />
        )}
        <MerchantContract
          contract={splitter?.contract}
          splitter={splitter}
          isParentActive={isParentActive}
        />
      </>
    )
  }
  return (
    <>
      <HorizontalLine
        isParentActive={isParentActive}
      />
      <Splitter
        splitter={{
          ...splitter.other,
          parent: splitter,
        }}
        col={col}
        isParentActive={isParentActive}
      />
    </>
  )
};

export default Other;