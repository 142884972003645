import { Card } from 'antd';
import React from 'react';
import classnames from 'classnames';
import styles from './Helper.module.scss';

const Helper = ({ gridColumn, gridRow, children, shown }) => (
  <Card
    style={{
      gridRowStart: Math.max(gridRow - 1, 2),
      gridRowEnd: Math.max(gridRow + 2, 4),
      gridColumnStart: gridColumn === 3 ? 4 : 3,
      gridColumnEnd: gridColumn === 3 ? 6 : 4,
    }}
    className={classnames(styles.root, { [styles.shown]: shown })}
  >
    {children}
  </Card>
);

export default Helper;
