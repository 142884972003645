import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import SiderContainer from '../../../Common/Sider/SiderContainer/SiderContainer';
import LinkedUsers from './LinkedUsers';
import AvailableUsers from './AvailableUsers';
import { MerchantAccountDetailQuery } from './query';
import { CompanyUserAuthorizedMerchantAccountMutation } from '../../../Admin/Company/query';
import styles from './AccountDetail.module.scss';
import { useCan } from '../../../../../contexts/ability.context';
import Loader from '../../../Common/Loader/Loader';

const AccountDetail = ({ merchantAccountId }) => {
  const can = useCan();
  const { data } = useQuery(MerchantAccountDetailQuery, {
    variables: { id: merchantAccountId },
    skip: !merchantAccountId,
  });

  const [updateCompanyUser] = useMutation(CompanyUserAuthorizedMerchantAccountMutation, {
    refetchQueries: ['TeamAccountListQuery', 'MerchantAccountDetailQuery'],
  });

  if (!data?.merchantAccount) return <Loader className={styles.loading} />;

  const merchantAccount = data?.merchantAccount;

  const handleAddAuthorizedUser =
    can('manage-users', 'company') &&
    ((userId) => {
      updateCompanyUser({
        variables: { id: userId, merchantAccount: merchantAccountId, toAdd: true },
      });
    });

  const handleRemoveAuthorizedUser =
    can('manage-users', 'company') &&
    ((userId) => {
      updateCompanyUser({
        variables: { id: userId, merchantAccount: merchantAccountId, toAdd: false },
      });
    });

  return (
    <SiderContainer id="account-detail" title={<div className={styles.title}>{merchantAccount?.name}</div>}>
      <LinkedUsers
        merchantAccount={merchantAccount}
        onAdd={handleAddAuthorizedUser}
        onRemove={handleRemoveAuthorizedUser}
      />
      <AvailableUsers
        merchantAccount={merchantAccount}
        onAdd={handleAddAuthorizedUser}
        onRemove={handleRemoveAuthorizedUser}
      />
    </SiderContainer>
  );
};

export default AccountDetail;
