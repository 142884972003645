import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styles from '../Content.module.scss';
import BranchIcon from './BranchIcon/BranchIcon';
import BranchTitle from './BranchTitle/BranchTitle';

const BranchContent = (props) => {
  const { branch, splitter, isActive, isParentActive } = props;

  const { t } = useTranslation();

  return (
    <div
      key={branch.id}
      role='presentation'
      className={classnames(styles.root, {[styles.active]: isActive}, {[styles.parentActive]: isParentActive})}
    >
      <BranchIcon
        branch={branch}
        type={splitter.type}
      />
      <div
        className={styles.text}
      >
        <div
          className={styles.textContent}
        >
          <div
            className={styles.title}
          >
            <BranchTitle
              splitter={splitter}
              branch={branch}
              type={splitter.type}
            />
          </div>
          <div
            className={styles.type}
          >
            { t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${splitter.type}.label`) }
          </div>
        </div>
        {branch.id === "branchTemp" && (
          <Spin indicator={<LoadingOutlined />} />
        )}
      </div>
    </div>
  );
};

export default BranchContent;