import React, { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client';
import { useMe } from '../../../contexts/me.context';
import { useCan } from '../../../contexts/ability.context';
import Page from '../Common/Page/Page';
import styles from '../Brainpower/Brainpower.module.scss';
import LordIcon from '../../../assets/icons/LordIcon';
import {merchantAccountQuery} from './query';

export const MerchantAccountContext = createContext(undefined);

export const useMerchantAccount = () => useContext(MerchantAccountContext);

const MerchantAccountProvider = ({ children }) => {
  const me = useMe();
  const can = useCan();

  const [searchParams] = useSearchParams();

  const { data: { merchantAccount } = { merchantAccount: null }} = useApolloQuery(merchantAccountQuery, {
    variables: {
      id: searchParams.get('maccount')
    }
  });

  if (
    me.companies[0].company.merchantAccountCount === 0 ||
    (!can('access', 'all-accounts') && me.companies[0].authorizedMerchantAccounts.length === 0)
  ) {
    return (
      <Page>
        <div className={styles.noAccess}>
          <LordIcon type="roadblock-lineal-edited" width={136} height={136} isStopped={false} autoplay />
          <div>You don&apos;t have access to any account.</div>
          <div>Please contact your administrator.</div>
        </div>
      </Page>
    );
  }

  return (
    <MerchantAccountContext.Provider
      value={{
        selectedMerchantAccount: searchParams.get('maccount'),
        merchantAccount,
      }}
    >
      {children}
    </MerchantAccountContext.Provider>
  );
};

export default MerchantAccountProvider;
