import React from 'react';
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import styles from '../../RuleEditor.module.scss';
import Splitter from '../Splitter';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import AddSplitterButton from '../AddSplitterButton/AddSplitterButton';
import MerchantContract from '../MerchantContract/MerchantContract';
import {useRuleEditor} from '../../ruleEditor.context';
import {updateNextMutation} from './query';
import BranchContent from '../Content/BranchContent/BranchContent';

const Branch = (props) => {
  const { branch, col, splitter, isParentActive } = props;
  const { editedItem, setEditedItem, setIsSiderOpen } = useRuleEditor();
  const isActive = editedItem?.edition === 'branch' && editedItem?.branch?.id === branch.id;

  const [updateNext] = useApolloMutation(updateNextMutation);

  return (
    <>
      <div
        key={branch.id}
        role='presentation'
        className={styles.branch}
        style={{
          gridColumnStart: col,
        }}
        onClick={(() => {
          setEditedItem({
            branch,
            splitter,
            edition: 'branch',
          });
          setIsSiderOpen(true);
        })}
      >
        <BranchContent
          id={branch.id}
          branch={branch}
          splitter={splitter}
          isActive={isActive}
          isParentActive={isParentActive}
        />
      </div>
      {branch.next ? (
        <>
          <HorizontalLine
            isParentActive={isActive || isParentActive}
          />
          <Splitter
            splitter={{
              ...branch.next,
              parent: splitter,
            }}
            col={col+2}
            isParentActive={isActive || isParentActive}
          />
        </>
      ) : (
        <>
          {/*Not allowed to add next if after a depth of 8*/}
          {col === 17 ? (
            <HorizontalLine
              isParentActive={isActive || isParentActive}
            />
          ) : (
            <AddSplitterButton
              handleAdd={(nextSplitterType) => {
                updateNext({
                  variables: {
                    id: branch.id,
                    input: {
                      type: nextSplitterType,
                      color: '#000000',
                      branches: [],
                      other: null,
                    },
                  },
                  optimisticResponse: {
                    updateNextBranch: {
                      ...branch,
                      contract: null,
                      blocking: false,
                      next: {
                        id: 'splitterTemp',
                        type: nextSplitterType,
                        color: '#000000',
                        branches: [],
                        other: null,
                        blocking: false,
                        contract: null,
                        __typename: 'Splitter',
                      },
                    },
                  },
                }).then((result) => {
                  setEditedItem({
                    edition: 'splitter',
                    splitter: {
                      ...result.data.updateNextBranch.next,
                      parent: splitter,
                    },
                  });
                  setIsSiderOpen(true);
                });
              }}
              col={col+2}
              branch={branch}
              isParentActive={isActive || isParentActive}
            />
          )}
          <MerchantContract
            contract={branch?.contract}
            isParentActive={isActive || isParentActive}
            branch={branch}
            splitter={{
              ...splitter,
              branch
            }}
          />
        </>
      )}
    </>
  )
};

export default Branch;