import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Space} from 'antd';
import ButtonElem from '../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import {useMerchantAccount} from '../../../merchantAccount.context';
import {addCertificateMutation, merchantAccountCertificatesQuery} from './query';
import Certificate from './Certificate/Certificate';
import Loader from '../../../../Common/Loader/Loader';

const Certificates = (props) => {
  const { merchantAccount } = useMerchantAccount();

  const [activeOnCreate, setActiveOnCreate] = useState(false);

  const [ addCertificate, { loading: loadingAdd} ] = useMutation(addCertificateMutation, {
    variables: {
      id: merchantAccount?.id,
    }
  });

  const { data: { merchantAccount: { certificates } } = { merchantAccount: { certificates: [] } } } = useQuery(merchantAccountCertificatesQuery, {
    variables: {
      merchantAccount: merchantAccount?.id,
    },
  });

  useEffect(() => {
    if (certificates) {
      setActiveOnCreate(true);
    }
  }, [certificates]);

  return (
    <>
      <Column id="certificates" {...props}>
        <ButtonElem
          disabled={loadingAdd}
          key='addCertificate'
          title={<Space>Add new certificate {loadingAdd && <Loader />}</Space>}
          handleClick={() => addCertificate()}
        />
        { [...certificates]
          .filter(certificate => !certificate.deleted)
          .sort((a, b) => (b.status !== a.status ? (b.status === 'active' ? 1 : -1) : b.name < a.name ? 1 : -1))
          .map(certificate => (
          <Certificate
            id={certificate.id}
            key={certificate.id}
            certificate={certificate}
            activeOnCreate={activeOnCreate}
          />
        ))}
      </Column>
    </>
  )
};

export default Certificates;