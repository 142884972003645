import React from 'react';
import Column from "../../../../../Common/NavigationContainer/Column/Column";
import Elem from "../../../../../Common/NavigationContainer/Column/Elem/Elem";
import NewHeader from "./NewHeader/NewHeader";
import HeaderActions from "./HeaderActions/HeaderActions";
import { useCan } from "../../../../../../../contexts/ability.context";

const Headers = ({ webhook, ...props }) => {
  const can = useCan();

  return (
    <Column
      {...props}
    >
      {can('update', 'webhook') && (
        <Elem
          title='Add header'
          id='Add header'
          key='Add header'
          subColumn={
            <NewHeader
              webhook={webhook}
            />
          }
        />
      )}
      {Object.keys(webhook.additional_headers ?? {}).sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)).map(headerKey => (
        <Elem
          title={headerKey}
          id={headerKey}
          key={headerKey}
          value={can('update', 'webhook') && webhook.additional_headers[headerKey]}
          subColumn={can('update', 'webhook') && (
            <HeaderActions
              webhook={webhook}
              header={{
                key: headerKey,
                value: webhook.additional_headers[headerKey],
              }}
            />
          )}
        />
      ))}
    </Column>
  );
};

export default Headers;