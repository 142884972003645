import React, { useContext, useEffect } from 'react';
import { message } from 'antd';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import { useMutation as useApolloMutation } from '@apollo/client/react/hooks/useMutation';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import SelectElem from '../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import LoadingElem from '../../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';
import { useCan } from '../../../../../../contexts/ability.context';
import { accountPartnersQuery } from './query';
import { MutatingContext } from '../../../../Common/NavigationContainer/NavigationContainer';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { createContractMutation, partnersQuery } from '../query';
import Elem from '../../../../Common/NavigationContainer/Column/Elem/Elem';
import PartnerConfigurationForm from '../Common/PartnerConfigurationForm/PartnerConfigurationForm';
import { useProgramManagerId } from '../../../../../../contexts/app.context';

const Risk = (props) => {
  const can = useCan();

  const { selectedMerchantAccount } = useMerchantAccount();
  const { setIsMutating } = useContext(MutatingContext);
  const [programManagerId] = useProgramManagerId();

  const {
    data: { partners } = { partners: [] },
    loading: loadingPartners,
  } = useApolloQuery(partnersQuery, {
    variables: {
      programManagerId,
    },
  });
  const [getAccountPartners, { data: { merchantAccount } = { merchantAccount: null }, loading: loadingContracts }] =
    useApolloLazyQuery(accountPartnersQuery, {
      variables: {
        id: selectedMerchantAccount,
      },
    });

  useEffect(() => {
    if (selectedMerchantAccount) {
      getAccountPartners();
    }
  }, [selectedMerchantAccount]);

  const [createContract, { loading }] = useApolloMutation(createContractMutation);

  useEffect(() => {
    setIsMutating(loading);
    return () => {
      setIsMutating(false);
    };
  }, [loading]);

  const partnersToAdd =
    partners.filter(
      (partner) =>
        !merchantAccount?.partners?.find((maPartner) => maPartner.id === partner.id) &&
        partner.type === 'risk_assessment' &&
        partner.status === 'active',
    ) ?? [];

  return (
    <Column id="risk" {...props}>
      {can('create', 'merchant_contract') && partnersToAdd.length && (
        <SelectElem
          key="addPartner"
          title="Add a partner"
          options={partnersToAdd.sort((a, b) => (a.name > b.name ? 1 : -1))}
          value={' '}
          handleChange={(partnerId) =>
            createContract({
              variables: {
                id: selectedMerchantAccount,
                partner: partnerId,
                partnerType: 'risk_assessment',
              },
            }).then(() => {
              message.success('Risk assessment partner successfully added');
            })
          }
          allowClear={false}
        />
      )}
      {!merchantAccount?.partners && (loadingPartners || loadingContracts || !selectedMerchantAccount) && (
        <LoadingElem />
      )}
      {merchantAccount?.merchant_contracts
        .filter((merchantContract) => merchantContract?.partner?.type === 'risk_assessment')
        .map((merchantContract) => (
          <Elem
            key={merchantContract.id}
            id={merchantContract.id}
            title={merchantContract.partner.name}
            subColumn={
              can('update', 'merchant_contract') && <PartnerConfigurationForm merchantContract={merchantContract} />
            }
          />
        ))}
    </Column>
  );
};

export default Risk;
