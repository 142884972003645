import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './DragAndDrop.module.scss';

const DropArea = ({ name, dataFormat, onDrop, children }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData(dataFormat);
    setIsDragOver(false);
    if (data) {
      onDrop(data);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      id={`droparea-${name}`}
      key={`droparea-${name}`}
      className={classnames(styles.dropArea, { [styles.dragOver]: isDragOver })}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  );
};

export default DropArea;
