import React, { useState } from "react";
import { Button, message, Space, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import styles from "./ListInput.module.scss";
import CsvModal from "./CsvModal/CsvModal";

const ListInput = ({
  action,
  list,
  loading,
  onValid,
  validation,
}) => {
  const [content, setContent] = useState([]);
  const [contentCsv, setContentCsv] = useState('');
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);


  const validate = (elems) => {
    const valid = [];
    const wrong = [];
    elems.forEach(elem => {
      if (validation[list.type] && !validation[list.type].method(elem)) {
        wrong.push(elem);
      } else {
        valid.push(elem);
      }
    })
    return {
      valid,
      wrong,
    };
  }

  return (
    <Space
      className={styles.root}
    >
      <div>
        Upload a csv file
      </div>
      <Upload
        className={styles.upload}
        accept='text/csv'
        onChange={({file}) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            setIsUploadModalVisible(true)
            setContentCsv(event.target.result.replaceAll(' ', ''))
          }
          reader.readAsText(file.originFileObj)
        }}
        customRequest={() => null}
        showUploadList={false}
      >
        <Button
          block
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      </Upload>
      <div>
        Enter single or multiple values below
      </div>
      <div>
        <TextArea
          status={validate(content).wrong.length ? 'error' : null}
          className={styles.textArea}
          onChange={(event) => setContent(event.target.value.replace(/ |\r|\n/g, '').split(','))}
          value={content.reduce((acc, elem) => `${acc}${elem},`, []).slice(0, -1)}
        />
        <small
          className={validate(content).wrong.length && styles.isError}
        >
          {validation[list.type]?.message}
        </small>
      </div>
      <div>
        <Button
          block
          disabled={validate(content).wrong.length > 0 || !content.length}
          onClick={() => onValid({
            variables: {
              content: content.filter(elem => !!elem),
            },
          }).then(() => {
            message.success('List has been updated')
            setContent([])
          })}
          loading={loading && !isUploadModalVisible}
        >
          Submit data
        </Button>
      </div>

      <CsvModal
        visible={isUploadModalVisible}
        onClose={() => {
          setContentCsv('')
          setIsUploadModalVisible(false)
        }}
        content={contentCsv}
        validate={validate}
        onValid={onValid}
        action={action}
        loading={loading}
      />
    </Space>
  );
};

export default ListInput;