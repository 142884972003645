import { Skeleton, Space, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styles from './BoolElem.module.scss';
import IconsContainer from '../Common/IconsContainer/IconsContainer';
import Helper from '../Common/IconsContainer/Helper/Helper';

const usePrevious = (v) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = v;
  });
  return ref.current;
};

const BoolElem = ({
  active,
  callbackRef,
  canCopy,
  closeNextColumns,
  defaultValue,
  gridColumn,
  gridRow,
  handleChange,
  helper,
  helperShown,
  isError,
  isLoading,
  isValid,
  isWarning,
  locked,
  nbColumns,
  subColumn,
  subColumnProps,
  title,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue ?? value ?? ['']);

  const [isHovered, setIsHovered] = useState(false);

  const prevGridColumn = usePrevious(gridColumn);

  const isColumnActive = gridColumn === Math.ceil(nbColumns / 2);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  const renderSubColumn = () => {
    if (active && subColumn) {
      return React.cloneElement(subColumn, {
        ...subColumnProps,
        offsetY: gridRow,
      });
    }
    return null;
  };

  return (
    <>
      <div
        ref={callbackRef}
        className={classnames(styles.root, {
          [styles.oob]: gridColumn <= 0,
          [styles.isColumnActive]: gridColumn === Math.ceil(nbColumns / 2),
          [styles.isAppearing]: prevGridColumn === undefined,
          [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
          [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
        })}
        style={{
          gridRow,
          gridColumn,
        }}
        onClick={isColumnActive ? null : closeNextColumns}
        onKeyDown={isColumnActive ? null : closeNextColumns}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
        tabIndex={-1}
      >
        <div className={styles.title}>{title}</div>
        <div className={classnames(styles.inputContainer)}>
          {isLoading ? (
            <div className={classnames(styles.selectRoot, styles.skeletonContainer, { [styles.error]: isError })}>
              <Skeleton.Input
                active
                loading
                style={{
                  width: 100,
                  height: '22px',
                  margin: '8px',
                }}
              />
            </div>
          ) : (
            <Space
              className={classnames(styles.switchRoot, { [styles.error]: isError })}
            >
              <Switch
                onChange={handleChange ?? ((v) => setCurrentValue(v))}
              />
            </Space>
          )}
          <IconsContainer
            isError={isError}
            isValid={isValid}
            isWarning={isWarning}
            locked={locked}
            canCopy={canCopy}
            copyValue={currentValue}
          />
        </div>
      </div>
      <Helper gridColumn={gridColumn} gridRow={gridRow} shown={helperShown ?? (helper && isColumnActive && isHovered)}>
        {helper}
      </Helper>
      {renderSubColumn()}
    </>
  );
};

export default BoolElem;
