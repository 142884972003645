import {useQuery as useApolloQuery} from '@apollo/client/react/hooks/useQuery';
import {currenciesQuery} from '../../../../../Common/query';

const AuthorizationCurrencyBranchTitle = ({ branch }) => {
  const { data: { currencies } = { currencies: [] } } = useApolloQuery(currenciesQuery);

  return branch.condition.reduce((acc, currency) => `${acc}${currencies?.find(currencyElem => currencyElem.id === currency)?.name ?? currency}, `, '').slice(0, -2) ?? '';

};

export default AuthorizationCurrencyBranchTitle;