import React from 'react';
import { SiderContainer } from "../../../Common/Sider";
import styles from './EmptyListSider.module.scss';

const EmptyListSider = () => (
    <SiderContainer
      title='No list yet'
    >
      <div
        className={styles.content}
      >
        To begin using lists, create one by clicking on the <strong>Create list</strong> button.
      </div>
    </SiderContainer>
  );

export default EmptyListSider;