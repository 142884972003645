import React from "react";
import PlusCircleOutlineIcon from "@2fd/ant-design-icons/lib/PlusCircleOutline";
import classnames from 'classnames';
import styles from './VerticalLine.module.scss';
import { getNbRows } from "../../RuleEditor";
import {useRuleEditor} from '../../ruleEditor.context';

const VerticalLine = (props) => {
  const { splitter, isActive } = props;
  const { isEditMode, setEditedItem, setIsSiderOpen, editedItem } = useRuleEditor();
  const nbRows = getNbRows(splitter, true);

  return (
    <>
      <div
        className={classnames(styles.vLineContainer, {[styles.edit]: isEditMode}, {[styles.active]: isActive})}
        style={{
          height: `calc(${nbRows*100}% + ${nbRows*48}px + ${isEditMode ? '24px' : '0px'})`,
        }}
      >
        {
          isEditMode ? (
            <PlusCircleOutlineIcon
              className={classnames(styles.addIcon, {[styles.active]: editedItem?.edition === 'branch' && editedItem?.splitter?.id === splitter.id})}
              onClick={() => {
                setEditedItem({edition: 'branch', splitter});
                setIsSiderOpen(true)
              }}
            />
          ) : null
        }
      </div>
    </>
  )
};

export default VerticalLine;