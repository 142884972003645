import React from 'react';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import {Button, Form} from 'antd';
import siderStyles from '../../../../Sider.module.scss';

const FormButtons = (props) => {
  const { branch, removeBranch, loading = false, splitter, showSave } = props

  return (
    <Form.Item>
      <div
        className={siderStyles.formButtons}
      >
        { branch ? (
          <Button
            onClick={removeBranch}
          >
            <TrashCanIcon />
            Remove
          </Button>
        ) : null}
        {showSave && (
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading || splitter.id === 'splitterTemp'}
            loading={loading || splitter.id === 'splitterTemp'}
          >
            { branch ? 'Save' :  'Add'}
          </Button>
        )}
      </div>
    </Form.Item>
  )
};

export default FormButtons;