/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {Button, Space, Table, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';

import usePaymentMethods from '../../../../../../../../hooks/common/usePaymentMethods';
import styles from './ModalContent.module.scss';
import WarningIcon from '../../../../../../../../assets/icons/customs/Warning';
import {compareByProp} from '../../../../../../../../util/array';

const ModalContent = ({ onCancel, onConfirm, issues }) => {
  const paymentMethods = usePaymentMethods();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.root}>
      <WarningIcon />
      <div>
        <div className={styles.header}>
          Please note that the following payment options will not be available on this merchant account anymore:
        </div>
        <div>
          <div className={styles.content}>
            <Table
              columns={[
                {
                  title: 'Payment method',
                  dataIndex: 'paymentMethod',
                  key: 'paymentMethods',
                  render: (value) => {
                    return <Space>
                      <img src={paymentMethods[value]?.imgUrl} alt={paymentMethods[value]?.name} style={{ width: 24 }} />
                      <span>{paymentMethods[value]?.name ?? value}</span>
                    </Space>
                  }
                },
                {
                  title: 'Sales channel(s)',
                  dataIndex: 'salesChannel',
                  key: 'salesChannel',
                },
                {
                  title: 'Authorization currencie(s)',
                  dataIndex: 'authorizationCurrency',
                  key: 'authorizationCurrency',
                },
              ]}
              dataSource={Object.keys(issues).reduce((acc, pm) => {
                if (issues[pm] === null) {
                  acc.push({
                    paymentMethod: pm,
                    salesChannel: 'All',
                    authorizationCurrency: 'All'
                  })
                } else if (Object.values(issues[pm]).every((value) => value === null)) {
                  acc.push({
                    paymentMethod: pm,
                    salesChannel: Object.keys(issues[pm]).map(channel => t(`andMe.paymentNetwork.merchantContract.channels.${channel}`)).join(', '),
                    authorizationCurrency: 'All',
                  })
                } else {
                  Object.keys(issues[pm]).forEach((channel) => {
                    const salesChannel = t(`andMe.paymentNetwork.merchantContract.channels.${channel}`)
                    if (issues[pm][channel] === null) {
                      acc.push({
                        paymentMethod: pm,
                        salesChannel,
                        authorizationCurrency: 'All',
                      })
                    } else {
                      const currencies = issues[pm][channel].map(currency => t(`currencies.${currency}`));
                      acc.push({
                        paymentMethod: pm,
                        salesChannel,
                        authorizationCurrency: issues[pm][channel].length <= 5
                          ? currencies.join(', ')
                          : <>
                            {currencies.slice(0, 5).join(', ')}
                            <Tooltip title={currencies.join(', ')}>
                              ... +{issues[pm][channel].length - 1} more
                            </Tooltip>
                          </>
                      });
                    }
                  })
                }
                return acc;
              }, []).sort(compareByProp('paymentMethod'))}
              pagination={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button onClick={onCancel} className={styles.modalButton}>
          Cancel
        </Button>
        <Button
          className={styles.modalButton}
          onClick={async () => {
            await onConfirm();
            setIsLoading(false);
            onCancel();
          }}
          loading={isLoading}
        >
          Proceed anyway
        </Button>
      </div>
    </div>
  );
};

export default ModalContent;
