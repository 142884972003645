import React from 'react';
import classnames from 'classnames';
import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ activeColumns, nbColumns, setActiveColumns }) => (
  <div
    className={styles.root}
    style={{
      gridColumn: 1 + Math.trunc(nbColumns / 2),
    }}
  >
    <div className={classnames(styles.breadcrumb, { [styles.overflow]: activeColumns.length > 4 })}>
      <div
        key="first"
        className={classnames(styles.step, { [styles.hoverable]: activeColumns.length > 0 })}
        onClick={() => setActiveColumns([])}
        onKeyPress={() => setActiveColumns([])}
        role="button"
        tabIndex={0}
      >
        <div className={classnames(styles.stepDot, styles.first, { [styles.isActive]: activeColumns.length === 0 })} />
      </div>
      {new Array(4).fill(undefined).map((elem, index) => {
        const columnIndex = index + Math.max(0, activeColumns.length - 4) + 1;
        return (
          <div
            key={columnIndex}
            className={classnames(styles.step, { [styles.hoverable]: columnIndex < activeColumns.length })}
            onClick={() => setActiveColumns(activeColumns.slice(0, columnIndex))}
            onKeyDown={() => setActiveColumns(activeColumns.slice(0, columnIndex))}
            role="button"
            tabIndex={0}
          >
            <div className={classnames(styles.stepDot, { [styles.isActive]: activeColumns.length === columnIndex })} />
          </div>
        );
      })}
    </div>
  </div>
);

export default Breadcrumb;
