import React, {useEffect, useState} from 'react';
import { useQuery as useApolloQuery } from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {Form} from 'antd';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import { currenciesQuery } from '../../../../Common/query';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const PaymentMethodBranchInput = (props) => {
  const { splitter, branch, handleChange, removeBranch } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const { data: { currencies } = { currencies: [] }, loading } = useApolloQuery(currenciesQuery);

  useEffect(() => {
    form.setFieldValue('currencies', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const disabledOptions = branch ? [] : splitter.branches.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []);

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.authorizationCurrency.label`)} condition`}
      description='Select currencies in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.currencies);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true)
        }}
      >
        <Form.Item name='currencies'>
          <SelectBranchInput
            options={currencies
              ?.map((currency) => ({
                value: currency.alpha,
                label: `[${currency.alpha}] ${currency.name.charAt(0).toUpperCase()}${currency.name.slice(1)}`,
                disabled: disabledOptions.includes(currency.alpha),
              }))
              .filter(
                (thing, index, self) => index === self.findIndex((elem) => elem.value === thing.value && elem.label === thing.label),
              )
              .sort((a, b) => (a.label < b.label ? -1 : 1))
              .filter((currency) => currency.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Select currencies in the list"
            isLoading={loading}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default PaymentMethodBranchInput;
