import React, {useEffect, useState} from 'react';
import { useQuery as useApolloQuery } from '@apollo/client';
import {Form} from 'antd';
import { useTranslation } from 'react-i18next';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import { paymentMethodsQuery } from '../../../../Common/query';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const PaymentTypeBranchInput = (props) => {
  const { splitter, handleChange, removeBranch, branch } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const { data: { paymentMethods } = { paymentMethods: [] }, loading } = useApolloQuery(paymentMethodsQuery);

  useEffect(() => {
    form.setFieldValue('paymentTypes', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const disabledOptions = branch ? [] : splitter?.branches?.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []) ?? [];

  const paymentTypes = paymentMethods
    ?.reduce(
      (acc, paymentMethod) =>
        acc.find((paymentType) => paymentType.value === paymentMethod.type)
          ? acc
          : [
              ...acc,
              {
                value: paymentMethod.type,
                label: t(
                  `andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentMethodType.options.${paymentMethod.type}`,
                ),
                disabled: disabledOptions.includes(paymentMethod.type)
              },
            ],
      [],
    )
    .filter((paymentType) => paymentType.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentMethodType.label`)} condition`}
      description='Select payment types in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.paymentTypes);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true);
        }}
      >
        <Form.Item name='paymentTypes'>
          <SelectBranchInput
            options={paymentTypes}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Select payment types in the list"
            isLoading={loading}
            splitter={splitter}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default PaymentTypeBranchInput;
