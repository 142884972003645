import React, {useState} from 'react';
import { useMutation } from '@apollo/client';
import {Button, Divider, Form, Input, message, Select} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import {initializeApp} from 'firebase/app';
import {getAuth, SAMLAuthProvider, signInWithPopup} from 'firebase/auth';
import SiderContainer from '../../Common/Sider/SiderContainer/SiderContainer';
import Loader from '../../Common/Loader/Loader';
import styles from '../Sso.module.scss';
import { Block } from '../../Common/Sider';
import {
  applySsoConfigMutation, updateCompanyLoginMethodMutation,
  updateSsoDraftMutation
} from './query';

const SiderForm = ({ onClose, company }) => {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  }, 'company');

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [isTestOk, setIsTestOk] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isLoginMethodUpdated, setIsLoginMethodUpdated] = useState(false);

  const [updateSso, {loading: loadingUpdateSso}] = useMutation(
    updateSsoDraftMutation,
    {
      variables: {
        id: company.id,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  const [applySsoConfig, { data: dataApplySso, loading: loadingApplySso }] = useMutation(
    applySsoConfigMutation,
    {
      variables: {
        id: company.id,
      },
      onError: async (err) => {
        message.error(err.graphQLErrors[0].message);
      }
    }
  );

  const [updateLoginMethod, updateState] = useMutation(
    updateCompanyLoginMethodMutation,
    {
      variables: {
        id: company.id,
      }
    }
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFinish = ({acsUrl, nameIdFormat, ...values}) => form.validateFields()
    .then(() => {
      updateSso({
        variables: {
          input: {
            ...values,
            x509Certificates: [values.x509Certificates]
          }
        }
      }).then(({data: {updateCompanySsoDraft: merchantCompany}} = {data: {updateCompanySsoDraft: null}}) => {
        const provider = new SAMLAuthProvider(merchantCompany?.sso?.draft?.providerId);
        const auth = getAuth(app);
        auth.tenantId = merchantCompany?.sso?.draft?.tenantId;
        auth.useDeviceLanguage();
        signInWithPopup(auth, provider).then(() => {
          message.success('Single Sign On connexion successful, you can now activate it');
          setIsTestOk(true);
        }).catch(e =>
          message.error('An error occurred, please check your configuration or make sure the account you logged in with has access to your SSO service')
        )
      })
    });

  if (!company) return <Loader />;

  return (
    <SiderContainer title="Login method" id="login-method">
      <Block title="Login method" description="Select the login method for your company users.">
        <Form
          form={form2}
          onFinish={(values) => updateLoginMethod({
            variables: {
              input: {
                loginMethod: values.loginMethod,
              }
            },
            optimisticResponse: {
              updateCompanyLoginMethod: {
                id: company.id,
                loginMethod: values.loginMethod,
                __typename: 'Company',
              }
            }
          }).then(() => setIsLoginMethodUpdated(false))}
          layout="vertical"
          className={styles.drawerForm}
        >
          <Form.Item
            label="Login method"
            name="loginMethod"
            initialValue={company.loginMethod}
          >
            <Select
              options={[
                {value: 'form', label: 'Form'},
                {value: 'sso', label: 'SSO', disabled: !company.sso.config.tenantId, title: 'test'},
              ]}
              onChange={() => setIsLoginMethodUpdated(true)}
            />
          </Form.Item>
          {isLoginMethodUpdated && (
            <>
              <Divider />
              <div className={styles.bottomButtons}>
                <Button block type="primary" htmlType="submit" loading={false}>
                    SAVE
                </Button>
              </div>
            </>
            )}
        </Form>
      </Block>
      <Form
        form={form}
        layout="vertical"
        className={styles.drawerForm}
        onFinish={handleFinish}
        requiredMark={false}
        initialValues={{
          ...company?.sso?.config,
          x509Certificates: company?.sso?.config?.x509Certificates?.[0],
          acsUrl: process.env.REACT_APP_ACS_URL,
          nameIdFormat: 'EMAIL',
        }}
        onFieldsChange={() => {
          setIsTouched(true);
          setIsTestOk(false);
        }}
      >
      <Block title="Single Sign On" description={company?.sso?.config?.tenantId && !isTouched ? 'You can now activate the SSO' : 'Fill in your SSO credentials before activating it.'}>
        <Form.Item
          name="acsUrl"
          label={
            <div className={styles.labelContainer}>
              <div>ACS URL</div>
              <CopyOutlined
                className={styles.copyIcon}
                onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_ACS_URL).then(() => message.success('Copied !'))}
              />
            </div>
          }
        >
          <Input
            disabled
          />
        </Form.Item>
        <Form.Item
          name='nameIdFormat'
          label={
            <div className={styles.labelContainer}>
              <div>Name ID format</div>
              <CopyOutlined
                className={styles.copyIcon}
                onClick={() => navigator.clipboard.writeText('EMAIL').then(() => message.success('Copied !'))}
              />
            </div>
          }
        >
          <Input
            disabled
          />
        </Form.Item>
        <Form.Item
          label="IdP-provided Entity ID"
          name="idpEntityId"
          rules={[{required: true}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="SSO URL"
          name="ssoURL"
          rules={[{required: true}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Customer-defined SAML Entity ID"
          name="rpEntityId"
          rules={[{required: true}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Certificate"
          name="x509Certificates"
          rules={[{required: true}]}
        >
          <Input.TextArea
            rows={6}
          />
        </Form.Item>
        <Divider />
        {company?.sso?.config?.tenantId && !isTouched && company?.loginMethod !== 'sso' && (
          <div className={styles.validMessage}>
            The configuration is valid, you can now activate the SSO as Login Method
          </div>
        )}
        <div className={styles.bottomButtons}>
          {isTouched && (
            isTestOk ? (
              <Button
                block
                type="primary"
                loading={loadingApplySso}
                onClick={() => applySsoConfig().then(() => {
                  setIsTouched(false);
                  setIsTestOk(false);
                  message.success('Your Single Sign On configuration is now saved, you can define it as your login method');
                })}
              >
                APPLY CONFIGURATION
              </Button>
            ) : (
              <Button block type="primary" htmlType="submit" loading={false}>
                TEST CONFIGURATION
              </Button>
            )
          )}
          <Button block type="default" onClick={onClose}>
            {isTouched ? 'CANCEL' : 'CLOSE'}
          </Button>
          {isTouched && (
            <Button
              block
              type="default"
              onClick={() => {
                setIsTestOk(false);
                setIsTouched(false);
                form.resetFields();
              }}
            >
              RESET
            </Button>
          )}
        </div>
      </Block>
    </Form>
</SiderContainer>
)
  ;
};

export default SiderForm;
