import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SwapRightOutlined, WarningOutlined} from '@ant-design/icons';
import {Form, InputNumber, Space} from 'antd';
import referenceCurrencies from 'norbr-shared-lib/constants/referenceCurrencies';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

export const getDefaultValues = (condition) => {
  let fromTo;
  switch (condition[0]) {
    case 'min':
      fromTo = {
        from: null,
        to: condition[1],
      };
      break;
    case 'max':
      fromTo = {
        from: condition[1],
        to: null,
      };
      break;
    default:
      fromTo = {
        from: condition[0],
        to: condition[1],
      }
  }
  return fromTo;
}

const AmountBranchInput = (props) => {
  const { handleChange, merchantAccount, branch, removeBranch, splitter } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [helperText, _setHelperText] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const currencySymbol = referenceCurrencies[merchantAccount.reference_currency].symbol;

  const label = t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.amount.label`);

  const setHelperText = ({from, to}) => {
    if (from && to) {
      if (parseFloat(from) > parseFloat(to)) {
        _setHelperText(
          <div
            style={{
              color: 'red',
            }}
          >
            <WarningOutlined width={16} height={16} /> min should be lower than max
          </div>
        );
      } else {
        _setHelperText(`Amount >= ${from} and < ${to}`);
      }
    } else if (from) {
      _setHelperText(`Amount >= ${from}`);
    } else if (to) {
      _setHelperText(`Amount < ${to}`);
    } else {
      _setHelperText('');
    }
  }

  useEffect(() => {
    if (branch?.condition) {
      const defaultValues = getDefaultValues(branch.condition);
      setHelperText(defaultValues);
      form.setFieldsValue(defaultValues);
    } else {
      form.setFieldsValue({ from: null, to: null});
    }
    setHasChanged(false);
  }, [branch])

  return (
    <Block
      title={`${label} condition`}
      description='Select a minimum or maximum amount or both to define a range'
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={(values) => {
          if (values.from && values.to) {
            handleChange([values.from.toString(), values.to.toString()]);
          } else if (values.from) {
            handleChange(['max', values.from.toString()]);
          } else if (values.to) {
            handleChange(['min', values.to.toString()]);
          }
          setHasChanged(false)
        }}
        onValuesChange={(_, values) => {
          setHasChanged(true)
          setHelperText(values);
        }}
      >
        <Space>
          <Form.Item
            name='from'
            style={{
              marginBottom: 0,
            }}
          >
            <InputNumber
              autoFocus
              min={0}
              placeholder='Min'
              addonAfter={currencySymbol}
            />
          </Form.Item>
          <SwapRightOutlined style={{ fontSize: 16, color: 'lightgrey' }} />
          <Form.Item
            name='to'
            style={{
              marginBottom: 0,
            }}
          >
            <InputNumber
              min={0}
              placeholder='Max'
              addonAfter={currencySymbol}
            />
          </Form.Item>
        </Space>
        <div style={{textAlign: 'center', margin: '4px 0 24px'}}>
          {helperText}
        </div>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default AmountBranchInput;
