import React, { useState } from 'react';
import { Dropdown, Input } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import styles from '../../AddableList.module.scss';
import useToggle from '../../../../../hooks/useToggle';
import { Block } from '../../../Common/Sider';
import Item from '../../../Common/SiderList/Item';
import DropArea from '../../../Common/DragAndDrop/DropArea';

const LinkedUsers = ({ merchantAccount, onAdd, onRemove }) => {
  const [searchInput, setSearchInput] = useState();
  const [displayAll, { toggle }] = useToggle(true);

  const itemList = [...merchantAccount.users]
    .sort((a, b) => (a.user.full_name.toLowerCase() > b.user.full_name.toLowerCase() ? 1 : -1))
    .map(({ id, user, aclProfile }) => ({
      id,
      label: user.full_name,
      icon: UserOutlined,
      avatar: user.avatar,
      profile: aclProfile.name,
      title: user.full_name,
    }));

  const renderItem = onRemove
    ? (item) => (
        <Dropdown
          key={item.id}
          menu={{
            items: [{ key: 'delete', label: 'Remove', onClick: () => onRemove(item.id) }],
          }}
          trigger={['contextMenu']}
        >
          <div>
            <Item key={item.id} {...item} />
          </div>
        </Dropdown>
      )
    : (item) => <Item key={item.id} {...item} />;

  return (
    <Block id="linked-users" title="Authorized users" description="Right click to remove access to account">
      {itemList.length > 5 && (
        <Input
          className={styles.searchInput}
          placeholder="Search users"
          allowClear
          size="normal"
          prefix={<SearchOutlined />}
          onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
        />
      )}
      <div className={styles.searchResults}>
        {searchInput &&
          itemList
            .filter((item) => item.label.toLocaleLowerCase().includes(searchInput)) // apply search
            .map(renderItem)}
      </div>
      <div className={styles.subtitle}>{itemList.length} users</div>
      {itemList
        .filter((item) => !searchInput || !item.label.toLocaleLowerCase().includes(searchInput)) // filter current searched
        .slice(0, displayAll ? itemList.length : 5) // only 5
        .map(renderItem)}
      {itemList.length > 5 && (
        <div className={styles.seeAll} tabIndex={0} role="button" onClick={toggle} onKeyPress={toggle}>
          {displayAll ? 'see less' : 'see all'}
        </div>
      )}
      {onAdd && (
        <DropArea name="addUser" dataFormat="availableUser" onDrop={onAdd}>
          Drop here
        </DropArea>
      )}
    </Block>
  );
};

export default LinkedUsers;
