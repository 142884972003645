import React from 'react';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import styles from './TextInput.module.scss';

const TextInput = ({ locked, ...inputProps }) => (
  <Input className={styles.root} {...inputProps} disabled={locked} suffix={locked && <LockOutlined />} />
);

export default TextInput;
