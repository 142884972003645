import PlusCircleOutlineIcon from '@2fd/ant-design-icons/lib/PlusCircleOutline';
import React from 'react';
import SiderSplitter from './SiderSplitter/SiderSplitter';
import SiderBranch from './SiderBranch/SiderBranch';
import SiderContract from './SiderContract/SiderContract';
import SiderRule from './SiderRule/SiderRule';

const SiderContent = ({
  editedItem,
  rule,
}) => {
  const {contract, splitter, edition, branch} = editedItem
  switch (edition) {
    case 'branch':
      return (
        <SiderBranch
          splitter={splitter}
          branch={branch}
        />
      );
    case 'splitter':
      return (
        <SiderSplitter
          splitter={splitter}
        />
      );
    case 'contract':
      return (
        <SiderContract
          contract={contract}
          branch={branch}
          splitter={splitter}
        />
      );
    case 'rule':
      return (
        <SiderRule
          rule={rule}
        />
      );
    default:
      return (
        <div>
          Tip: select a condition (by clicking on <PlusCircleOutlineIcon />) to modify it specifically.
        </div>
      )
  }
};

export default SiderContent;