import React, {useEffect, useState} from 'react';
import issuerList from 'norbr-shared-lib/constants/issuers/list';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const IssuerBranchInput = (props) => {
  const { splitter, handleChange, branch, removeBranch } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    form.setFieldValue('issuers', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const disabledOptions = branch ? [] : splitter?.branches?.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []) ?? [];

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.issuer.label`)} condition`}
      description='Select issuers in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.issuers);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true)
        }}
      >
        <Form.Item name='issuers'>
          <SelectBranchInput
            options={issuerList
              ?.map(({ id, label: issuerLabel }) => ({
                value: id,
                label: issuerLabel,
                disabled: disabledOptions.includes(id)
              }))
              .filter(
                (thing, index, self) => index === self.findIndex((elem) => elem.value === thing.value && elem.label === thing.label),
              )
              .sort((a, b) => (a.label < b.label ? -1 : 1))
              .filter((issuer) => issuer.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Select issuers in the list"
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default IssuerBranchInput;
