import React, { useEffect } from 'react';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import Elem from '../../../../Common/NavigationContainer/Column/Elem/Elem';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import CredentialForm from './CredentialForm/CredentialForm';

import APICreation from './APICreation/APICreation';
import { useCan } from '../../../../../../contexts/ability.context';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { merchantAccountCredentialsQuery } from './query';
import LoadingElem from '../../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';

const Credentials = ({ ...props }) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const can = useCan();

  const [fetchCredentials, { data: { merchantAccount } = { merchantAccount: null }, loading }] = useApolloLazyQuery(
    merchantAccountCredentialsQuery,
  );

  useEffect(() => {
    if (selectedMerchantAccount) {
      fetchCredentials({
        variables: {
          id: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  const apiKeys = merchantAccount?.api_keys?.length ? [...merchantAccount?.api_keys] : [];

  return (
    <Column id="apiKeys" {...props}>
      {can('create', 'api_key') && (
        <Elem
          id="addCredentials"
          key="addCredentials"
          title="Add API credentials"
          value="Create new API key"
          subColumn={<APICreation />}
        />
      )}
      {!apiKeys.length && (loading || !selectedMerchantAccount) && <LoadingElem id="loader-apiKeys" />}
      {apiKeys
        .sort((a, b) => {
          if (a.status !== b.status) {
            return a.status === 'active' ? -1 : 1;
          }
          return b.name?.toLowerCase() < a.name?.toLowerCase() ? 1 : -1;
        })
        ?.map((apiKey) => (
          <Elem
            id={apiKey.id}
            key={apiKey.id}
            title={apiKey.name}
            value={apiKey.is_public ? 'Public' : 'Private'}
            // dropdownActions={[
            //   {
            //     label: 'Copy',
            //     call: () => {
            //       copyToClipboard(apiKey.key);
            //       notification.success({ message: 'Successfully copied to clipboard' });
            //     },
            //   },
            // ]}
            isValid={apiKey.status === 'active'}
            isWarning={apiKey.status !== 'active'}
            subColumn={<CredentialForm apiKey={apiKey} />}
          />
        ))}
    </Column>
  );
};

export default Credentials;
