import React, {useEffect, useState} from 'react';
import {Form, Input, InputNumber, Space} from 'antd';
import {SwapRightOutlined, WarningOutlined} from '@ant-design/icons';

export const arrayToObject = (fromTo) => {
  if (fromTo?.[0] === 'min') {
    return {
      from: undefined,
      to: fromTo[1],
    }
  }
  if (fromTo?.[0] === 'max') {
    return {
      from: fromTo[1],
      to: undefined,
    }
  }
  return {
    from: fromTo?.[0],
    to: fromTo?.[1],
  }
}

const objectToArray = (fromTo) => {
  if (fromTo?.from && fromTo?.to) {
    return [fromTo.from, fromTo.to];
  }
  if (fromTo?.from) {
    return ['max', fromTo.from]
  }
  if (fromTo?.to) {
    return ['min', fromTo.to]
  }
  return [];
}

const NumberInput = ({merchantDataKey}) => {
  const [helperText, _setHelperText] = useState('');
  const form = Form.useFormInstance();

  const setHelperText = ({from, to}) => {
    if (from && to) {
      if (parseFloat(from) > parseFloat(to)) {
        _setHelperText(
          <div
            style={{
              color: 'red',
            }}
          >
            <WarningOutlined width={16} height={16} /> min should be lower than max
          </div>
        );
      } else {
        _setHelperText(`${merchantDataKey} >= ${from} and < ${to}`);
      }
    } else if (from) {
      _setHelperText(`${merchantDataKey} >= ${from}`);
    } else if (to) {
      _setHelperText(`${merchantDataKey} < ${to}`);
    } else {
      _setHelperText('');
    }
  }

  useEffect(() => {
    setHelperText(arrayToObject(form.getFieldValue('condition')))
  }, [])

  return (
    <Form.Item
      name='condition'
      normalize={(value, prevValue, store) => {
        setHelperText(store);
        return objectToArray(store);
      }}
    >
      <Space
        direction='vertical'
        align='center'
      >
        <Space>
          <Form.Item
            name='from'
            style={{
              marginBottom: 0,
            }}
          >
            <Input
              autoFocus
              placeholder='Min'
              type='number'
            />
          </Form.Item>
          <SwapRightOutlined style={{ fontSize: 16, color: 'lightgrey' }} />
          <Form.Item
            name='to'
            style={{
              marginBottom: 0,
            }}
          >
            <Input
              placeholder='Max'
              type='number'
            />
          </Form.Item>
        </Space>
        {helperText}
      </Space>
    </Form.Item>
  );
}

export default NumberInput;