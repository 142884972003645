import React, {useEffect} from 'react';
import {CheckCircleFilled, ExclamationCircleFilled} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {useLazyQuery} from '@apollo/client';
import styles from '../../RuleEditor.module.scss';
import {useRuleEditor} from '../../ruleEditor.context';
import ContractContent from '../Content/ContractContent/ContractContent';
import {merchantContractRoutesQuery} from './query';
import Loader from '../../../../Common/Loader/Loader';
import {isContractAvailable} from '../../Sider/SiderContent/SiderContract/utils';

const MerchantContract = (props) => {
  const { contract, isParentActive, branch, splitter } = props;
  const { isEditMode, editedItem, setEditedItem, setIsSiderOpen } = useRuleEditor();

  const isActive = editedItem?.edition === 'contract' && (editedItem?.branch ? editedItem?.branch?.id === branch?.id : !branch && editedItem?.splitter?.id === splitter?.id);

  const [getMerchantAccountWithRoutes, { data: { merchantContract: merchantContractWithRoutes } = { merchantContract: null } }] = useLazyQuery(merchantContractRoutesQuery);

  useEffect(() => {
    if (contract) {
      getMerchantAccountWithRoutes({
        variables: {
          id: contract?._id ?? contract?.id,
        }
      })
    }
  }, [contract]);

  if (!contract) {
    return (
      <>
        <div
          role='presentation'
          className={styles.contractSelect}
          onClick={branch || splitter ? (() => {
            setEditedItem({
              contract,
              branch,
              splitter,
              edition: 'contract',
            });
            setIsSiderOpen(true)
          }) : () => null}
        >
          <ContractContent
            contract={null}
            blocking={branch?.blocking || (!branch && splitter?.blocking)}
            isParentActive={isParentActive}
            isActive={isActive}
          />
        </div>
        <div>
          {!(branch?.blocking || (!branch && splitter?.blocking)) && (
            <ExclamationCircleFilled
              style={{
                padding: '18px 9px',
                color: '#ffa933',
                fontSize: 22,
                opacity: isEditMode ? .5 : 1,
              }}
            />
          )}
        </div>
      </>
    )
  }

  const isAvailable = merchantContractWithRoutes && isContractAvailable(merchantContractWithRoutes, splitter);

  return (
    <>
      <div
        role='presentation'
        className={styles.contract}
        onClick={() => {
          setEditedItem({
            contract,
            branch,
            splitter,
            edition: 'contract',
          });
          setIsSiderOpen(true)
        }}
      >
        <ContractContent
          contract={contract}
          isParentActive={isParentActive}
          isActive={isActive}
        />
      </div>
      <div>
        {merchantContractWithRoutes ? (
          <Tooltip
            title={(() => {
              if (contract?.status === 'active') {
                if (isAvailable) {
                  return null;
                }
                return 'This contract does not match the conditions anymore'
              }
              return `This merchant contract is ${contract?.status}`
            })()}
          >
            {contract?.status === 'active' && isAvailable ? (
              <CheckCircleFilled
                style={{
                  padding: '18px 9px',
                  color: '#55c985',
                  fontSize: 22,
                  opacity: isEditMode ? .5 : 1,
                }}
              />) : (
              <ExclamationCircleFilled
                style={{
                  padding: '18px 9px',
                  color: '#ffa933',
                  fontSize: 22,
                  opacity: isEditMode ? .5 : 1,
                }}
              />
            )}
          </Tooltip>
        ) : (
          <div
            style={{
              padding: '18px 9px',
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </>
  )
};

export default MerchantContract;