import React from 'react';
import styles from './SiderContainer.module.scss';
import CloseIcon from '@2fd/ant-design-icons/lib/Close';

const SiderContainer = (props) => {
  const { children, title, id, handleClose = false } = props;
  return (
    <div id={id} className={styles.root}>
      {typeof title === 'string' ? (
        <div className={styles.header}>
          {title}
          {handleClose ? (
            <CloseIcon
              className={styles.closeIcon}
              onClick={handleClose}
            />
          ) : null}
        </div>
      ) : title}
      {children}
    </div>
  );
};

export default SiderContainer;
