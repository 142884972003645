import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import styles from './NavigationContainer.module.scss';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Loader from '../Loader/Loader';

export const ColumnsContext = React.createContext(undefined);

export const MutatingContext = React.createContext(undefined);

const NavigationContainer = ({ columnSteps, children, navigationId, forcedActiveColumns }) => {
  let nbColumns;

  const containerRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeColumns = useMemo(() => searchParams.get(navigationId)?.split('-') ?? [], [searchParams]);
  const setActiveColumns = useCallback(
    (value) => {
      if (value.length) {
        searchParams.set(navigationId, value.join('-'));
      } else {
        searchParams.delete(navigationId)
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (forcedActiveColumns) {
      setActiveColumns(forcedActiveColumns);
    }
  }, [forcedActiveColumns]);

  const [isMutating, setIsMutating] = useState(false);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  if (isBigScreen) {
    nbColumns = columnSteps.md;
  } else {
    nbColumns = columnSteps.sm;
  }

  const toggleElem = (columnIndex, columnId) => {
    const newActiveColumns = [...activeColumns];
    if (newActiveColumns[columnIndex] === columnId) {
      newActiveColumns.splice(columnIndex, activeColumns.length - columnIndex);
    } else {
      newActiveColumns.splice(columnIndex, activeColumns.length - columnIndex, columnId);
    }
    setActiveColumns(newActiveColumns);
  };

  const closeNextColumns = (columnIndex) => {
    setActiveColumns([...activeColumns].slice(0, columnIndex));
  };

  const [nodeScrolledTo, setNodeScrolledTo] = useState(null);

  const scrollOnCreate = (node) => {
    if (node && node !== nodeScrolledTo) {
      node?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      setNodeScrolledTo(node);
    }
  };

  return (
    <div
      ref={containerRef}
      className={classnames(styles.navigationContainer, { [styles.isLoading]: isMutating })}
      style={{
        gridTemplateColumns: `repeat(${nbColumns}, minmax(0, 1fr))`,
      }}
    >
      {isMutating && <Loader className={styles.loader} height={70} width={70} />}
      <ColumnsContext.Provider
        value={{
          activeColumns,
          setActiveColumns,
        }}
      >
        <MutatingContext.Provider value={{ setIsMutating }}>
          <Breadcrumb activeColumns={activeColumns} nbColumns={nbColumns} setActiveColumns={setActiveColumns} />
          {React.Children.map(children, (child) => (
            <child.type
              key={child.props.id}
              nbColumns={nbColumns}
              column={0}
              toggleElem={toggleElem}
              closeNextColumns={closeNextColumns}
              offsetX={activeColumns.length}
              offsetY={2}
              activeColumns={activeColumns}
              scrollOnCreate={scrollOnCreate}
              {...child.props}
            />
          ))}
        </MutatingContext.Provider>
      </ColumnsContext.Provider>
    </div>
  );
};

export default NavigationContainer;
