import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavigateWithSearch } from '../../../../util/navigate';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import ScaRules from './ScaRules/ScaRules';
import AuthorizationFallbacks from './AuthorizationFallbacks/AuthorizationFallbacks';
import AuthenticationFallbacks from './AuthenticationFallbacks/AuthenticationFallbacks';

const Optimizer = () => (
  <Routes>
    <Route path="general-settings" element={<GeneralSettings />} />
    <Route path="sca-rules" element={<ScaRules />} />
    <Route path="authorization-fallbacks" element={<AuthorizationFallbacks />} />
    <Route path="authentication-fallbacks" element={<AuthenticationFallbacks />} />
    <Route path="/" element={<NavigateWithSearch to="general-settings" />} />
  </Routes>
);

export default Optimizer;
