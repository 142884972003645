import React, { useContext, useEffect, useState } from 'react';
import { Badge, Dropdown, Input, Tooltip } from 'antd';
import { useLazyQuery as useApolloLazyQuery, useMutation as useApolloMutation } from '@apollo/client';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { Block } from '../../../../../../Common/Sider';
import { MerchantContractContext } from '../../MerchantContractDrawer';
import { partnerPaymentMethodsQuery } from '../../query';
import Item from '../../../../../../Common/SiderList/Item';
import styles from './PaymentMethods.module.scss';
import { ContractEditionContext } from '../../../../PaymentNetwork';
import { addPaymentMethodMutation, removePaymentMethodMutation } from './query';
import HandleRoutesModal from '../../HandleRoutesModal/HandleRoutesModal';
import DropArea from './DropArea/DropArea';

const PaymentMethods = ({ ref, setEditedPaymentMethod }) => {
  const { t } = useTranslation();

  const { isEdited, setIsSaving } = useContext(ContractEditionContext);

  const { merchantContract } = useContext(MerchantContractContext);
  const [
    getPaymentMethods,
    {
      data: { partner: { payment_methods } } = {
        partner: {
          payment_methods: [],
        },
      },
    },
  ] = useApolloLazyQuery(partnerPaymentMethodsQuery);

  const [pmIssues, setPmIssues] = useState(null);

  const [addPaymentMethod, { loading: loadingAdd }] = useApolloMutation(addPaymentMethodMutation);
  const [removePaymentMethod, { loading: loadingRemove }] = useApolloMutation(removePaymentMethodMutation, {
    onError: (e) => setPmIssues(e.graphQLErrors.find((ee) => ee.extensions.code === '409')?.extensions?.issues),
  });

  useEffect(() => {
    setIsSaving(loadingAdd || loadingRemove);
  }, [loadingAdd, loadingRemove]);

  const [paymentMethodFilter, setPaymentMethodFilter] = useState('');


  const [paymentMethodId, setPaymentMethodId] = useState(null);

  useEffect(() => {
    if (merchantContract?.id) {
      getPaymentMethods({
        variables: {
          id: merchantContract.partner.id,
          partnerRouteFilters: {
            channel: merchantContract?.channels,
            settlement_currency: merchantContract?.settlement_currency,
            status: 'active',
          },
        },
      });
    }
  }, [merchantContract?.id, merchantContract?.channels, merchantContract?.settlement_currency]);

  const filterPaymentMethods = (paymentMethod) =>
    t(`andMe.paymentNetwork.merchantContract.payment_methods.${paymentMethod.id}`)
      .toLowerCase()
      .indexOf(paymentMethodFilter.toLowerCase()) > -1;

  if (!merchantContract?.channels?.length || !merchantContract.settlement_currency) {
    return (
      <Block title="Payment methods">
        Please select a settlement currency and at least one sales channel above to continue
      </Block>
    );
  }

  const availablePaymentMethods = payment_methods?.filter(
    (paymentMethod) => !merchantContract?.payment_methods?.find((pm) => pm.id === paymentMethod.id),
  );

  return (
    <Block
      title="Payment methods"
      description={isEdited && 'Drag and drop to activate'}
      className={classnames(styles.root, { [styles.isLoading]: loadingAdd || loadingRemove })}
    >
      {isEdited && (
        <Input
          placeholder="Search payment methods"
          prefix={<SearchOutlined />}
          value={paymentMethodFilter}
          onChange={(event) => setPaymentMethodFilter(event.target.value)}
        />
      )}
      <div key="activeCount" ref={ref}>
        <Badge
          className={classnames(styles.badge, styles.active)}
          count={merchantContract?.payment_methods?.length ?? 0}
          showZero
          dot={false}
        />
        Active
      </div>
      {[...(merchantContract?.payment_methods?.filter(filterPaymentMethods) ?? [])]
        ?.sort((a, b) =>
          t(`andMe.paymentNetwork.merchantContract.payment_methods.${b.id}`) <
          t(`andMe.paymentNetwork.merchantContract.payment_methods.${a.id}`)
            ? 1
            : -1,
        )
        ?.map((paymentMethod) => (
          <Dropdown
            key={paymentMethod.id}
            menu={{
              items: [
                {
                  key: 'delete',
                  label: 'Remove',
                  onClick: () => {
                    setPaymentMethodId(paymentMethod.id);
                    removePaymentMethod({
                      variables: {
                        id: merchantContract.id,
                        input: {
                          paymentMethod: paymentMethod.id,
                        },
                      },
                    }).catch((e) => setPmIssues(e.graphQLErrors.find((ee) => ee.extensions.code === '409')?.extensions?.issues));
                  },
                },
              ],
            }}
            trigger={isEdited ? ['contextMenu'] : []}
          >
            <div className={classnames(styles.activePaymentMethod, { [styles.draggable]: isEdited })}>
              <Item
                id={paymentMethod.id}
                label={t(`andMe.paymentNetwork.merchantContract.payment_methods.${paymentMethod.id}`)}
                isDraggable
                color="rgba(0, 0, 0, 0)"
                icon={() => <img src={paymentMethod?.imgUrl} alt="" width={51} />}
                noPadding
                onClick={() => setEditedPaymentMethod(paymentMethod.id)}
              />
            </div>
          </Dropdown>
        ))}
      {isEdited && <DropArea addPaymentMethod={addPaymentMethod} />}
      <div key="availableCount">
        <Badge
          className={classnames(styles.badge, styles.available)}
          count={availablePaymentMethods?.filter(filterPaymentMethods)?.length ?? 0}
          dot={false}
          showZero
        />
        Available
      </div>
      {[...(availablePaymentMethods?.filter(filterPaymentMethods) ?? [])]
        ?.sort((a, b) =>
          t(`andMe.paymentNetwork.merchantContract.payment_methods.${b.id}`) <
          t(`andMe.paymentNetwork.merchantContract.payment_methods.${a.id}`)
            ? 1
            : -1,
        )
        ?.map((paymentMethod) => {
          const neededConfigurations = Object.keys(merchantContract.partner.partner_data_configuration ?? {})
            .filter((configuration) => merchantContract.partner.partner_data_configuration[configuration].sources[merchantContract.source] === 'required')
            .map((configuration) => ({
              name: configuration,
              ...merchantContract.partner.partner_data_configuration[configuration],
            }))
            .filter((configuration) => configuration.paymentMethods?.includes(paymentMethod.id));
          const emptyConfigurations = neededConfigurations.filter(
            (configuration) => [null, undefined, ""].includes(merchantContract.configuration?.[configuration.name]),
          );
          return (
            <Tooltip
              key={paymentMethod.id}
              title={
                emptyConfigurations.length
                  ? `You must fill the following fields in connection settings to activate this payment method : ${neededConfigurations
                      .reduce((acc, configuration) => `${acc}${configuration.name}, `, '')
                      .slice(0, -2)}`
                  : null
              }
            >
              <div>
                <Dropdown
                  key={paymentMethod.id}
                  menu={{
                    items: [
                      { key: 'add', label: 'Add', onClick: () =>
                          addPaymentMethod({
                            variables: {
                              id: merchantContract.id,
                              input: {
                                paymentMethod: paymentMethod.id,
                              },
                            },
                          }),
                      }
                    ]
                  }}
                  trigger={isEdited ? ['contextMenu'] : []}
                >
                  <div
                    id={paymentMethod.id}
                    key={paymentMethod.id}
                    draggable={isEdited}
                    onDragStart={
                      isEdited
                        ? (event) => {
                            event.dataTransfer.setData('paymentMethod', paymentMethod.id);
                          }
                        : null
                    }
                    className={classnames({
                      [styles.draggable]: isEdited,
                      [styles.configNeeded]: emptyConfigurations.length,
                    })}
                  >
                    <Item
                      id={paymentMethod.id}
                      label={t(`andMe.paymentNetwork.merchantContract.payment_methods.${paymentMethod.id}`)}
                      title={t(`andMe.paymentNetwork.merchantContract.payment_methods.${paymentMethod.id}`)}
                      isDraggable
                      color="rgba(0, 0, 0, 0)"
                      icon={() => <img src={paymentMethod.imgUrl} alt="" width={51} draggable={false} />}
                      noPadding
                    />
                  </div>
                </Dropdown>
              </div>
            </Tooltip>
          );
        })}
      <HandleRoutesModal
        issues={pmIssues}
        onCancel={() => setPmIssues(null)}
        onConfirm={() => {
          removePaymentMethod({
            variables: {
              id: merchantContract.id,
              input: {
                paymentMethod: paymentMethodId,
              },
              force: true,
            },
          });
          setPmIssues(null)
        }}
      />
    </Block>
  );
};

export default PaymentMethods;
