const getPartnerRouteValue = {
  paymentMethodName: (partnerRoute) => partnerRoute.payment_method.api_name,
  paymentMethodType: (partnerRoute) => partnerRoute.payment_method.type,
  authorizationCurrency: (partnerRoute) => partnerRoute.authorization_currency,
  paymentChannel: (partnerRoute) => partnerRoute.channel,
  authenticationLevel: (partnerRoute, partner) => partnerRoute.is_3ds_available ?? partner?.is_3ds_available,
}

const getFilters = (splitter) => {
  let currentSplitter = splitter;
  let filters = {};
  do {
    // If it's a branch, add an include of the branch condition
    if (currentSplitter.branch) {
      filters = {
        ...filters,
        [currentSplitter.type]: {
          ...filters?.[currentSplitter.type],
          include: [...new Set([...(filters?.[currentSplitter.type]?.include ?? []), ...currentSplitter.branch.condition])],
        },
      }
    }
    // If it's other from a splitter, add an exclude of all the splitter's branches conditions
    else {
      filters = {
        ...filters,
        [currentSplitter.type]: {
          ...filters?.[currentSplitter.type],
          exclude: [...new Set([...currentSplitter.branches.reduce((acc, branch) => [...acc, ...branch.condition], []), ...(filters?.[currentSplitter.type]?.exclude ?? [])])],
        },
      }
    }
    currentSplitter = currentSplitter.parent;
  } while (currentSplitter);
  return filters;
}

export const isContractAvailable = (contract, splitter) => {
  const contractRoutes = contract
    .merchant_routes
    .map(merchantRoute => merchantRoute.partner_route);
  // If contract has no routes, it can't be used
  if (!contractRoutes.length) {
    return false;
  }
  const filters = getFilters(splitter);
  // We build the array of combinations the contract must accept
  let flatInclude = [];
  // We build the array of combinations the contract refuses
  let flatExclude = [];
  let contradiction = null;
  Object.keys(filters).forEach((filterType) => {
    // If the filterType is not handled, it is not a criteria to accept or not a contract
    if (!getPartnerRouteValue[filterType]) {
      return;
    }
    const {include, exclude} = filters[filterType];
    const includeWithoutExclude = include?.filter(includeElem => !exclude?.includes(includeElem));
    if (include) {
      // Rule contradictoire
      if (!includeWithoutExclude.length) {
        contradiction = filterType;
      } else if (flatInclude.length) {
        flatInclude = flatInclude.flatMap(flatIncludeElem => include.flatMap(includeElem => {
          if (flatInclude[filterType] && flatInclude[filterType] !== includeElem) {
            return [
              flatIncludeElem,
              {
                ...flatIncludeElem,
                [filterType]: includeElem,
              }
            ]
          }
          return ({
            ...flatIncludeElem,
            [filterType]: includeElem,
          })
        }));
      } else {
        flatInclude = include.flatMap(includeElem => ({
          [filterType]: includeElem,
        }));
      }
    }

    if (exclude) {
      if (flatExclude.length) {
        flatExclude = flatExclude.flatMap(flatExcludeElem => exclude.map(excludeElem => ({
          ...flatExcludeElem,
          [filterType]: excludeElem,
        })));
      } else {
        flatExclude = exclude.map(excludeElem => ({
          [filterType]: excludeElem,
        }));
      }
    }
  });
  if (contradiction) {
    return false;
  }
  if (flatInclude.length) {
    // Every include condition must be respected for the contract to be available
    return flatInclude.every((includeCondition) => {
      // There must be at least one partnerRoute that matches the given condition
      const hasRoute = contractRoutes.some(partnerRoute =>
        // Every rule of the condition must be respected by the partnerRoute
        (Object.keys(includeCondition ?? {}).every((filterType) => {
          // We recover the value of the partnerRoute for the given filter type
          const partnerRouteValue = getPartnerRouteValue[filterType]?.(partnerRoute, contract?.partner);
          // Exception for authenticationLevel : no3ds is also allowed when is_3ds_available is true
          if (filterType === 'authenticationLevel' && (partnerRouteValue || (includeCondition[filterType] === 'no3ds'))) {
            return true;
          }
          // If the value on the partnerRoute is the same as the value of the filter of the condition, this filter is ok
          if (partnerRouteValue === includeCondition[filterType]) {
            return true;
          }
          return false;
        })
        // The partnerRoute must match the include conditions AND not match any of the exclude conditions
        && flatExclude.every((excludeCondition) =>
          // Every exclude condition must
          Object.keys(excludeCondition ?? {}).every((filterType) => {
            // If a condition is both excluded and included, we only check the other excludes
            if (flatInclude.map(includeConditionElem => includeConditionElem[filterType]).includes(excludeCondition[filterType])) {
              return true;
            }
            const partnerRouteValue = getPartnerRouteValue[filterType]?.(partnerRoute, contract?.partner);
            // Exception for authenticationLevel : if no3ds is excluded, allow only 3ds
            if (filterType === 'authenticationLevel' && !(partnerRouteValue && (excludeCondition[filterType] === 'no3ds'))) {
              return true;
            }
            if (partnerRouteValue !== excludeCondition[filterType]) {
              return true;
            }
            return false;
          })
        )
      ))
      // if (!hasRoute) {
      //   console.log(contract.name, includeCondition, 'Condition does not match');
      // }
      return hasRoute;
    })
  }
  // If no include, the contract must just have some routes that are not excluded.
  const otherThanExcluded = contractRoutes.some(partnerRoute =>
    flatExclude.every((excludeCondition) =>
      Object.keys(excludeCondition ?? {}).every((filterType) => {
        const partnerRouteValue = getPartnerRouteValue[filterType]?.(partnerRoute);
        // Exception for authenticationLevel : if no3ds is excluded, allow only 3ds
        if (filterType === 'authenticationLevel' && !(partnerRouteValue && (excludeCondition[filterType] === 'no3ds'))) {
          return true;
        }
        if (partnerRouteValue !== excludeCondition[filterType]) {
          return true;
        }
        return false;
      })
    )
  )
  // if (!otherThanExcluded) {
  //   console.log(contract.name, flatExclude, 'No other routes than excluded')
  // }
  return otherThanExcluded;
}

export const filterContracts = (splitter, contracts) => contracts.reduce((acc, contract) => {
  const selectedContract = splitter.branch ? splitter.branch.contract : splitter.contract;
  const isSelectedContract = selectedContract?.id === contract.id;
  const isAvailable = isContractAvailable(contract, splitter);
  return [
    ...acc,
    ...(isSelectedContract || isAvailable ? [{
      ...contract,
      inactive: contract.status !== 'active',
      unavailable: !isAvailable,
    }] : [])
  ];
}, []);