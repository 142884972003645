import React from 'react';
import { Radio } from 'antd';

const BooleanInput = ({ value, onChange, disabledOptions }) => (
  <Radio.Group
    value={value?.[0]}
    onChange={(e) => onChange([e.target.value])}
    options={[
      {
        label: 'True',
        value: 'true',
        disabled: disabledOptions.includes('true'),
      },
      {
        label: 'False',
        value: 'false',
        disabled: disabledOptions.includes('false'),
      },
    ]}
    optionType="button"
    buttonStyle="solid"
  />
);

export default BooleanInput;
