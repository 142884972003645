import React, { useState } from "react";
import { Skeleton } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { DATETIME_FORMAT } from "../../../../../../constants/DATES";
import TooltipWithCopyBtn from "../../../../Common/TableCell/TooltipWihCopyBtn/TooltipWithCopyBtn";
import TextInput from "../../../../Common/Form/TextInput/TextInput";
import styles from "./ListHeader.module.scss";
import { updateListNameMutation } from "./query";

const ListHeader = ({list, loading}) => {
  const { t } = useTranslation();

  const [updateName] = useMutation(
    updateListNameMutation,
    {
      variables: {
        id: list.id,
      },
      context: {
        debounceKey: `updateListName-${list.id}`,
        debounceTimeout: 1000,
      },
    }
  );

  const [isEditName, setIsEditName] = useState(false);

  return (
    <div className={styles.root}>
      <div>
        <div
          className={styles.nameContainer}
        >
          {
            isEditName ? (
              <TextInput
                value={list.name}
                autoFocus
                onChange={(event) => updateName({
                  variables: {
                    name: event.target.value
                  },
                  optimisticResponse: {
                    updateListName: {
                      id: list.id,
                      name: event.target.value,
                      __typename: 'List',
                    }
                  }
                })}
              />
            ) : (
              <h2>
                {loading
                  ? (
                    <Skeleton.Button
                      active
                      size="small"
                      shape="square"
                      block={false}
                      style={{
                        width: 120,
                        height: 33,
                      }}
                    />
                  )
                  : list.name
                }
              </h2>
            )
          }
          <EditOutlined
            className={styles.icon}
            onClick={() => setIsEditName(!isEditName)}
          />
        </div>
        <small className={styles.subtitle}>
          id: <TooltipWithCopyBtn
          value={loading
            ? (
              <Skeleton.Button
                active
                size="small"
                shape="square"
                block={false}
                style={{
                  width: 180,
                  height: 14,
                }}
              />
            )
            : list.id
          }
          trunc={false}
        />
        </small>
      </div>
      <div
        className={styles.titleFooter}
      >
        <small className={styles.subtitle}>
          List type: {loading
          ? (
            <Skeleton.Button
              active
              size="small"
              shape="square"
              block={false}
              style={{
                width: 100,
                height: 14,
              }}
            />
          )
          : t(`andMe.lists.types.${list.type}`)
        }
        </small>
        <small className={styles.subtitle}>
          Nb of entries: {loading
          ? (
            <Skeleton.Button
              active
              size="small"
              shape="square"
              block={false}
              style={{
                width: 50,
                height: 14,
              }}
            />
          )
          : list.length
        }
        </small>
        <small className={styles.subtitle}>
          Last update: {loading
          ? (
            <Skeleton.Button
              active
              size="small"
              shape="square"
              block={false}
              style={{
                width: 100,
                height: 14,
              }}
            />
          )
          : moment(list.updatedAt).format(DATETIME_FORMAT)
        }
        </small>
      </div>
    </div>
  );
};

export default ListHeader;