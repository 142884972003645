import React, {useContext, useEffect, useState} from 'react';
import { message } from 'antd';
import {
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
} from '@apollo/client';
import { useCan } from '../../../../../../contexts/ability.context';
import { compareByProp } from '../../../../../../util/array';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import SelectElem from '../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import { ColumnsContext, MutatingContext } from '../../../../Common/NavigationContainer/NavigationContainer';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { accountPartnersQuery } from './query';
import LoadingElem from '../../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';
import Partner from './Partner/Partner';
import { createContractMutation, partnersQuery } from '../query';
import { useProgramManagerId } from '../../../../../../contexts/app.context';
import SOURCES from '../../../../../../constants/SOURCES';

const PartnerSelect = (props) => {
  const { activeColumns, type } = props;

  const can = useCan();
  const [programManagerId] = useProgramManagerId();

  const { setActiveColumns } = useContext(ColumnsContext);
  const { selectedMerchantAccount } = useMerchantAccount();

  const [selectedPartner, setSelectedPartner] = useState(' ');
  const [isAddPartnerActive, setIsAddPartnerActive] = useState(false);

  const [getAccountPartners, { data: { merchantAccount } = { merchantAccount: null }, loading: loadingContracts }] =
    useApolloLazyQuery(accountPartnersQuery, {
      variables: {
        id: selectedMerchantAccount,
        type,
      },
    });

  useEffect(() => {
    if (selectedMerchantAccount) {
      getAccountPartners();
    }
  }, [selectedMerchantAccount]);

  const { data: { partners } = { partners: [] }, loading: loadingPartners } = useApolloQuery(partnersQuery, {
    variables: {
      programManagerId,
    },
  });

  const [createContract, { loading }] = useApolloMutation(createContractMutation);

  const { setIsMutating } = useContext(MutatingContext);

  useEffect(() => {
    setIsMutating(loading);
    return () => {
      setIsMutating(false);
    };
  }, [loading]);

  const partnersToAdd =
    partners.filter(
      (partner) => {
        if (
          merchantAccount?.source === SOURCES.STACK && partner.allow_stack ||
          merchantAccount?.source === SOURCES.INSIGHTS && partner.allow_insights ||
          merchantAccount?.source === SOURCES.INSIGHTS_POS && partner.allow_insights_pos
        ) {
          return !merchantAccount?.partners?.find((maPartner) => maPartner.id === partner.id) &&
            partner.type === type &&
            partner.status === 'active';
        }
        return false;
      }) ?? [];

  const createContractWithPartner = (partnerId) => createContract({
    variables: {
      id: selectedMerchantAccount,
      partner: partnerId,
      partnerType: type,
    },
  }).then((response) => {
    setIsAddPartnerActive(false);
    setSelectedPartner(' ')
    setActiveColumns([
      ...activeColumns.filter(column => column !== 'addPartner'),
      partnerId,
      response.data.addMerchantContract.merchant_contracts[0].id,
    ]);
    message.success('Psp successfully added');
  })

  const onSelectPartner = (partnerId) => {
    createContractWithPartner(partnerId)
    setSelectedPartner(partnerId)
    setIsAddPartnerActive(true)
    setActiveColumns([
      ...activeColumns,
      'addPartner',
    ]);
  }

  return (
    <Column id={type} {...props}>
      {can('create', 'merchant_contract') && partnersToAdd.length && (
        <SelectElem
          key="addPartner"
          title="Add a partner"
          id='addPartner'
          options={partnersToAdd.sort(compareByProp('name', 'asc', 'string'))}
          value={selectedPartner}
          handleChange={onSelectPartner}
          allowClear={false}
          active={isAddPartnerActive}
        />
      )}
      {!merchantAccount?.partners && (loadingPartners || loadingContracts || !selectedMerchantAccount) && (
        <LoadingElem />
      )}
      {[...(merchantAccount?.partners ?? [])].sort(compareByProp('name', 'asc', 'string')).map((partner) => (
        <Partner
          key={partner.id}
          id={partner.id}
          partner={partner}
          closeAddPartner={() => {setIsAddPartnerActive(false); setSelectedPartner(' ')}}
          type={type}
        />
      ))}
    </Column>
  );
};

export default PartnerSelect;
