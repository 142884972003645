import {useQuery} from '@apollo/client';
import {companyListsQuery} from '../../../../../Common/query';
import {useCompany} from '../../../../../../../../../contexts/app.context';

const ListBranchTitle = ({ branch }) => {
  const [company] = useCompany();

  const {data: {merchantCompany: {lists}} = {merchantCompany: {lists: []}}} = useQuery(
    companyListsQuery,
    {
      variables: {
        id: company
      }
    }
  );

  return lists.find(list => list.id === branch.condition[0])?.name ?? branch.condition[0]
};

export default ListBranchTitle;