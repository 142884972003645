import React from 'react';
import {Spin} from 'antd';
import {ExclamationCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import styles from '../../Content.module.scss';
import splitterTypes from '../../../../constants/splitterTypes';

const MerchantDataSplitterContent = (props) => {
  const { splitter, isActive, isParentActive } = props;

  return (
    <div
      key={splitter.id}
      role='presentation'
      className={classnames(styles.root, styles.splitter, {[styles.active]: isActive}, {[styles.parentActive]: isParentActive})}
    >
      <div
        className={styles.icon}
      >
        {splitterTypes.find(type => type.id === splitter.type).icon}
      </div>
      <div
        className={styles.text}
      >
        <div
          className={styles.textContent}
        >
          <div
            className={styles.title}
          >
            {splitter.merchant_data_key ?? (
              <>
                <ExclamationCircleOutlined style={{color: '#ff0000'}} /> Enter key name
              </>
            )}
          </div>
          <div
            className={styles.type}
          >
            {splitter.merchant_data_type}
          </div>
        </div>
        {splitter.id === "splitterTemp" && (
          <Spin indicator={<LoadingOutlined />} />
        )}
      </div>
    </div>
  )
};

export default MerchantDataSplitterContent;