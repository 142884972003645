import React from "react";
import classnames from 'classnames';
import styles from './HorizontalLine.module.scss';
import {useRuleEditor} from '../../ruleEditor.context';

const HorizontalLine = ({ isParentActive }) => {
  const { isEditMode } = useRuleEditor();

  return (
    <div
      className={styles.hLineContainer}
    >
      <div
        className={classnames(styles.hLine, {[styles.edit]: isEditMode}, {[styles.active]: isParentActive})}
      />
    </div>
  );
}

export default HorizontalLine;