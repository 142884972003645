import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  message,
  Space,
  Radio
} from "antd";
import {
  addListContentMutation,
  listQuery,
  removeListContentMutation,
  replaceListContentMutation,
} from "./query";
import { Block, SiderContainer } from "../../../Common/Sider";
import styles from './ListDetailsSider.module.scss';
import ListInput from "./ListInput/ListInput";
import { useCan } from "../../../../../contexts/ability.context";
import ListHeader from "./ListHeader/ListHeader";
import ListActions from "./ListActions/ListActions";
import useNavigateWithSearch from "../../../../../util/navigate";


const ListDetailsSider = ({id}) => {
  const navigate = useNavigateWithSearch();
  const can = useCan();

  const {data: { list } = { list : {} }, loading} = useQuery(
    listQuery,
    {
      variables: {
        id,
      },
      onError: (error) => {
        if (error?.graphQLErrors?.[0]?.extensions.code === '404') {
          message.error('List not found');
          navigate(`/router/lists`)
        }
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [addListContent, {loading: loadingAdd}] = useMutation(
    addListContentMutation,
    {
      variables: {
        id: list.id,
      }
    }
  );

  const [removeListContent, {loading: loadingRemove}] = useMutation(
    removeListContentMutation,
    {
      variables: {
        id: list.id,
      }
    }
  );

  const [replaceListContent, {loading: loadingReplace}] = useMutation(
    replaceListContentMutation,
    {
      variables: {
        id: list.id,
      }
    }
  );

  const [action, setAction] = useState('add');

  const validation = {
    bin: {
      method: (elem) => elem.charAt(0) !== '0' && /^\d{6}$/.test(elem),
      message: 'BIN must be 6 digits long and cannot start with a 0'
    },
  }

  return (
    <SiderContainer
      title={<ListHeader list={list} loading={loading} />}
    >
      {['product_sku', 'bin'].includes(list.type) || can('read', 'gdpr') ? (
        <>
          <Block
            title='Action type'
            description='Do you want to add or delete these values?'
          >
            <div
              className={styles.blockContent}
            >
              <Radio.Group
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <Space
                  direction='vertical'
                >
                  <Radio
                    value='add'
                  >
                    Add new data to this list
                  </Radio>
                  <Radio
                    value='remove'
                  >
                    Remove specific data of this list
                  </Radio>
                  <Radio
                    value='replace'
                  >
                    Replace all data to this list
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Block>
          <Block
            title='Input type'
            description='Upload a csv file or add separate values using a comma ","'
          >
            <div
              className={styles.blockContent}
            >
              <ListInput
                action={action}
                list={list}
                onValid={(() => {
                  switch (action) {
                    case 'add':
                      return addListContent;
                    case 'remove':
                      return removeListContent;
                    case 'replace':
                      return replaceListContent;
                  }
                })()}
                loading={loadingAdd || loadingRemove || loadingReplace}
                validation={validation}
              />
            </div>
          </Block>
          <Block
            title='Delete / download list'
            description="Click on one of the options below"
          >
            <div
              className={styles.blockContent}
            >
              <ListActions
                list={list}
              />
            </div>
          </Block>
        </>
        ) : (
        <>
          <div
            className={styles.gdprMessage}
          >
            You cannot access values of this list based on your access rights
          </div>
          <Block
            title='Delete list'
            description="Click the button below to delete this list"
          >
            <div
              className={styles.blockContent}
            >
              <ListActions
                list={list}
              />
            </div>
          </Block>
        </>
      )}
    </SiderContainer>
  );
};

export default ListDetailsSider;