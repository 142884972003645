import { Modal } from 'antd';
import React from 'react';
import ModalContent from './ModalContent/ModalContent';

const HandleRoutesModal = ({ onCancel, onConfirm, issues }) => (
  <Modal
    onCancel={onCancel}
    open={issues}
    destroyOnClose
    focusTriggerAfterClose
    footer={null}
    closable={false}
    width={700}
  >
    <ModalContent
      onCancel={onCancel}
      onConfirm={onConfirm}
      issues={issues}
    />
  </Modal>
);

export default HandleRoutesModal;
