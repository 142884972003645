import React, {useEffect, useState} from 'react';
import { useQuery as useApolloQuery } from '@apollo/client';
import {Form, Space} from "antd";
import {useTranslation} from 'react-i18next';
import { countriesQuery } from './query';
import { CountryFlag } from "../../../../../../../../constants/COUNTRIES";
import TreeSelectBranchInput
  from '../Common/TreeSelectBranchInput/TreeSelectBranchInput';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const CountryBranchInput = (props) => {
  const { splitter, removeBranch, branch, handleChange } = props;

  const disabledOptions = branch ? [] : splitter?.branches?.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []) ?? [];

  const { data: { regions } = { regions: [] }, loading } = useApolloQuery(countriesQuery);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [hasChanged, setHasChanged] = useState(false);

  const options = regions?.map((region) => ({
    value: `region_${region.id}`,
    title: region.label,
    search: region.label,
    disabled: region.subregions.every((s) => s.countries.every((c) => disabledOptions.includes(c.id))),
    children: region.subregions.map((subregion) => ({
      value: `subregion_${subregion.id}`,
      title: subregion.label,
      search: subregion.label,
      disabled: subregion.countries.every(country => disabledOptions.includes(country.id)),
      children: subregion.countries.map((country) => ({
        value: country.id,
        title: <Space><CountryFlag value={country.id} size={24} />{country.label}</Space>,
        search: `${country.id} ${country.label}`,
        disabled: disabledOptions.includes(country.id),
      })),
    })),
  }));

  useEffect(() => {
    form.setFieldValue('countries', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.${splitter.type}.label`)} condition`}
      description='Select countries in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.countries);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true)
        }}
      >
        <Form.Item name='countries'>
          <TreeSelectBranchInput
            options={options}
            isLoading={loading}
            treeCheckable
            placeholder='Select countries in the list'
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default CountryBranchInput;
