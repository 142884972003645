import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import Column from "../../../../../../Common/NavigationContainer/Column/Column";
import TextInputElem from "../../../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem";
import ButtonElem from "../../../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem";
import { addWebhookHeaderMutation } from "./query";
import { ColumnsContext } from "../../../../../../Common/NavigationContainer/NavigationContainer";

const NewHeader = ({ webhook, ...props }) => {
  const {setActiveColumns, activeColumns} = useContext(ColumnsContext);

  const [headerKey, setHeaderKey] = useState();
  const [headerValue, setHeaderValue] = useState();

  const [updateWebhook] = useMutation(
    addWebhookHeaderMutation,
    {
      variables: {
        id: webhook.id,
        input: {
          key: headerKey,
          value: headerValue,
        }
      },
      optimisticResponse: {
        addWebhookHeader: {
          id: webhook.id,
          __typename: 'Webhook',
          additional_headers: {
            [headerKey]: headerValue,
            ...webhook.additional_headers ?? {},
          }
        }
      },
    }
  )

  return (
    <Column
      {...props}
    >
      <TextInputElem
        title='Header key'
        value={headerKey}
        handleChange={(event) => setHeaderKey(event.target.value)}
        isError={Object.keys(webhook.additional_headers ?? {}).includes(headerKey)}
      />
      <TextInputElem
        title='Header value'
        value={headerValue}
        handleChange={(event) => setHeaderValue(event.target.value)}
      />
      <ButtonElem
        title='Create header'
        disabled={!(headerKey && headerValue) || Object.keys(webhook.additional_headers ?? {}).includes(headerKey)}
        handleClick={() => {
          setActiveColumns(activeColumns.slice(0, -1))
          updateWebhook();
        }}
      />
    </Column>
  );
};

export default NewHeader;