import { Tooltip, message, Dropdown } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import styles from './Elem.module.scss';
import IconsContainer from '../Common/IconsContainer/IconsContainer';
import Helper from '../Common/IconsContainer/Helper/Helper';
import DragNDropSVG from './dragNDrop.svg';

const Elem = ({
  active,
  activeOnCreate,
  avatar,
  callbackRef,
  canCopy,
  customIcon,
  dropdownActions,
  draggable,
  extraStyle,
  gridColumn,
  gridRow,
  handleClick,
  isError,
  isLoading,
  isValid,
  isWarning,
  locked,
  lockedTooltip,
  nbColumns,
  noHover,
  onDragLeave,
  onDragOver,
  onDragStart,
  onDrop,
  helper,
  helperShown,
  subColumnProps,
  subColumn,
  title,
  toggleElem,
  value,
  id,
}) => {
  const { t } = useTranslation();

  const titleRef = useRef();
  const valueRef = useRef();

  const [isHovered, setIsHovered] = useState(false);

  const [isTitleTooltipVisible, setIsTitleTooltipVisible] = useState(false);
  const [isValueTooltipVisible, setIsValueTooltipVisible] = useState(false);

  function usePrevious(newValue) {
    const ref = useRef();
    useEffect(() => {
      ref.current = newValue;
    });
    return ref.current;
  }

  const prevGridColumn = usePrevious(gridColumn);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  const renderSubColumn = () => {
    if (active && subColumn) {
      return React.cloneElement(subColumn, {
        ...subColumnProps,
        offsetY: gridRow,
      });
    }
    return null;
  };

  const getTooltipTitle = () => {
    if (canCopy) {
      const handleCopy = (e) => {
        e.preventDefault();
        e.stopPropagation();
        copy(value);
        return message.success(t('common.successfullyCopied'));
      };
      return (
        <div onClick={handleCopy} onKeyDown={handleCopy} role="button" tabIndex={0}>
          {value}
          <CopyOutlined
            style={{
              marginLeft: '5px',
            }}
          />
        </div>
      );
    }
    if (locked && lockedTooltip) {
      return lockedTooltip;
    }
    return null;
  };

  useEffect(() => {
    if ((active && locked) || (!active && activeOnCreate)) {
      toggleElem();
    }
  }, []);

  const isColumnActive = gridColumn === Math.ceil(nbColumns / 2);

  const onClick = () => {
    if (subColumn && !locked) {
      toggleElem();
    }
    if (handleClick && !locked) {
      handleClick();
    }
  };

  return (
    <>
      <Tooltip overlayClassName={styles.tooltip} title={getTooltipTitle()}>
        <Dropdown
          menu={{
            items: dropdownActions?.map((action) => ({
              key: action.label,
              label: action.label,
              onClick: action.call,
            })),
          }}
          trigger={dropdownActions?.length ? ['contextMenu'] : ''}
        >
          <div
            ref={callbackRef}
            className={classnames(styles.elem, 'elem', {
              [styles.active]: active,
              [styles.isLoading]: isLoading,
              [styles.noHover]: noHover,
              [styles.locked]: locked,
              [styles.oob]: gridColumn <= 0,
              [styles.isColumnActive]: isColumnActive,
              [styles.isAppearing]: prevGridColumn === undefined,
              [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
              [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
            })}
            style={{
              gridColumn,
              gridRow,
              ...extraStyle,
            }}
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => {
              if (dropdownActions?.length) {
                e.preventDefault();
              }
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            draggable={draggable}

            id={id}
          >
            {avatar}
            <div
              className={styles.contentColumn}
              onDrop={(event) => {
                event.preventDefault();
                // TODO : handle OnDrop here
              }}
            >
              <Tooltip
                title={title}
                open={isTitleTooltipVisible}
                // Show a tooltip of the title if it overflows
                onOpenChange={(visible) =>
                  setIsTitleTooltipVisible(visible && titleRef.current.offsetWidth < titleRef.current.scrollWidth)
                }
              >
                <div ref={titleRef} className={styles.titleContainer}>
                  {title}
                </div>
              </Tooltip>
              <Tooltip
                title={value}
                open={isValueTooltipVisible}
                // Show a tooltip of the value if it overflows
                onOpenChange={(visible) =>
                  setIsValueTooltipVisible(visible && valueRef.current.offsetWidth < valueRef.current.scrollWidth && !canCopy)
                }
              >
                <div ref={valueRef} className={classnames(styles.contentContainer)}>
                  {value ?? ''}
                </div>
              </Tooltip>
            </div>
            <IconsContainer
              isError={isError}
              isWarning={isWarning}
              isValid={isValid}
              locked={locked}
              contrast={active}
              custom={customIcon}
            />
            {draggable && <img className={styles.dragNDrop} src={DragNDropSVG} alt="dragAndDrop" />}
          </div>
        </Dropdown>
      </Tooltip>
      <Helper gridColumn={gridColumn} gridRow={gridRow} shown={helperShown ?? (helper && isColumnActive && isHovered)}>
        {helper}
      </Helper>
      {renderSubColumn()}
    </>
  );
};

export default Elem;
