import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client/react/hooks/useQuery';
import {useTranslation} from 'react-i18next';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import { paymentMethodsQuery } from '../../../../Common/query';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const PaymentMethodBranchInput = (props) => {
  const { splitter, handleChange, removeBranch, branch } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const { data: { paymentMethods } = { paymentMethods: [] }, loading } = useApolloQuery(paymentMethodsQuery);

  useEffect(() => {
    form.setFieldValue('paymentMethod', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const disabledOptions = branch ? [] : splitter?.branches?.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []) ?? [];

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentMethodName.label`)} condition`}
      description='Select payment methods in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.paymentMethod);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true);
        }}
      >
        <Form.Item name='paymentMethod'>
          <SelectBranchInput
            options={paymentMethods
              ?.map((paymentMethod) => ({
                value: paymentMethod.api_name,
                label: paymentMethod.name,
                imgUrl: paymentMethod.imgUrl,
                disabled: disabledOptions.includes(paymentMethod.api_name)
              }))
              .filter(
                (thing, index, self) => index === self.findIndex((elem) => elem.value === thing.value && elem.label === thing.label),
              )
              .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
              .filter((paymentMethod) => paymentMethod.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Select payment methods in the list"
            isLoading={loading}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default PaymentMethodBranchInput;
