import {useQuery as useApolloQuery} from '@apollo/client/react/hooks/useQuery';
import {paymentMethodsQuery} from '../../../../../Common/query';

const PaymentMethodBranchTitle = (props) => {
  const { branch } = props;

  const { data: { paymentMethods } = { paymentMethods: [] } } = useApolloQuery(paymentMethodsQuery);

  return branch.condition.reduce((acc, pm) => `${acc}${paymentMethods?.find(pmElem => pmElem.api_name === pm)?.name ?? pm}, `, '').slice(0, -2) ?? '';
};

export default PaymentMethodBranchTitle;