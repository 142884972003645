import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { Col, List, Row, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useMe } from '../../../../contexts/me.context';
import SiderPage from '../../Common/SiderPage/SiderPage';
import SearchInput from '../SearchInput';
import { TeamAccountListQuery } from './query';
import styles from '../Teams.module.scss';
import AccountDetail from './AccountDetail/AccountDetail';
import { search } from '../../../../util/string';
import CMSS from '../../../../constants/CMSS';
import NavButtons from '../NavButtons';
import useNavigateWithSearch from "../../../../util/navigate";

const AccountList = () => {
  const navigate = useNavigateWithSearch();
  const me = useMe();

  const { accountId } = useParams();

  const { data, loading } = useQuery(TeamAccountListQuery, {
    variables: { companyId: me.companies[0].company.id },
  });

  const [searchValue, setSearchValue] = useState();
  const handleSearch = (e) => setSearchValue(e.target.value);

  const filteredList = useMemo(() => {
    if (data) {
      let list = [...data.merchantCompany.merchantAccounts];
      if (searchValue) {
        list = list.filter((merchantAccount) => search(searchValue, merchantAccount.name));
      }
      list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      return list;
    }
    return [];
  }, [data, searchValue]);

  useEffect(() => {
    // activate first item
    if (!accountId && filteredList.length > 0) {
      navigate(`/teams/accounts/${filteredList[0]?.id}`);
    }
  }, [accountId, filteredList]);

  return (
    <SiderPage
      id="page-team-accounts"
      defaultOpen
      siderContent={<AccountDetail merchantAccountId={accountId} />}
      hideToggle
    >
      <NavButtons />
      <Row className={styles.rowList} justify="space-around">
        <Col md={16} lg={12} xl={8}>
          <div className={styles.infoText}>
            <div>You have access to {data?.merchantCompany.merchantAccounts.length} accounts.</div>
            <div>Manage the users linked to your accounts.</div>
          </div>
          <SearchInput placeholder="Account name" onChange={handleSearch} />
          <List
            id="team-accounts"
            loading={loading}
            dataSource={filteredList}
            renderItem={(ma) => (
              <List.Item
                key={ma.id}
                onClick={() => navigate(`/teams/accounts/${ma.id}`)}
                className={classNames(styles.listItem, { [styles.active]: ma.id === accountId })}
                extra={[
                  <Tag key="userCount" icon={<UserOutlined />}>
                    {ma.userCount}
                  </Tag>,
                ]}
              >
                <List.Item.Meta title={ma.name} description={CMSS[ma.cms_name]?.label || 'n/a'} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </SiderPage>
  );
};

export default AccountList;
