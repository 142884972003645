import React from 'react';
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import {Button, Form, Input} from 'antd';
import {updateRuleMutation} from '../../../query';
import styles from '../SiderSplitter/SiderSplitter.module.scss';
import {Block} from '../../../../../Common/Sider';
import siderStyles from '../../Sider.module.scss';

const SiderRule = ({rule}) => {
  const [form] = Form.useForm();

  const [updateRule, { loading }] = useApolloMutation(updateRuleMutation, {
    variables: {
      id: rule?.id,
    }
  });

  return (
    <Block
      title='Rule informations'
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          updateRule({
            variables: {
              input: {
                name: values.ruleName,
              }
            },
            context: {
              debounceKey: `updateRuleName-${rule?.id}`,
              debounceTimeout: 1000,
            },
          })
        }}
      >
        <Form.Item
          name='ruleName'
          label='Rule name'
        >
          <Input
            defaultValue={rule?.name}
            autoFocus
            allowClear
          />
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.ruleName !== curValues.ruleName}>
          {() => (
            <div className={siderStyles.bottomButtons}>
              <Button type="text" onClick={() => form.setFieldValue('ruleName', rule?.name)}>
                CANCEL
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!form.getFieldValue('ruleName') || form.getFieldValue('ruleName') === rule?.name}
              >
                SAVE
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </Block>
  )
};

export default SiderRule;