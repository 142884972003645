import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from './Teams.module.scss';

/**
 * Search input with custom styles
 * @param onChange
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const SearchInput = ({ onChange, placeholder }) => (
  <Input
    id="search-input"
    className={styles.searchInput}
    onChange={onChange}
    placeholder={placeholder || 'search'}
    addonBefore={<SearchOutlined />}
    allowClear
  />
);

export default SearchInput;
