import React from 'react';
import { useMutation } from '@apollo/client';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import TextInputElem from '../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import SelectElem from '../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import { useCan } from '../../../../../../contexts/ability.context';
import Elem from '../../../../Common/NavigationContainer/Column/Elem/Elem';
import { TRANSACTION_STATUS_LIST } from '../../../../../../constants/TRANSACTION_STATUSES';
import Headers from './Headers/Headers';
import { updateNotificationMutation } from './query';

const Notification = ({ webhook, ...props }) => {
  const can = useCan();

  const [updateNotification] = useMutation(updateNotificationMutation);

  const updateNotificationField = (input) =>
    updateNotification({
      variables: {
        id: webhook.id,
        input,
      },
      optimisticResponse: {
        updateWebhook: {
          ...webhook,
          ...input,
        },
      },
    });

  return (
    <Column id={`webhook${webhook.id}`} {...props}>
      <TextInputElem
        id={`${webhook.id}-name`}
        key={`${webhook.id}-name`}
        title="Name"
        autoFocus
        defaultValue={webhook.name}
        handleValid={(value) => {
          if (value !== webhook.name) {
            updateNotificationField({
              name: value,
            });
          }
        }}
        locked={!can('update', 'webhook')}
      />
      <TextInputElem
        id={`${webhook.id}-url`}
        key={`${webhook.id}-url`}
        title="Url"
        defaultValue={webhook.url}
        isValid={webhook.url}
        isWarning={!webhook.url}
        handleValid={(value) => {
          if (value !== webhook.url) {
            updateNotificationField({
              url: value,
            });
          }
        }}
        locked={!can('update', 'webhook')}
      />
      <SelectElem
        id={`${webhook.id}-triggers`}
        key={`${webhook.id}-triggers`}
        title="Triggers"
        options={TRANSACTION_STATUS_LIST.map((status) => ({ id: status.value, name: status.label }))}
        defaultValue={webhook.trigger}
        isValid={!!webhook.trigger.length}
        isWarning={!webhook.trigger.length}
        handleValid={(value) => {
          if (value !== webhook.trigger) {
            updateNotificationField({
              trigger: value,
            });
          }
        }}
        mode="multiple"
        locked={!can('update', 'webhook')}
      />
      {can('update', 'webhook') && <Elem title="headers" id="headers" key="headers" subColumn={<Headers webhook={webhook} />} />}
      <SelectElem
        id={`${webhook.id}-only-last`}
        key={`${webhook.id}-only-mast`}
        title="Notify last transaction only?"
        options={[
          {
            id: 'yes',
            name: 'Yes',
          },
          {
            id: 'no',
            name: 'No',
          },
        ]}
        defaultValue={webhook.notify_last_transaction_only ? 'yes' : 'no'}
        handleChange={(value) => {
          if (value !== webhook.notify_last_transaction_only) {
            updateNotificationField({
              notify_last_transaction_only: value === 'yes',
            });
          }
        }}
        locked={!can('update', 'webhook')}
      />
      <SelectElem
        id={`${webhook.id}-status`}
        key={`${webhook.id}-status`}
        title="Status"
        options={[
          {
            id: 'active',
            name: 'Active',
          },
          {
            id: 'disabled',
            name: 'Inactive',
          },
        ]}
        defaultValue={webhook.status}
        handleChange={(value) => {
          if (value !== webhook.status) {
            updateNotificationField({
              status: value,
            });
          }
        }}
        locked={!can('update', 'webhook')}
      />
    </Column>
  );
};

export default Notification;
