import React, {useEffect, useState} from "react";
import {Form} from 'antd';
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { companyListsQuery } from "../../../../Common/query";
import { useCompany } from "../../../../../../../../contexts/app.context";
import TreeSelectBranchInput from "../Common/TreeSelectBranchInput/TreeSelectBranchInput";
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const ListBranchInput = (props) => {
  const { splitter, branch, handleChange, removeBranch } = props;
  const [form] = Form.useForm();
  const [company] = useCompany();
  const { t } = useTranslation();

  const {data: {merchantCompany: {lists}} = {merchantCompany: {lists: []}}, loading} = useQuery(
    companyListsQuery,
    {
      variables: {
        id: company
      }
    }
  );

  const getListsTree = () => {
    const listsTree = {};
    lists.forEach(list => {
      listsTree[list.type] = [
        ...listsTree[list.type] ?? [],
        {
          title: list.name,
          value: list.id,
          disabled: splitter.branches.map(branchElem => branchElem.condition[0]).includes(list.id)
        }
      ]
    });
    return Object.keys(listsTree).map(listType => ({
      title: t(`andMe.lists.types.${listType}`),
      value: listType,
      key: listType,
      isLeaf: false,
      selectable: false,
      children: listsTree[listType],
    }))
  }

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    form.setFieldValue('list', branch?.condition[0] ?? null);
    setHasChanged(false);
  }, [branch])

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.list.label`)} condition`}
      description='Select a list in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.list);
          setHasChanged(false);
        }}
        onValuesChange={() => setHasChanged(true)}
      >
        <Form.Item name='list'>
          <TreeSelectBranchInput
            options={getListsTree()}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder='Select a list'
            isLoading={loading}
            treeCheckable={false}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default ListBranchInput;