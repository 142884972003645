import React, { useContext, useEffect, useState } from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { message } from 'antd';
import TextInputElem from '../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import { ColumnsContext, MutatingContext } from '../../../../Common/NavigationContainer/NavigationContainer';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { createNotificationMutation } from './query';
import ButtonElem from '../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';

const NewNotificationForm = (props) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const [name, setName] = useState('');

  const [createNotification, { loading }] = useApolloMutation(createNotificationMutation, {
    refetchQueries: ['MerchantAccountWebhooks'],
  });

  const { setIsMutating } = useContext(MutatingContext);

  const { activeColumns, setActiveColumns } = useContext(ColumnsContext);

  useEffect(() => {
    setIsMutating(loading);
    return () => {
      setIsMutating(false);
    };
  }, [loading]);

  return (
    <Column id="notifForm" {...props}>
      <TextInputElem
        id="notifName"
        title="Name"
        autoFocus
        placeholder="Your webhook name"
        value={name}
        handleChange={(e) => setName(e.target.value)}
      />
      {name && (
        <ButtonElem
          key="notificationCreateButton"
          handleClick={() =>
            createNotification({
              variables: {
                id: selectedMerchantAccount,
                input: {
                  name,
                },
              },
            }).then(({ data }) => {
              message.success('Successfully created');
              const createdWebhookId =
                data?.addWebhookMerchantAccount?.webhooks?.[data?.addWebhookMerchantAccount?.webhooks.length - 1]?.id;
              setActiveColumns(
                activeColumns.map((activeColumn) =>
                  activeColumn === 'addNotification' ? createdWebhookId : activeColumn,
                ),
              );
            })
          }
          title="Create"
        />
      )}
    </Column>
  );
};

export default NewNotificationForm;
