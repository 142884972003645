import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Input, message, Skeleton, Tooltip } from 'antd';
import copy from 'copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IconsContainer from '../Common/IconsContainer/IconsContainer';
import styles from './TextInputElem.module.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const TextInputElem = ({
  autoFocus,
  isError,
  isValid,
  isWarning,
  handleChange,
  title,
  gridColumn,
  gridRow,
  placeholder,
  value,
  closeNextColumns,
  locked,
  handleValid,
  defaultValue,
  canCopy,
  isLoading,
  nbColumns,
  callbackRef,
  regexp,
}) => {
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState(defaultValue ?? value ?? '');

  const [isRegexpError, setIsRegexpError] = useState(false);

  const prevGridColumn = usePrevious(gridColumn);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value || value === '') {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (regexp) {
      const error = !regexp.test(currentValue);
      setIsRegexpError(error);
    }
  }, [currentValue]);

  const getTooltipTitle = () => {
    if (canCopy) {
      const handleCopy = (e) => {
        e.preventDefault();
        e.stopPropagation();
        message.success(t('common.successfullyCopied'));
        copy(value);
      };
      return (
        <div onClick={handleCopy} onKeyDown={handleCopy} role="button" tabIndex={0}>
          {value ?? defaultValue}
          <CopyOutlined
            style={{
              marginLeft: '5px',
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Tooltip overlayClassName={styles.tooltip} title={getTooltipTitle()}>
      <div
        ref={callbackRef}
        className={classnames(styles.root, {
          [styles.isLoading]: isLoading,
          [styles.oob]: gridColumn <= 0,
          [styles.isColumnActive]: gridColumn === Math.ceil(nbColumns / 2),
          [styles.isAppearing]: prevGridColumn === undefined,
          [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
          [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
        })}
        style={{
          gridRow,
          gridColumn,
        }}
        onClick={closeNextColumns}
        onKeyDown={closeNextColumns}
        role="button"
        tabIndex={-1}
      >
        <div className={styles.title}>{title}</div>
        <div className={classnames(styles.inputContainer, { [styles.locked]: locked })}>
          {(() => {
            if (isLoading) {
              return (
                <div className={classnames(styles.inputRoot, styles.valueContainer)}>
                  <Skeleton.Input
                    active
                    loading
                    style={{
                      width: 100,
                      height: '22px',
                    }}
                  />
                </div>
              );
            }
            if (locked) {
              return (
                <div className={classnames(styles.valueContainer)}>
                  {currentValue}
                  <IconsContainer
                    isError={isError || isRegexpError}
                    isValid={isValid}
                    isWarning={isWarning}
                    locked={locked}
                  />
                </div>
              );
            }
            return (
              <>
                <Input
                  autoFocus={autoFocus}
                  className={classnames(styles.inputRoot, { [styles.error]: isError || isRegexpError })}
                  onChange={handleChange ?? ((e) => setCurrentValue(e.target.value))}
                  placeholder={placeholder}
                  value={currentValue}
                  onBlur={handleValid ? () => handleValid(currentValue) : null}
                  onPressEnter={handleValid ? () => handleValid(currentValue) : null}
                />
                <IconsContainer
                  isError={isError || isRegexpError}
                  isValid={isValid}
                  isWarning={isWarning}
                  locked={locked}
                />
              </>
            );
          })()}
        </div>
      </div>
    </Tooltip>
  );
};

export default TextInputElem;
