import React, { useState } from 'react';

import { useMutation as useApolloMutation } from '@apollo/client';
import Modal from 'antd/lib/modal/Modal';
import Column from '../../../../Common/NavigationContainer/Column/Column';
import Elem from '../../../../Common/NavigationContainer/Column/Elem/Elem';
import { useCan } from '../../../../../../contexts/ability.context';
import ConfirmModalContent from './ConfirmModalContent/ConfirmModalContent';
import { useMerchantAccount } from '../../../merchantAccount.context';
import { removeRuleMutation } from './query';
import useNavigateWithSearch from "../../../../../../util/navigate";

const RuleActions = ({ rule, ...props }) => {
  const navigate = useNavigateWithSearch();

  const can = useCan();

  const { selectedMerchantAccount } = useMerchantAccount();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [removeRule] = useApolloMutation(removeRuleMutation);

  return (
    <>
      <Column id={`rule-${rule.id}`} {...props}>
        <Elem
          title='View'
          handleClick={() => navigate(`/router/payment-network/rule/${rule.id}`)}
          active={false}
        />
        {can('delete', 'rule') && (
          <Elem title="Delete" handleClick={() => setIsConfirmModalOpen(true)} active={false} />
        )}
      </Column>
      <Modal
        open={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        closable={false}
        footer={null}
        centered
      >
        <ConfirmModalContent
          handleClose={() => setIsConfirmModalOpen(false)}
          handleConfirm={() => {
            removeRule({
              variables: {
                id: rule.id,
              },
              optimisticResponse: {
                removeRule: {
                  id: rule.id,
                  status: 'inactive',
                  __typename: 'Rule',
                },
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify({
                    id: selectedMerchantAccount,
                    __typename: 'MerchantAccount',
                  }),
                  fields: {
                    rules(existingRules, { readField }) {
                      return existingRules.filter((ruleRef) => data.removeRule.id !== readField('id', ruleRef));
                    },
                  },
                });
              },
            });
            setIsConfirmModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default RuleActions;
