import React, { useState } from 'react';
import { Input, Space, Tag } from 'antd';
import { ContactsOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import useToggle from '../../../../../hooks/useToggle';
import { useTheme } from '../../../../../contexts/theme.context';
import styles from '../../AddableList.module.scss';
import { Block } from '../../../Common/Sider';
import Item from '../../../Common/SiderList/Item';

const AllAccountsAccess = ({ user }) => {
  const { userTheme } = useTheme();
  const [searchInput, setSearchInput] = useState();
  const [displayAll, { toggle }] = useToggle(true);

  const itemList = [...user?.companies[0]?.company.merchantAccounts]
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((ma) => ({
      id: ma.id,
      label: ma.name,
      icon: ContactsOutlined,
      extra: [
        <Tag key="userCount" color={userTheme.primaryColor}>
          {ma.userCount}
        </Tag>,
        !user.companies[0].authorizedMerchantAccounts.find((ama) => ama.id === ma.id) && (
          <ExclamationCircleFilled
            key="iconInfo"
            title={`Access inherited from ${user.companies[0].aclProfile.name} profile.`}
            style={{ marginRight: 8, color: '#ffba00', fontSize: '18px' }}
          />
        ),
      ],
      title: `${ma.name} (${ma.userCount} users)`,
    }));

  const renderItem = (item) => (
    <Item key={item.id} color={user?.companies[0]?.company?.theme?.secondaryColor} {...item} />
  );

  return (
    <Block
      title="Authorized accounts"
      description={
        <Space>
          <ExclamationCircleFilled style={{ color: '#ffba00' }} />
          {`As ${user.companies[0].aclProfile.name}, user has access to all accounts`}
        </Space>
      }
    >
      {itemList.length > 5 && (
        <Input
          className={styles.searchInput}
          placeholder="Search accounts"
          allowClear
          size="normal"
          prefix={<SearchOutlined />}
          onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
        />
      )}
      <div className={styles.searchResults}>
        {searchInput &&
          itemList
            .filter((item) => item.label.toLocaleLowerCase().includes(searchInput)) // apply search
            .map(renderItem)}
      </div>
      <div className={styles.subtitle}>{itemList.length} accounts</div>
      {itemList
        .slice(0, displayAll ? itemList.length : 5) // only 5
        .filter((item) => !searchInput || !item.label.toLocaleLowerCase().includes(searchInput)) // filter current searched
        .map(renderItem)}
      {itemList.length > 5 && (
        <div className={styles.seeAll} tabIndex={0} role="button" onClick={toggle} onKeyPress={toggle}>
          {displayAll ? 'see less' : 'see all'}
        </div>
      )}
    </Block>
  );
};

export default AllAccountsAccess;
