import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import styles from './ButtonElem.module.scss';
import IconsContainer from '../Common/IconsContainer/IconsContainer';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ButtonElem = ({ isError, locked, handleClick, gridRow, gridColumn, title, nbColumns, callbackRef, disabled }) => {
  const prevGridColumn = usePrevious(gridColumn);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  return (
    <div
      ref={callbackRef}
      className={classnames(styles.root, {
        [styles.isColumnActive]: gridColumn === Math.ceil(nbColumns / 2),
        [styles.oob]: gridColumn <= 0,
        [styles.isAppearing]: prevGridColumn === undefined,
        [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
        [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      style={{
        gridColumn,
        gridRow,
      }}
    >
      {title}
      <IconsContainer isError={isError} locked={locked} />
    </div>
  );
};

export default ButtonElem;
