import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Select} from 'antd';
import classnames from 'classnames';
import {useParams} from 'react-router-dom';
import moment from 'moment-timezone';
import {useMutation} from '@apollo/client';
import { useRuleEditor } from '../ruleEditor.context';
import styles from './Footer.module.scss';
import {DATETIME_FORMAT} from '../../../../../constants/DATES';
import useNavigateWithSearch from '../../../../../util/navigate';
import {removeDraftMutation} from './query';
import {useCan} from '../../../../../contexts/ability.context';

const Footer = (props) => {
  const { saveRule, createDraft, rule, loadingCreateDraft, loadingSaveRule, loadingRule } = props;
  const { isEditMode, setIsEditMode } = useRuleEditor();
  const navigate = useNavigateWithSearch();
  const { v } = useParams();
  const can = useCan();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [versionValue, setVersionValue] = useState(v ?? rule?.v.toString())

  const [removeDraft, { loading: loadingRemoveDraft }] = useMutation(removeDraftMutation, {
    variables: {
      id: rule?.id,
    }
  })

  useEffect(() => {
    setVersionValue(v ?? rule?.v.toString())
  }, [v, rule])

  if (isEditMode) {
    return (
      <div
        className={classnames(styles.root, styles.edit)}
      >
        <div
          className={styles.buttonsContainer}
        >
          <Button
            type='primary'
            className={styles.button}
            onClick={() => saveRule().then(() => {
              setIsEditMode(!isEditMode)
            })}
            loading={loadingSaveRule}
          >
            Apply
          </Button>
          <Button
            className={styles.button}
            onClick={() => {
              setIsEditMode(false)
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div
      className={styles.root}
    >
      <Select
        className={classnames(styles.versionSelector, styles.aligner)}
        options={[
          ...(rule?.previous?.map(versionElem => ({
            label: `Version until ${moment(versionElem.updatedAt).format(DATETIME_FORMAT)}`,
            value: versionElem.__v.toString(),
          })) ?? []),
          {
            label: 'Current version',
            value: rule?.v.toString()
          }
        ]}
        value={versionValue}
      />
      <div
        className={styles.buttonsContainer}
      >
        {versionValue === rule?.v.toString() && can('update', 'rule') && (
          <Button
            type='primary'
            className={styles.button}
            loading={loadingCreateDraft || loadingRule}
            onClick={() => createDraft().then(() => {
              setIsEditMode(true)
            }).catch(error => {
              if (error?.graphQLErrors?.[0]?.extensions.code === '409') {
                setIsModalOpen(true)
              } else {
                message.error('An error occurred, please try again later.');
              }
            })}
          >
            Edit
          </Button>
        )}
      </div>
      <Select
        disabled={isEditMode || !rule}
        loading={!rule}
        className={styles.versionSelector}
        options={[
          ...(rule?.previous?.map(versionElem => ({
            label: `Version until ${moment(versionElem.updatedAt).format(DATETIME_FORMAT)}`,
            value: versionElem.__v.toString(),
          })) ?? []),
          {
            label: 'Current version',
            value: rule?.v.toString()
          }
        ]}
        value={versionValue}
        onChange={(value) => navigate(`/router/payment-network/rule/${rule.id}${value === rule?.v.toString() ? '' : `/${value}`}`)}
      />
      <Modal
        open={isModalOpen}
        okText='Keep'
        cancelText='Discard'
        cancelButtonProps={{
          loading: loadingCreateDraft || loadingRemoveDraft,
        }}
        onOk={() => {
          setIsModalOpen(false)
          setIsEditMode(true)
        }}
        onCancel={() => removeDraft().then(() => {
          createDraft().then(() => {
            setIsModalOpen(false)
            setIsEditMode(true)
          })
        })}
        closable={false}
      >
        There are pending changes on this rule. Do you want to keep these changes or discard them?
      </Modal>
    </div>
  )
};

export default Footer;