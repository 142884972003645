import React, {useCallback, useEffect, useMemo, useState} from 'react';
import moment from 'moment-timezone';
import {DatePicker, Input, InputNumber, Radio, Select, Space, Tabs} from 'antd';
import {SwapRightOutlined, WarningOutlined} from '@ant-design/icons';
import { DATE_FORMAT } from '../../../../../../../../../../constants/DATES';

const Tab = {
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
};

// encode function
const objectToArray = (obj) => {
  if (!obj) return null;
  if (obj.from || obj.to) {
    if (obj.from && !obj.to) {
      return ['from', obj.from.format(DATE_FORMAT)];
    }
    if (!obj.from && obj.to) {
      return ['to', obj.to.format(DATE_FORMAT)];
    }
    return [obj.from.format(DATE_FORMAT), obj.to.format(DATE_FORMAT)];
  }
  return [obj.period, obj.number];
};

// decode function
const arrayToObject = (array) => {
  if (!array) return {};
  switch (array[0]) {
    case 'from':
      return {
        from: moment(array[1], DATE_FORMAT),
      };
    case 'to':
      return {
        to: moment(array[1], DATE_FORMAT),
      };
    case 'hours':
    case 'days':
    case 'months':
      return {
        period: array[0],
        number: array[1],
      };
    default:
      // !period && number
      if (!array[0] && array[1]) return { number: array[1] };
      // from && to
      return { from: array[0] && moment(array[0], DATE_FORMAT), to: array[1] && moment(array[1], DATE_FORMAT) };
  }
};

/**
 * DateInput
 *
 * value:
 * { min?, max? }
 * { number, period }
 *
 * @param value
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const DateInput = ({ value, onChange, merchantDataKey }) => {
  const _value = useMemo(() => arrayToObject(value), [value]);
  const setFieldValue = useCallback((key, val) => onChange(objectToArray({ ..._value, [key]: val })), [_value]);
  const [tab, setTab] = useState(_value?.from || _value?.to ? Tab.ABSOLUTE : Tab.RELATIVE); // absolute | relative
  const [older, setOlder] = useState(_value?.number && parseFloat(_value?.number) < 0 ? 'newer' : 'older');
  const [helperText, _setHelperText] = useState('');

  const setHelperText = ({from, to}) => {
    if (from && to) {
      if (parseFloat(from) > parseFloat(to)) {
        _setHelperText(
          <div
            style={{
              color: 'red',
            }}
          >
            <WarningOutlined width={16} height={16} /> min should be lower than max
          </div>
        );
      } else {
        _setHelperText(`${merchantDataKey} >= ${moment(from).format(DATE_FORMAT)} and < ${to.format(DATE_FORMAT)}`);
      }
    } else if (from) {
      _setHelperText(`${merchantDataKey} >= ${from.format(DATE_FORMAT)}`);
    } else if (to) {
      _setHelperText(`${merchantDataKey} < ${to.format(DATE_FORMAT)}`);
    } else {
      _setHelperText('');
    }
  }

  useEffect(() => {
    if (value) {
      setHelperText(_value)
    }
  }, [_value]);

  return (
    <Tabs
      size="small"
      centered
      activeKey={tab}
      onChange={(newTab) => {
        onChange(null);
        setTab(newTab);
      }}
      items={[
        {
          key: Tab.RELATIVE,
          label: 'Relative period',
          children: (
            <>
              <div>
                Select a period
              </div>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                style={{
                  marginTop: 8,
                }}
                value={older}
                onChange={(event) => {
                  setOlder(event.target.value);
                  setFieldValue('number', event.target.value === 'older' ? Math.abs(_value?.number).toString() : (-Math.abs(_value?.number)).toString())
                }}
                options={[
                  {
                    label: 'Older',
                    value: 'older',
                  },
                  {
                    label: 'Newer',
                    value: 'newer',
                  },
                ]}
              />
              <div>
                than
              </div>
              <Input.Group compact>
                <InputNumber
                  style={{ width: '40%' }}
                  value={Math.abs(_value?.number)}
                  onChange={(v) => setFieldValue('number', older === 'older' ? Math.abs(v).toString(): (-Math.abs(v)).toString())}
                  stringMode
                  placeholder='number'
                />
                <Select
                  style={{ width: '60%' }}
                  options={[
                    // { label: 'Hours', value: 'hours' },
                    { label: 'Days', value: 'days' },
                    { label: 'Months', value: 'months' },
                  ]}
                  value={_value?.period}
                  placeholder='period'
                  onChange={(v) => setFieldValue('period', v)}
                />
              </Input.Group>
              <div style={{textAlign: 'center', marginTop: 4}}>
                {_value?.number && _value?.period && `${merchantDataKey} ${_value?.number >= 0 ? '<' : '>='} ${Math.abs(_value?.number)} ${Math.abs(_value?.number) === 1 ? _value?.period.slice(0, -1) : _value?.period} ago`}
              </div>
            </>
          ),
        },
        {
          key: Tab.ABSOLUTE,
          label: 'Absolute period',
          children: (
            <div>
              <Space>
                <DatePicker
                  value={_value?.from}
                  onChange={(v) => {
                    setFieldValue('from', v)
                    setHelperText({from: v, ...value});
                  }}
                  disabledDate={(date) => _value?.to && _value.to < date}
                />
                <SwapRightOutlined style={{ fontSize: 16, color: 'lightgrey' }} />
                <DatePicker
                  value={_value?.to}
                  onChange={(v) => {
                    setFieldValue('to', v)
                    setHelperText({to: v, ..._value});
                  }}
                  disabledDate={(date) => _value?.from && _value.from > date}
                />
              </Space>
              <div style={{textAlign: 'center', marginTop: 4}}>
                {helperText}
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

export default DateInput;
