import React from 'react';
import { Select } from 'antd';

const StringInput = ({ value, onChange }) => (
  <Select
    placeholder="Type the values here"
    mode="tags"
    style={{ width: '100%' }}
    value={value ?? []}
    onChange={onChange}
  />
);

export default StringInput;
