import React from "react";
import { useMutation } from "@apollo/client";
import Column from "../../../../../../Common/NavigationContainer/Column/Column";
import ButtonElem from "../../../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem";
import { removeWebhookHeaderMutation, updateWebhookHeaderMutation } from "./query";
import TextInputElem from "../../../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem";

const HeaderActions = ({ webhook, header, ...props }) => {
  const headerClone = {...webhook.additional_headers};
  delete headerClone[header.key];

  const [removeHeader] = useMutation(
    removeWebhookHeaderMutation,
    {
      variables: {
        id: webhook.id,
        headerKey: header.key,
      },
      optimisticResponse: {
        removeWebhookHeader: {
          id: webhook.id,
          __typename: 'Webhook',
          additional_headers: headerClone,
        }
      }
    }
  );

  const [updateWebhook] = useMutation(
    updateWebhookHeaderMutation,
    {
      variables: {
        id: webhook.id,
      },
    }
  );

  return (
    <Column
      {...props}
    >
      <TextInputElem
        title={header.key}
        value={header.value}
        handleChange={(event) => updateWebhook({
          variables: {
            input: {
              key: header.key,
              value: event.target.value,
            }
          },
          optimisticResponse: {
            updateWebhookHeader: {
              id: webhook.id,
              __typename: 'Webhook',
              additional_headers: {
                ...webhook.additional_headers ?? {},
                [header.key]: event.target.value,
              }
            }
          },
          context: {
            debounceKey: `updateWebhookHeader-${webhook.id}-${webhook.key}`,
            debounceTimeout: 1000,
          },
        })}
      />
      <ButtonElem
        title='Delete'
        handleClick={removeHeader}
      />
    </Column>
  );
};

export default HeaderActions;