import React from 'react';
import Column from '../../../Common/NavigationContainer/Column/Column';
import Elem from '../../../Common/NavigationContainer/Column/Elem/Elem';
import Authentication from './Authentication/Authentication';
import Risk from './Risk/Risk';
import {useMerchantAccount} from '../../merchantAccount.context';
import SOURCES from '../../../../../constants/SOURCES';
import Certificates from './Certificates/Certificates';
import {useCan} from '../../../../../contexts/ability.context';
import PartnerSelect from './PartnerSelect/PartnerSelect';

const ServiceProviders = (props) => {
  const can = useCan();
  const { merchantAccount } = useMerchantAccount();

  return (
    <Column id="serviceProvidersColumn" {...props}>
      <Elem
        id="payment"
        key="payment"
        title="Payment providers"
        value="Add, remove and manage your PSPs"
        subColumn={<PartnerSelect type='payin' />}
      />
      {merchantAccount?.source === SOURCES.STACK ? (
        <Elem
          id="riskAssessment"
          key="riskAssessment"
          title="Risk assessment providers"
          value="Configure your fraud detection tools"
          subColumn={<Risk />}
        />
      ) : null}
      {merchantAccount?.source === SOURCES.STACK ? (
        <Elem
          id="authentication"
          key="authentication"
          title="Authentication service providers"
          value="Select and configure your 3DS tools"
          subColumn={<Authentication />}
        />
      ) : null}
      {merchantAccount?.source === SOURCES.INSIGHTS_POS && can('update', 'merchant_contract') ? (
        <Elem
          id="cashier"
          key="cashier"
          title="Cashier providers"
          value="Add, remove and manage your cashiers"
          subColumn={<PartnerSelect type='cashier' />}
        />
      ) : null}
      {merchantAccount?.source === SOURCES.STACK && can('update', 'merchant_contract') ? (
        <Elem
          id="certificates"
          key="certificates"
          title="Apple pay certificates"
          value="Add, remove and manage your certificates"
          subColumn={<Certificates />}
        />
      ) : null}
    </Column>
  );
}

export default ServiceProviders;
