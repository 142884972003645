import React from 'react';
import {Badge} from 'antd';
import splitterTypes from '../../../../constants/splitterTypes';
import styles from '../../Content.module.scss';
import {CountryFlag} from '../../../../../../../../constants/COUNTRIES';
import {useQuery as useApolloQuery} from '@apollo/client/react/hooks/useQuery';
import {paymentMethodsQuery} from '../../../../Common/query';

const BranchIcon = ({branch, type}) => {
  const { data: { paymentMethods } = { paymentMethods: [] } } = useApolloQuery(paymentMethodsQuery);

  switch (type) {
    case 'issuerCountry':
    case 'customerIpCountry':
      if (branch.condition.length === 1) {
        return (
          <div
            style={{
              padding: '17px 4px',
            }}
          >
            <CountryFlag value={branch.condition[0]} size={32} />
          </div>
        )
      }
    // eslint-disable-next-line no-fallthrough
    case 'paymentMethodName':
      if (branch.condition.length === 1 && paymentMethods.length) {
        return (
          <div
            className={styles.img}
          >
            <img src={paymentMethods.find(pmElem => pmElem.api_name === branch.condition[0])?.imgUrl} alt={paymentMethods.find(pmElem => pmElem.id === branch.condition[0])?.name ?? branch.condition[0]} width={32} height={32} />
          </div>
        )
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return (
        <div
          className={styles.icon}
        >
          {splitterTypes.find(typeElem => typeElem.id === type).icon}
        </div>
      );
  }
};

export default BranchIcon;

/*
[
  {
    id: 'amount',
    icon: <CurrencyEur />,
    name: 'Amount',
  },
  {
    id: 'authenticationLevel',
    icon: <FingerprintIcon />,
    name: 'Authentication level',
  },
  {
    id: 'authorizationCurrency',
    icon: <CurrencyUsdIcon />,
    name: 'Authorization currency',
  },
  {
    id: 'customerIpCountry',
    icon: <WebIcon />,
    name: 'Country (IP)',
  },
  {
    id: 'issuerCountry',
    icon: <WebIcon />,
    name: 'Country (issuer)',
  },
  {
    id: 'issuer',
    icon: <BankTransferOutIcon />,
    name: 'Issuer',
  },
  {
    id: 'list',
    icon: <ClipboardTextIcon />,
    name: 'List',
  },
  {
    id: 'merchantStoreId',
    icon: <StoreIcon />,
    name: 'Merchant store ID',
  },
  {
    id: 'merchantData',
    icon: <CogsIcon />,
    name: 'Merchant data',
  },
  {
    id: 'paymentChannel',
    icon: <ClipboardTextIcon />,
    name: 'Payment channel',
  },
  {
    id: 'paymentMethodName',
    icon: <CardsIcon />,
    name: 'Payment method name',
  },
  {
    id: 'paymentMethodType',
    icon: <CreditCardOutlineIcon />,
    name: 'Payment method type',
  },
  {
    id: 'splitter',
    icon: <AbTestingIcon />,
    name: 'A/B testing',
  },
]
*/