import React from 'react';
import { Button } from 'antd';
import styles from './ConfirmModalContent.module.scss';

const ConfirmModalContent = ({ handleClose, handleConfirm }) => (
  <div className={styles.modalContent}>
    <div className={styles.modalTitle}>Are you sure you want to permanently delete this rule?</div>
    <div className={styles.buttonsContainer}>
      <Button onClick={handleClose} className={styles.modalButton}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} className={styles.modalButton}>
        Confirm
      </Button>
    </div>
  </div>
);

export default ConfirmModalContent;
