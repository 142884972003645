import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { InputNumber } from 'antd';
import styles from './NumberInputElem.module.scss';
import IconsContainer from '../Common/IconsContainer/IconsContainer';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const NumberInputElem = ({
  autoFocus,
  callbackRef,
  canCopy,
  closeNextColumns,
  defaultValue,
  gridColumn,
  gridRow,
  handleChange,
  handleValid,
  isError,
  isLoading,
  isValid,
  isWarning,
  locked,
  max,
  min,
  nbColumns,
  placeholder,
  title,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue ?? value ?? '');

  const prevGridColumn = usePrevious(gridColumn);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value || value === '') {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <div
      ref={callbackRef}
      className={classnames(styles.root, {
        [styles.isLoading]: isLoading,
        [styles.oob]: gridColumn <= 0,
        [styles.isColumnActive]: gridColumn === Math.ceil(nbColumns / 2),
        [styles.isAppearing]: prevGridColumn === undefined,
        [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
        [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
      })}
      style={{
        gridRow,
        gridColumn,
      }}
      onClick={closeNextColumns}
      onKeyPress={closeNextColumns}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.title}>{title}</div>
      <div className={classnames(styles.inputContainer, { [styles.locked]: locked })}>
        {locked ? (
          <div className={styles.valueContainer}>{value}</div>
        ) : (
          <InputNumber
            autoFocus={autoFocus}
            className={classnames(styles.inputRoot, { [styles.error]: isError })}
            max={max}
            min={min}
            onBlur={handleValid ? () => handleValid(currentValue) : null}
            onChange={handleChange ?? ((v) => setCurrentValue(v))}
            onStep={handleChange ?? ((v) => setCurrentValue(v))}
            onPressEnter={handleValid ? () => handleValid(currentValue) : null}
            placeholder={placeholder}
            value={currentValue}
          />
        )}
        <IconsContainer
          isError={isError}
          isValid={isValid}
          isWarning={isWarning}
          locked={locked}
          canCopy={canCopy}
          copyValue={value}
        />
      </div>
    </div>
  );
};

export default NumberInputElem;
