import React, { useState } from "react";
import { Divider, List, Modal, Radio, Space } from "antd";
import styles from "./CsvModal.module.scss";

const CsvModal = ({
  visible,
  onClose,
  content,
  validate,
  onValid,
  action,
  loading
}) => {
  const [separator, setSeparator] = useState('\n');

  return (
    <Modal
      className={styles.root}
      centered
      onCancel={() => {
        onClose();
      }}
      okButtonProps={{
        loading,
      }}
      onOk={() => {
        onValid({
          variables: {
            content: validate(content.split(separator).filter(elem => !!elem)).valid,
          },
        }).then(() => {
          onClose();
        })
      }}
      open={visible}
    >
      <div>
        <div
          className={styles.separatorLabel}
        >
          Separator in the CSV file
        </div>
        <Radio.Group
          onChange={(e) => setSeparator(e.target.value)}
          value={separator}
        >
          <Space
            direction='vertical'
          >
            <Radio value={'\n'}>Carriage return</Radio>
            <Radio value=','>Comma</Radio>
            <Radio value=';'>Semicolon</Radio>
          </Space>
        </Radio.Group>
        <Divider />
        <h3>
          Preview
        </h3>
        <List
          className={styles.list}
          dataSource={(() => {
            const { valid, wrong } = validate(content.split(separator).filter(elem => !!elem));
            return [
              ...wrong.map(item => ({value: item, status: 'wrong'})),
              ...valid.map(item => ({value: item, status: 'valid'})),
            ]
          })()}
          renderItem={(item) => (
            <List.Item>
              <div
                className={styles[item.status]}
              >
                {item.value}
              </div>
            </List.Item>
          )}
        />
        {validate(content.split(separator).filter(elem => !!elem)).wrong.length > 0 && (
          <>
            <Divider />
            <div
              className={styles.errorText}
            >
              * There is invalid data that won't be added to the list.
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CsvModal;