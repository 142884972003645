import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Form, Switch, Typography } from 'antd';
import classnames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SiderContainer from '../../../Common/Sider/SiderContainer/SiderContainer';
import Loader from '../../../Common/Loader/Loader';
import { useMerchantAccount } from '../../merchantAccount.context';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsMutation, FallbackSettingsQuery } from '../query';
import { Block } from '../../../Common/Sider';

const SiderForm = ({ onClose }) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data } = useQuery(FallbackSettingsQuery, { variables: { id: selectedMerchantAccount } });

  const [updateSettings, { loading }] = useMutation(FallbackSettingsMutation);

  const [form] = Form.useForm();
  Form.useWatch([], form);

  const handleFinish = (values) => {
    updateSettings({ variables: { id: selectedMerchantAccount, input: values } }).then((res) => {
      if (res.data) onClose();
    });
  };

  if (!data) return <Loader />;

  const fallbackSettings = data?.merchantAccount.fallbackSettings;
  const fallbacksAreInactive = data?.merchantAccount.fallbackSettings.status === 'inactive';

  return (
    <SiderContainer title="Fallbacks based on authentication results" id="authentication-fallbacks">
      <Form form={form} layout="vertical" className={styles.drawerForm} onFinish={handleFinish}>
        <Block
          title="If authentication failed"
          description="When the payment provider is under maintenance and you have no other partner available, do you still want to do the fallback?"
        >
          <Form.Item
            label="Activate fallback"
            name="authentication_failed"
            initialValue={fallbackSettings.authentication_failed}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              className={classnames({ [styles.warningSwitch]: fallbacksAreInactive })}
            />
          </Form.Item>
          {fallbacksAreInactive && form.getFieldValue('authentication_failed') && (
            <Typography.Text italic className={styles.warningText}>
              <ExclamationCircleOutlined /> Fallback service is inactive in General Settings.
            </Typography.Text>
          )}
        </Block>
        <Block
          title="If authentication succeeds"
          description="If the authentication is successful but the 3DSecure data is unusable in a fallback, would you like to attempt a non 3DSecure transaction with a TRA exemption?"
        >
          <Form.Item
            label="Forced authorization"
            name="authentication_succeed"
            initialValue={fallbackSettings.authentication_succeed}
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Block>
        <div style={{ margin: '0 24px' }}>
          <Divider />
          <div className={styles.bottomButtons}>
            <Button type="text" onClick={onClose}>
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              SAVE
            </Button>
          </div>
        </div>
      </Form>
    </SiderContainer>
  );
};

export default SiderForm;
