import React, { useContext, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {Space, Tag} from 'antd';
import Elem from '../../../../../../../Common/NavigationContainer/Column/Elem/Elem';
import { ContractEditionContext } from '../../../../../PaymentNetwork';
import MerchantContractButtons from './MerchantContractButtons/MerchantContractButtons';
import { useCan } from '../../../../../../../../../contexts/ability.context';
import SOURCES from '../../../../../../../../../constants/SOURCES';
import {merchantContractQuery} from '../query';
import {PartnerTypes} from '../../../../../../../Admin/Partners/constants';

const MerchantContract = ({ merchantContract, type, ...props }) => {
  const { editedContractId, setEditedContractId } = useContext(ContractEditionContext);

  const {data, loading} = useQuery(merchantContractQuery, {
    variables: {
      id: merchantContract.id,
    }
  })

  const can = useCan();
  const { t } = useTranslation();

  // Close merchantContract drawer on unmount
  useEffect(() => {
    if (props.activeOnCreate) {
      setEditedContractId(merchantContract.id);
    }
    return () => setEditedContractId(null);
  }, []);

  return (
    <>
      <Elem
        title={merchantContract.name ? <Space>{merchantContract.name} { merchantContract.is_demo && <Tag style={{alignSelf: 'center'}} color='yellow'>Demo</Tag>}</Space> : 'Not named contract'}
        value={(() => {
          if (type === PartnerTypes.CASHIER) {
            return '';
          }
          if (merchantContract.source === SOURCES.INSIGHTS) {
            return t(`sources.${SOURCES.INSIGHTS}`);
          }
          if (loading) {
            return '';
          }
          return `${data?.merchantContract?.payment_methods?.length} payment methods`;
        })()}
        subColumn={can('update', 'merchant_contract') && <MerchantContractButtons />}
        isLoading={merchantContract.id === 'temp'}
        handleClick={() => setEditedContractId(merchantContract.id)}
        isWarning={merchantContract.status !== 'active'}
        isValid={merchantContract.status === 'active'}
        {...props}
        active={editedContractId === merchantContract.id}
      />
    </>
  );
};

export default MerchantContract;
