import React from 'react';
import CustomNavTabs from '../Common/CustomNavTabs/CustomNavTabs';

const NavButtons = () => (
  <CustomNavTabs
    items={[
      { path: '/teams/users', label: 'Users' },
      { path: '/teams/accounts', label: 'Accounts' },
    ]}
  />
);

export default NavButtons;
