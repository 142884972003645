import React, {useState} from 'react';
import {useQuery} from '@apollo/client';
import {Button, Col, List, Row, Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import SiderForm from './SiderForm/SiderForm';
import styles from './Sso.module.scss';
import Loader from '../Common/Loader/Loader';
import SiderPage from '../Common/SiderPage/SiderPage';
import {useCompany} from '../../../contexts/app.context';
import {companySsoQuery} from './query';
import {useCan} from '../../../contexts/ability.context';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import {formatEndUnderline} from '../Brainpower/Common/utils';
import Page from '../Common/Page/Page';

const Sso = () => {
  const can = useCan();
  const [company] = useCompany();

  const { data: { merchantCompany } = { merchantCompany: null }, loading } = useQuery(companySsoQuery, {
    variables: {
      id: company,
    }
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <AccountMultipleIcon />
          {formatEndUnderline('Login')}
        </Space>
      }
    >
      <SiderPage className={styles.root} defaultOpen isSiderHidden={!open} siderContent={<SiderForm onClose={handleClose} company={merchantCompany} />} hideToggle>
        <div>
          {!merchantCompany ? (
            <div className={styles.loader}>
              <Loader size="large" />
            </div>
          ) : (
            <Row key="body" className={styles.rowList} justify="space-around">
              <Col md={16} lg={12} xl={8}>
                {can('update', 'company-login') && (
                  <Button
                    className={styles.editButton}
                    icon={<EditOutlined />}
                    size="large"
                    block
                    onClick={handleOpen}
                  >
                    Edit settings
                  </Button>
                )}
                <List loading={loading}>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="Login method"
                      description={merchantCompany.loginMethod === 'form' ? 'Form' : 'SSO'}
                    />
                  </List.Item>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="IdP-provided Entity ID"
                      description={merchantCompany.sso.config.idpEntityId ?? 'n/a'}
                    />
                  </List.Item>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="SSO URL"
                      description={merchantCompany.sso.config.ssoURL ?? 'n/a'}
                    />
                  </List.Item>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="Customer-defined SAML Entity ID"
                      description={merchantCompany.sso.config.rpEntityId ?? 'n/a'}
                    />
                  </List.Item>
                  <List.Item className={styles.listItem}>
                    <List.Item.Meta
                      title="Certificate"
                      description={merchantCompany.sso.config.x509Certificates.length ? merchantCompany.sso.config.x509Certificates[0] : 'n/a'}
                      className={styles.certificate}
                    />
                  </List.Item>
                </List>
              </Col>
            </Row>
          )}
        </div>
      </SiderPage>
    </Page>
  )
};

export default Sso;