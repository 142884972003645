import React, { useState } from 'react';
import { Dropdown, Input, Tag } from 'antd';
import { ContactsOutlined, SearchOutlined } from '@ant-design/icons';
import useToggle from '../../../../../hooks/useToggle';
import { useTheme } from '../../../../../contexts/theme.context';
import styles from '../../AddableList.module.scss';
import { Block } from '../../../Common/Sider';
import Item from '../../../Common/SiderList/Item';
import Draggable from '../../../Common/DragAndDrop/Draggable';

const AvailableAccounts = ({ user, onAdd }) => {
  const { userTheme } = useTheme();
  const [searchInput, setSearchInput] = useState();
  const [displayAll, { toggle }] = useToggle(true);

  const itemList = user?.companies[0]?.company.merchantAccounts
    .filter((ma) => !user.companies[0].authorizedMerchantAccounts.find((ama) => ama.id === ma.id))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((ma) => ({
      id: ma.id,
      label: ma.name,
      icon: ContactsOutlined,
      extra: [
        <Tag key="userCount" color={userTheme.primaryColor}>
          {ma.userCount}
        </Tag>,
      ],
      title: `${ma.name} (${ma.userCount} users)`,
    }));

  const renderItem = onAdd
    ? (item) => (
        <Dropdown
          key={item.id}
          menu={{ items: [{ key: 'delete', label: 'Add', onClick: () => onAdd(item.id) }] }}
          trigger={['contextMenu']}
        >
          <div>
            <Draggable key={item.id} dataFormat="availableAccount" data={item.id} draggable>
              <Item color={user?.companies[0]?.company?.theme?.secondaryColor} isDraggable {...item} />
            </Draggable>
          </div>
        </Dropdown>
      )
    : (item) => <Item color={user?.companies[0]?.company?.theme?.secondaryColor} {...item} />;

  return (
    <Block title="Available accounts" description="Drag account to add access">
      {itemList.length > 5 && (
        <Input
          className={styles.searchInput}
          placeholder="Search accounts"
          allowClear
          size="normal"
          prefix={<SearchOutlined />}
          onChange={(event) => setSearchInput(event.target.value.toLowerCase())}
        />
      )}
      <div className={styles.searchResults}>
        {searchInput &&
          itemList
            .filter((item) => item.label.toLocaleLowerCase().includes(searchInput)) // apply search
            .map(renderItem)}
      </div>
      <div className={styles.subtitle}>{itemList.length} accounts</div>
      {itemList
        .slice(0, displayAll ? itemList.length : 5) // only 5
        .filter((item) => !searchInput || !item.label.toLocaleLowerCase().includes(searchInput)) // filter current searched
        .map(renderItem)}
      {itemList.length > 5 && (
        <div className={styles.seeAll} tabIndex={0} role="button" onClick={toggle} onKeyPress={toggle}>
          {displayAll ? 'see less' : 'see all'}
        </div>
      )}
    </Block>
  );
};

export default AvailableAccounts;
