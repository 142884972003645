import React, { useState, useEffect } from 'react';
import { useLazyQuery as useApolloLazyQuery, useMutation as useApolloMutation } from '@apollo/client';

import Column from '../../../Common/NavigationContainer/Column/Column';
import Elem from '../../../Common/NavigationContainer/Column/Elem/Elem';
import RuleForm from './RuleForm/RuleForm';
import styles from './RoutingRules.module.scss';
import RuleActions from './RuleActions/RuleActions';
import { useCan } from '../../../../../contexts/ability.context';
import { useMerchantAccount } from '../../merchantAccount.context';
import { rulesQuery, updateMerchantAccountRulesOrder } from './query';
import LoadingElem from '../../../Common/NavigationContainer/Column/LoadingElem/LoadingElem';
import TestRouterDrawer from './TestRouterDrawer/TestRouterDrawer';

const RoutingRules = (props) => {

  const [draggedOverRule, setDraggedOverRule] = useState(null);

  const [draggedRuleIndex, setDraggedRuleIndex] = useState(null);

  const { selectedMerchantAccount } = useMerchantAccount();

  const [activeOnCreate, setActiveOnCreate] = useState(false);

  const [isTestRouterDrawerOpen, setIsTestRouterDrawerOpen] = useState(false);

  const can = useCan();

  const [getRules, { data: { merchantAccount } = { merchantAccount: null }, loading }] = useApolloLazyQuery(rulesQuery);

  useEffect(() => {
    if (selectedMerchantAccount) {
      getRules({
        variables: {
          merchantAccountId: selectedMerchantAccount,
        },
      });
    }
  }, [selectedMerchantAccount]);

  const [updateRulesOrder] = useApolloMutation(updateMerchantAccountRulesOrder);

  useEffect(() => {
    if (merchantAccount?.rules?.length) {
      setActiveOnCreate(true);
    }
  }, [merchantAccount?.rules]);

  return (
    <Column id="routingRulesColumn" key="routingRulesColumn" {...props}>
      {can('create', 'rule') && (
        <Elem
          id="addRule"
          key="addRule"
          title="Add new rule"
          value="Configure your routing rules"
          subColumn={<RuleForm />}
        />
      )}
      {loading && !merchantAccount?.rules && <LoadingElem />}
      {merchantAccount?.rules &&
        [...(merchantAccount?.rules ?? [])]
          .sort((a, b) => a.order - b.order)
          ?.map((rule, index) => (
            <Elem
              activeOnCreate={activeOnCreate}
              key={rule.id}
              id={rule.id}
              title={rule.name}
              customIcon={<div className={styles.numberIcon}>{index + 1}</div>}
              draggable={can('update', 'rule')}
              onDragStart={(event) => {
                event.dataTransfer.setData('ruleId', rule.id);
                setDraggedRuleIndex(index);
              }}
              onDragOver={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDraggedOverRule(index);
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDraggedOverRule(null);
              }}
              onDrop={(event) => {
                event.preventDefault();
                setDraggedOverRule(null);
                const droppedRuleId = event.dataTransfer.getData('ruleId');
                const ruleToMoveIndex = merchantAccount.rules.findIndex((ruleElem) => ruleElem.id === droppedRuleId);
                const newRulesState = [...merchantAccount.rules];
                newRulesState.splice(index, 0, newRulesState.splice(ruleToMoveIndex, 1)[0]);
                updateRulesOrder({
                  variables: {
                    id: selectedMerchantAccount,
                    input: {
                      rules: newRulesState.map((r) => r.id),
                    },
                  },
                  optimisticResponse: {
                    updateMerchantAccountRulesOrder: {
                      id: selectedMerchantAccount,
                      rules: newRulesState.map((r, i) => ({
                        id: r.id,
                        order: i,
                        __typename: 'Rule',
                      })),
                      __typename: 'MerchantAccount',
                    },
                  },
                });
              }}
              subColumn={<RuleActions rule={rule} />}
              extraStyle={
                index === draggedOverRule
                  ? {
                      transform: `translateY(${draggedRuleIndex > index ? '' : '-'}6px)`,
                    }
                  : {}
              }
            />
          ))}
      <Elem
        id='testRouter'
        key='testRouter'
        title='Rule testing engine'
        value='Rule testing module'
        handleClick={() => setIsTestRouterDrawerOpen(!isTestRouterDrawerOpen)}
      />
      <TestRouterDrawer
        visible={isTestRouterDrawerOpen}
        onClose={() => setIsTestRouterDrawerOpen(false)}
      />
    </Column>
  );
};

export default RoutingRules;
