import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import { useMutation as useApolloMutation } from '@apollo/client/react/hooks/useMutation';
import { SiderContainer } from '../../../../Common/Sider';
import { merchantContractQuery, updateContractMutation } from './query';
import ContractForm from './ContractForm/ContractForm';
import PaymentMethodForm from './PaymentMethodForm/PaymentMethodForm';
import { ContractEditionContext } from '../../PaymentNetwork';
import SiderTitle from './SiderTitle/SiderTitle';

export const MerchantContractContext = React.createContext(null);

const MerchantContractDrawer = ({
  merchantContractId,
  setForcedActiveColumns,
}) => {
  const { setIsSaving } = useContext(ContractEditionContext);

  const [editedPaymentMethod, setEditedPaymentMethod] = useState(null);

  const [getMerchantContract, { data: { merchantContract } = { merchantContract: null } }] = useApolloLazyQuery(
    merchantContractQuery,
    {
      variables: {
        id: merchantContractId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all'
    },
  );

  useEffect(() => {
    setEditedPaymentMethod(null);
    if (merchantContractId !== 'temp' && merchantContractId !== null) {
      getMerchantContract();
    }
  }, [merchantContractId]);

  const [updateMerchantContract, { loading: loadingUpdate }] = useApolloMutation(updateContractMutation, {
    variables: {
      id: merchantContractId,
    },
  });

  useEffect(() => {
    setIsSaving(loadingUpdate);
  }, [loadingUpdate]);

  return (
    <MerchantContractContext.Provider
      value={{
        merchantContract,
        updateMerchantContract,
      }}
    >
      <SiderContainer title={<SiderTitle />}>
        {editedPaymentMethod ? (
          <PaymentMethodForm
            editedPaymentMethod={editedPaymentMethod}
            setEditedPaymentMethod={setEditedPaymentMethod}
          />
        ) : (
          <ContractForm
            editedPaymentMethod={editedPaymentMethod}
            setEditedPaymentMethod={setEditedPaymentMethod}
            setForcedActiveColumns={setForcedActiveColumns}
          />
        )}
      </SiderContainer>
    </MerchantContractContext.Provider>
  );
};

export default MerchantContractDrawer;
