import React from 'react';
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import {
  addBranchSplitterMutation, removeBranchSplitterMutation, updateBranchConditionMutation
} from './query';
import CountryBranchInput from '../Input/CountryBranchInput/CountryBranchInput';
import IssuerBranchInput from '../Input/IssuerBranchInput/IssuerBranchInput';
import ListBranchInput
  from '../Input/ListBranchInput/ListBranchInput';
import AmountBranchInput
  from '../Input/AmountBranchInput/AmountBranchInput';
import AuthorizationCurrencyBranchInput
  from '../Input/AuthorizationCurrencyBranchInput/AuthorizationCurrencyBranchInput';
import AuthenticationLevelBranchInput
  from '../Input/AuthenticationLevelBranchInput/AuthenticationLevelBranchInput';
import SplitterBranchInput
  from '../Input/SplitterBranchInput/SplitterBranchInput';
import PaymentTypeBranchInput
  from '../Input/PaymentTypeBranchInput/PaymentTypeBranchInput';
import PaymentMethodBranchInput
  from '../Input/PaymentMethodBranchInput/PaymentMethodBranchInput';
import PaymentChannelBranchInput
  from '../Input/PaymentChannelBranchInput/PaymentChannelBranchInput';
import MerchantStoreIdBranchInput
  from '../Input/MerchantStoreIdBranchInput/MerchantStoreIdBranchInput';
import { useMerchantAccountContracts } from '../../../merchantAccountContracts.context';
import {useRuleEditor} from '../../../ruleEditor.context';
import MerchantDataBranchInput from '../Input/MerchantDataBranchInput/MerchantDataBranchInput';

const SiderBranch = (props) => {
  const { splitter, branch } = props;

  const { merchantAccount } = useMerchantAccountContracts();
  const { setIsSiderOpen, setEditedItem } = useRuleEditor();

  const [addBranchSplitterTemp] = useApolloMutation(addBranchSplitterMutation);
  const [removeBranchSplitter] = useApolloMutation(removeBranchSplitterMutation);
  const [updateBranchCondition] = useApolloMutation(updateBranchConditionMutation);

  const addBranchSplitter = (condition) => {
    const color = '#000000';
    addBranchSplitterTemp({
      variables: {
        id: splitter.id,
        input: {
          condition,
          next: null,
          color,
        },
      },
      optimisticResponse: {
        addBranchSplitter: {
          id: splitter.id,
          branches: [
            ...splitter.branches,
            {
              id: 'branchTemp',
              color,
              condition,
              contract: null,
              next: null,
              blocking: false,
              __typename: 'SplitterBranch',
            },
          ],
          __typename: 'Splitter',
        },
      },
    }).then(result => setEditedItem({
      edition: 'branch',
      branch: result.data.addBranchSplitter.branches.at(-1),
      splitter,
    }));
  };

  const editBranch = (condition) => {
    updateBranchCondition({
      variables: {
        id: branch?.id,
        condition,
      },
      optimisticResponse: {
        updateBranchCondition: {
          ...branch,
          id: branch?.id,
          __typename: 'SplitterBranch',
          condition,
        },
      }
    })
  }

  const removeBranch = () => {
    removeBranchSplitter({
      variables: {
        id: splitter.id,
        branchId: branch.id,
      },
      optimisticResponse: {
        removeBranchSplitter: {
          id: splitter.id,
          __typename: 'Splitter',
          branches: splitter.branches.filter((branchElem) => branchElem.id !== branch.id),
        },
      },
    });
    setIsSiderOpen(false);
  }

  const handleChange = branch ? editBranch : addBranchSplitter;

  const inputProps = {
    splitter,
    branch,
    handleChange,
    removeBranch,
  };

  switch (splitter.type) {
    case 'issuerCountry':
    case 'customerIpCountry':
      return (
        <CountryBranchInput
          {...inputProps}
        />
      );
    case 'issuer':
      return (
        <IssuerBranchInput
          {...inputProps}
        />
      );
    case 'list':
      return (
        <ListBranchInput
          {...inputProps}
        />
      );
    case 'amount':
      return (
        <AmountBranchInput
          {...inputProps}
          merchantAccount={merchantAccount}
        />
      );
    case 'authorizationCurrency':
      return (
        <AuthorizationCurrencyBranchInput
          {...inputProps}
        />
      );
    case 'authenticationLevel':
      return (
        <AuthenticationLevelBranchInput
          {...inputProps}
        />
      );
    case 'splitter':
      return (
        <SplitterBranchInput
          {...inputProps}
        />
      );
    case 'paymentMethodType':
      return (
        <PaymentTypeBranchInput
          {...inputProps}
        />
      );
    case 'paymentMethodName':
      return (
        <PaymentMethodBranchInput
          {...inputProps}
        />
      );
    case 'paymentChannel':
      return (
        <PaymentChannelBranchInput
          {...inputProps}
        />
      );
    case 'merchantStoreId':
      return (
        <MerchantStoreIdBranchInput
          {...inputProps}
        />
      );
    case 'merchantData':
      return (
        <MerchantDataBranchInput
          key={branch?.id}
          {...inputProps}
        />
      );
    default:
      return <div>Can&apos;t add branches for this Splitter</div>;
  }
};

export default SiderBranch;