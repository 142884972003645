import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import styles from './DropArea.module.scss';
import { MerchantContractContext } from '../../../MerchantContractDrawer';

const DropArea = ({ addPaymentMethod }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const { merchantContract } = useContext(MerchantContractContext);

  return (
    <div
      id="dropArea"
      key="dropArea"
      className={classnames(styles.dropArea, { [styles.dragOver]: isDragOver })}
      onDrop={(event) => {
        event.preventDefault();
        const paymentMethod = event.dataTransfer.getData('paymentMethod');
        setIsDragOver(false);
        addPaymentMethod({
          variables: {
            id: merchantContract.id,
            input: {
              paymentMethod,
            },
          },
        }); // .then(() => setEditedPaymentMethod(paymentMethod));
      }}
      onDragEnter={(event) => {
        event.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setIsDragOver(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
    >
      Drag and drop here
    </div>
  );
};

export default DropArea;
