import React, { useState, useEffect } from 'react';
import {Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import styles from './SplitterBranchInput.module.scss';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const getTotal = (splitter) => splitter?.branches?.reduce((acc, branch) => acc + Number(branch.condition[0]), 0);

const SplitterBranchInput = (props) => {
  const { splitter, handleChange, removeBranch, branch } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [splitterTotal, setSplitterTotal] = useState(getTotal(splitter));
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setSplitterTotal(getTotal(splitter));
  }, [splitter.branches]);

  const [newValue, setNewValue] = useState(branch?.condition[0] ?? null);


  useEffect(() => {
    form.setFieldValue('splitter', branch?.condition[0] ?? null);
    setHasChanged(false);
  }, [branch])

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.splitter.label`)} condition`}
      description='Choose a percentage'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={() => {
          handleChange([newValue.toString()]);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true);
        }}
        className={styles.form}
      >
        <Form.Item
          name='splitter'
          validateStatus={newValue + splitterTotal <= 100 ? 'success' : 'error'}
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                const total = splitter.branches.filter(branchElemFilter => branchElemFilter.id !== branch?.id).reduce((acc, branchElem) => acc + branchElem.condition.reduce((acc2, elem) => acc2 + elem*1, 0), 0);
                if (total + getFieldValue('splitter')*1 > 100) {
                  return Promise.reject(new Error('The total must be 100% max'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input
            type="number"
            className={styles.input}
            autoFocus
            value={newValue}
            onChange={(event) => setNewValue(parseInt(event.target.value, 10))}
            suffix="%"
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default SplitterBranchInput;
