import React, {useContext, useState} from 'react';

export const RuleEditorContext = React.createContext(undefined);

export const useRuleEditor = () => useContext(RuleEditorContext);

const RuleEditorProvider = ({ children }) => {
  const [isEditMode, _setIsEditMode] = useState(false);
  const [editedItem, setEditedItem] = useState(null);
  const [isSiderOpen, setIsSiderOpen] = useState(false);

  const setIsEditMode = (value) => {
    if (value === false) {
      setIsSiderOpen(false);
      setEditedItem(null);
    }
    _setIsEditMode(value)
  }

  return (
    <RuleEditorContext.Provider
      value={{
        isEditMode,
        setIsEditMode,
        editedItem,
        setEditedItem,
        isSiderOpen,
        setIsSiderOpen: (isOpen) => setIsSiderOpen(isOpen && isEditMode),
      }}
    >
      { children }
    </RuleEditorContext.Provider>
  );
};

export default RuleEditorProvider;