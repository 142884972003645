import React from 'react';
import Icon from '@ant-design/icons';

const WarningSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="90.724" height="90.724" viewBox="0 0 90.724 90.724">
    <path
      id="Icon_awesome-exclamation-circle"
      data-name="Icon awesome-exclamation-circle"
      d="M91.286,45.924A45.362,45.362,0,1,1,45.924.563,45.359,45.359,0,0,1,91.286,45.924ZM45.924,55.07a8.414,8.414,0,1,
      0,8.414,8.414A8.414,8.414,0,0,0,45.924,55.07ZM37.936,24.826,39.293,49.7a2.195,2.195,0,0,0,2.192,2.075h8.88A2.195,
      2.195,0,0,0,52.556,49.7l1.357-24.876a2.195,2.195,0,0,0-2.192-2.315H40.128A2.195,2.195,0,0,0,37.936,24.826Z"
      transform="translate(-0.563 -0.563)"
      fill="red"
    />
  </svg>
);

const WarningIcon = (props) => <Icon component={WarningSvg} {...props} />;

export default WarningIcon;
