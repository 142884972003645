import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import { useQuery as useApolloQuery } from '@apollo/client';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import { paymentChannelsQuery } from './query';
import FormButtons from '../Common/FormButtons/FormButtons';
import {Block} from '../../../../../../Common/Sider';

const PaymentChannelBranchInput = (props) => {
  const { splitter, handleChange, removeBranch, branch } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const { data: { paymentChannels } = { paymentChannels: [] }, loading } = useApolloQuery(paymentChannelsQuery);

  useEffect(() => {
    form.setFieldValue('paymentChannels', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const disabledOptions = branch ? [] : splitter?.branches?.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []) ?? [];

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentChannel.label`)} condition`}
      description='Select payment channels in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.paymentChannels);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true);
        }}
      >
        <Form.Item name='paymentChannels'>
          <SelectBranchInput
            options={paymentChannels
              ?.map((channel) => ({
                value: channel.id,
                label: channel.name,
                disabled: disabledOptions.includes(channel.id)
              }))
              .filter((channel) => channel.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            defaultValue={branch?.condition ?? []}
            placeholder="Select channels in the list"
            isLoading={loading}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default PaymentChannelBranchInput;
