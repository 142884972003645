import React, {useEffect, useState} from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormButtons from '../Common/FormButtons/FormButtons';
import { Block } from '../../../../../../Common/Sider';
import NumberInput from './MerchantDataInput/NumberInput/NumberInput';
import DateInput from './MerchantDataInput/DateInput/DateInput';
import BooleanInput from './MerchantDataInput/BooleanInput/BooleanInput';
import StringInput from './MerchantDataInput/StringInput/StringInput';
import {useRuleEditor} from '../../../../ruleEditor.context';
import {getDefaultValues} from '../AmountBranchInput/AmountBranchInput';

const MerchantDataBranchInput = (props) => {
  const { handleChange, removeBranch, branch, splitter } = props;

  const { setEditedItem } = useRuleEditor();

  const [hasChanged, setHasChanged] = useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();


  useEffect(() => {
    if (splitter.merchant_data_type === 'number') {
      if (branch?.condition) {
        form.setFieldsValue(getDefaultValues(branch?.condition));
      } else {
        form.setFieldsValue({from: null, to: null});
      }
    } else {
      form.setFieldValue('condition', branch?.condition ?? null);
    }
    setHasChanged(false);
  }, [branch])

  if (!splitter.merchant_data_key || !splitter.merchant_data_type) {
    setEditedItem({
      splitter,
      edition: 'splitter',
    });
  }

  let input;

  switch (splitter.merchant_data_type) {
    case 'boolean': {
      const disabledOptions = splitter.branches.filter(branchElem => branchElem.id !== branch?.id).map(branchElem => branchElem.condition[0]);
      input = (
        <Form.Item name="condition">
          <BooleanInput
            disabledOptions={disabledOptions}
          />
        </Form.Item>
      );
      break;
    }
    case 'date':
      input =  (
        <Form.Item name="condition">
          <DateInput
            merchantDataKey={splitter.merchant_data_key}
          />
        </Form.Item>
      );
      break;
    case 'number':
      input = <NumberInput
        merchantDataKey={splitter.merchant_data_key}
      />;
      break;
    case 'string':
      input =  (
        <Form.Item
          name="condition"
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                let isOk = true;
                getFieldValue('condition').forEach(value => {
                  if (value.replaceAll(' ', '') !== value.replaceAll(/[^a-zA-Z0-9_-]/gi, '')) {
                    isOk = false;
                  }
                })
                return isOk ? Promise.resolve() : Promise.reject(new Error('Characters should be alphanumeric (A-Z, a-z, 0-9), _ or -'));

              }
            })
          ]}
        >
          <StringInput />
        </Form.Item>
      );
      break;
    default:
      return null;
  }

  return (
    <Block
      title={`${t(
        `andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.merchantData.label`,
      )} condition`}
      description={`Value of ${splitter.merchant_data_key}`}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          condition: branch?.condition ?? null,
        }}
        onFinish={(values) => {
          handleChange(values.condition)
          setHasChanged(false);
        }}
        onValuesChange={() => setHasChanged(true)}
      >
        {input}
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default MerchantDataBranchInput;
