import React, {useEffect, useState} from 'react';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import {useQuery as useApolloQuery} from '@apollo/client/react/hooks/useQuery';
import {useMutation as useApolloMutation} from '@apollo/client/react/hooks/useMutation';
import {Button, Form, Select, Space, Tooltip} from 'antd';
import BlockHelperIcon from '@2fd/ant-design-icons/lib/BlockHelper';
import {Block} from '../../../../../Common/Sider';
import siderStyles from '../../Sider.module.scss';
import styles from '../SiderSplitter/SiderSplitter.module.scss';
import {merchantAccountContractsQuery} from '../../../query';
import {useMerchantAccountContracts} from '../../../merchantAccountContracts.context';
import {setContractSplitterMutation, setSplitterBlockingMutation, setBranchBlockingMutation, setContractBranchMutation} from './query';
import {filterContracts} from './utils';

const SiderContract = (props) => {
  const { splitter, contract, branch } = props;
  const { merchantAccount } = useMerchantAccountContracts();

  const [form] = Form.useForm();

  const [filteredContracts, setFilteredContracts] = useState([])

  const { data: { merchantAccount: merchantAccountContracts } = { merchantAccount: null } } = useApolloQuery(merchantAccountContractsQuery, {
    variables: {
      id: merchantAccount.id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const [setContractSplitter] = useApolloMutation(setContractSplitterMutation);
  const [setContractBranch] = useApolloMutation(setContractBranchMutation);

  const [setSplitterBlocking] = useApolloMutation(setSplitterBlockingMutation);
  const [setBranchBlocking] = useApolloMutation(setBranchBlockingMutation);

  const setContract = (contractId) => {
    const selectedContract = merchantAccountContracts?.merchant_contracts?.find(contractElem => contractElem?.id === contractId)
    if (splitter && !branch) {
      setContractSplitter({
        variables: {
          id: splitter.id,
          contractId,
        },
        optimisticResponse: {
          setContractSplitter: {
            id: splitter.id,
            contract: contractId ? {
              id: contractId,
              partner: selectedContract?.partner,
              __typename: 'MerchantContract',
            } : null,
            blocking: false,
            __typename: 'Splitter',
          },
        },
      })
    } else if (branch) {
      setContractBranch({
        variables: {
          id: branch.id,
          contractId,
        },
        optimisticResponse: {
          updateBranchContract: {
            id: branch.id,
            contract: contractId ? {
              id: contractId,
              partner: selectedContract?.partner,
              __typename: 'MerchantContract',
            } : null,
            blocking: false,
            __typename: 'SplitterBranch',
          },
        },
      });
    }
  }

  const setBlocking = () => {
    if (splitter && !branch) {
      setSplitterBlocking({
        variables: {
          id: splitter.id,
        },
        optimisticResponse: {
          setSplitterBlocking: {
            id: splitter.id,
            contract: null,
            blocking: true,
            __typename: 'Splitter',
          },
        },
      })
    } else if (branch) {
      setBranchBlocking({
        variables: {
          id: branch.id,
        },
        optimisticResponse: {
          setBranchBlocking: {
            id: branch.id,
            contract: null,
            blocking: true,
            __typename: 'SplitterBranch',
          },
        },
      });
    }
  }

  useEffect(() => {
    if (branch && branch.blocking || !branch && splitter.blocking) {
      form.setFieldValue('merchantContract', '__blocking__')
    } else {
      form.setFieldValue('merchantContract', (branch ? branch?.contract?.id : splitter?.contract?.id) ?? null)
    }
  }, [splitter, branch])

  useEffect(() => {
    if (merchantAccountContracts?.merchant_contracts?.length) {
      setFilteredContracts(
        filterContracts(splitter, merchantAccountContracts?.merchant_contracts),
      );
    }
  }, [merchantAccountContracts?.merchant_contracts, splitter, branch]);

  return (
    <Block
      title={contract ? 'Edit the contract for this route' : 'Select a contract for this route'}
    >
      <div
        className={styles.blockContent}
      >
        <Form
          form={form}
        >
          <Form.Item
            name='merchantContract'
          >
            <Select
              onChange={(value) => {
                if (value === '__blocking__') {
                  setBlocking();
                } else {
                  setContract(value);
                }
              }}
              placeholder='Select a merchant contract'
            >
              {filteredContracts
                ?.filter((merchantContract) => merchantContract.type === 'payin')
                ?.sort((a, b) => {
                  if (!a.name || !b.name) {
                    if (b.name) {
                      return 1;
                    }
                    if (a.name) {
                      return -1;
                    }
                    return a.id > b.id ? 1 : -1;
                  }
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                })
                ?.map(merchantContract => {
                  // Used to remove the old contract of the options if changed and not valid anymore
                  if (form.getFieldValue('merchantContract') !== merchantContract.id && merchantContract.unavailable) {
                    return null;
                  }
                  return (
                    <Select.Option
                      key={merchantContract.id}
                      value={merchantContract.id}
                      hidden={merchantContract.inactive}
                    >
                      <Tooltip
                        title={(() => {
                          if (merchantContract.inactive)
                            return 'This contract is not active anymore';
                          if (merchantContract.unavailable)
                            return 'This contract does not match the conditions anymore';
                          return null;
                        })()}
                      >
                        <Space
                          style={merchantContract.unavailable ? {color: '#FFA933'} : {}}
                        >
                          <img src={merchantContract.partner.company.theme.icon} width={16} height={16} alt={merchantContract.partner.name} />
                          {merchantContract.name || merchantContract.id}
                        </Space>
                      </Tooltip>
                    </Select.Option>
                  )
                })}
              <Select.Option value='__blocking__' label='Blocking'>
                <Space>
                  <BlockHelperIcon
                    style={{
                      color: 'rgb(245, 34, 45)'
                    }}
                  />
                  Block payment process
                </Space>
              </Select.Option>
            </Select>
          </Form.Item>
          <div
            className={siderStyles.formButtons}
          >
            <Button
              loading={splitter.id === 'splitterTemp' || branch?.id === 'branchTemp'}
              disabled={splitter.id === 'splitterTemp' || branch?.id === 'branchTemp'}
              onClick={() => {
                setContract(null)
                form.setFieldValue('merchantContract', null);
              }}
            >
              <TrashCanIcon />
              Remove
            </Button>
          </div>
        </Form>
      </div>
    </Block>
  )
};

export default SiderContract;