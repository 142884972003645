import React from 'react';

const Column = ({
  activeColumns,
  children,
  column,
  offsetX,
  offsetY,
  toggleElem,
  closeNextColumns,
  nbColumns,
  scrollOnCreate,
}) => {
  const childrenArray = React.Children.toArray(children).filter((child) => child);

  return (
    <>
      {childrenArray.map((child, index) => {
        const gridColumn = Math.ceil(nbColumns / 2) + column - activeColumns.length;
        return (
          <child.type
            callbackRef={index === 0 && column - activeColumns.length === 0 ? scrollOnCreate : undefined}
            subColumnProps={{
              activeColumns,
              column: column + 1,
              offsetX: offsetX + 1,
              toggleElem,
              closeNextColumns,
              nbColumns,
              scrollOnCreate,
            }}
            activeColumns={activeColumns}
            key={child.props.id?.replaceAll('-', '_')}
            active={child.props.id?.replaceAll('-', '_') === activeColumns[column] ? true : undefined}
            toggleElem={() => toggleElem(column, child.props.id?.replaceAll('-', '_'))}
            closeNextColumns={() => closeNextColumns(column)}
            gridColumn={gridColumn}
            gridRow={index + offsetY}
            nbColumns={nbColumns}
            {...child.props}
          />
        );
      })}
    </>
  );
};

export default Column;
