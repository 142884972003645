import React, {useEffect, useState} from 'react';
import {Form, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import FormButtons from '../Common/FormButtons/FormButtons';
import {Block} from '../../../../../../Common/Sider';

const MerchantStoreIdBranchInput = (props) => {
  const { handleChange, removeBranch, branch, splitter } = props;

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    form.setFieldValue('merchantStore', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  return (
    <Block
      title={`${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.merchantStoreId.label`)} condition`}
      description='Fill with IDs'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange(values.merchantStore);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true)
        }}
      >
        <Form.Item
          name='merchantStore'
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                if (!getFieldValue('merchantStore').length) {
                  return Promise.reject(new Error('You must type at least one ID'));
                }
                let isOk = true;
                getFieldValue('merchantStore').forEach(value => {
                  if (value.replaceAll(' ', '') !== value.replaceAll(/[^a-zA-Z0-9_-]/gi, '')) {
                    isOk = false;
                  }
                })
                return isOk ? Promise.resolve() : Promise.reject(new Error('Characters should be alphanumeric (A-Z, a-z, 0-9), _ or -'));
              }
            })
          ]}
        >
          <Select
            required
            placeholder='Merchant store IDs'
            mode='tags'
            style={{ width: '100%' }}
            options={[]}
            placement='topLeft'
            listHeight={210}
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default MerchantStoreIdBranchInput;