import React, { useContext } from 'react';
import { Form } from 'antd';
import MerchantContractStatus from './MerchantContractStatus/MerchantContractStatus';
import MerchantContractSettlementCurrency from './MerchantContractSettlementCurrency/MerchantContractSettlementCurrency';
import MerchantContractChannels from './MerchantContractChannels/MerchantContractChannels';
import MerchantContractAuthentication from './MerchantContractAuthentication/MerchantContractAuthentication';
import MerchantContractRiskAssessment from './MerchantContractRiskAssessment/MerchantContractRiskAssessment';
import { Block } from '../../../../../../Common/Sider';
import MerchantContractId from './MerchantContractId/MerchantContractId';
import { ContractEditionContext } from '../../../../PaymentNetwork';
import styles from '../ContractForm.module.scss';
import SOURCES from '../../../../../../../../constants/SOURCES';
import {MerchantContractContext} from '../../MerchantContractDrawer';
import MerchantContractPayinContract from './MerchantContractPayinContract/MerchantContractPayinContract';

const GeneralSettings = () => {
  const { isEdited } = useContext(ContractEditionContext);
  const { merchantContract } = useContext(MerchantContractContext);

  return (
    <Block title="General settings" description={isEdited && 'Set up your contract'}>
      <Form layout="vertical" className={styles.drawerForm}>
        <MerchantContractStatus />
        <MerchantContractId />
        {merchantContract?.type === 'payin' ? (
          <>
            <MerchantContractSettlementCurrency />
            <MerchantContractChannels />
          </>
        ) : null}
        {merchantContract?.source === SOURCES.STACK && merchantContract?.type === 'payin' ? (
          <>
            <MerchantContractAuthentication />
            <MerchantContractRiskAssessment />
          </>
        ) : null}
        {merchantContract?.source === SOURCES.INSIGHTS_POS && merchantContract?.type === 'cashier' ? (
          <MerchantContractPayinContract />
        ) : null}
      </Form>
    </Block>
  );
};

export default GeneralSettings;
