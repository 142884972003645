import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Col, List, Row, Space, Typography } from 'antd';
import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import SourceBranchIcon from '@2fd/ant-design-icons/lib/SourceBranch';
import { useMutation, useQuery } from '@apollo/client';
import challengePreferences from 'norbr-shared-lib/constants/strongCustomerAuthentication/challengePreferences';
import exemptionReasons from 'norbr-shared-lib/constants/strongCustomerAuthentication/exemptionReasons';
import referenceCurrencies from 'norbr-shared-lib/constants/referenceCurrencies';
import fields from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/scaRuleConditionFields';
import FieldTypes from 'norbr-shared-lib/constants/merchantAccounts/fallbackSettings/scaRuleConditionFields/types';
import { Link } from '../../../../../util/navigate';
import { useCan } from '../../../../../contexts/ability.context';
import { useCompany } from '../../../../../contexts/app.context';
import { reorder } from '../../../../../util/array';
import { omitTypename } from '../../../../../util/object';
import COUNTRIES, { CountryFlag } from '../../../../../constants/COUNTRIES';
import Page from '../../../Common/Page/Page';
import SiderPage from '../../../Common/SiderPage/SiderPage';
import Loader from '../../../Common/Loader/Loader';
import SortableList from '../../../Common/SortableList/SortableList';
import SortableItem from '../../../Common/SortableList/SortableItem';
import Handle from '../../../Common/SiderList/Handle';
import MerchantAccountSelector from '../../MerchantAccountSelector/MerchantAccountSelector';
import { useMerchantAccount } from '../../merchantAccount.context';
import { companyListsQuery } from '../../Lists/query';
import NavButtons from '../NavButtons';
import styles from '../Optimizer.module.scss';
import { FallbackSettingsMutation, FallbackSettingsQuery } from '../query';
import SiderForm from './SiderForm';
import { ResultType } from './constants';
import { formatEndUnderline } from '../../../Brainpower/Common/utils';

const ConditionText = ({ condition, currency, operator }) => {
  const { field, value, excluded, list } = condition;

  const [company] = useCompany();
  const listsQuery = useQuery(companyListsQuery, { variables: { id: company } });

  if (!condition || !fields[field]) return null;

  const content = [];
  let title;

  if (operator) content.push(operator);
  content.push(`if ${fields[field].label} is`);
  if (excluded) content.push('not');

  switch (fields[field].type) {
    case FieldTypes.AMOUNT_RANGE:
      if (value.min == null) content.push(`under ${value.max} ${currency.symbol}`);
      else if (value.max == null) content.push(`above ${value.min} ${currency.symbol}`);
      else content.push(`between ${value.min} and ${value.max} ${currency.symbol}`);
      break;
    case FieldTypes.MULTI_SELECT:
      switch (fields[field].references) {
        case 'countries':
          if (value.length === 1) content.push(<CountryFlag value={value} />, COUNTRIES[value].label);
          else {
            title = value.map((c) => COUNTRIES[c].label).join(', ');
            content.push('in :', ...value.map((c) => <CountryFlag value={c} hideTitle />));
          }
          break;
        default:
          if (value.length === 1) content.push(fields[field].options.find((o) => o.id === value[0]).label);
          else {
            title = value.map((v) => fields[field].options.find((o) => o.id === v).label).join(', ');
            content.push('in :', title);
          }
          break;
      }
      break;

    case FieldTypes.MULTI_INPUT:
      if (list) {
        content.push(
          'in',
          <Link key="link-to-list" to={`/router/lists/${value}`}>
            <Typography.Text code>
              <UnorderedListOutlined />
              &nbsp;
              {listsQuery.data?.merchantCompany.lists.find((l) => l.id === value)?.name ?? '...'}
            </Typography.Text>
          </Link>,
        );
        break;
      }

      title = value.join(', ');
      content.push('in :', title);
      break;
    default:
      break;
  }

  return (
    <Typography.Text type="secondary" ellipsis title={title}>
      {content.map((c) => [c, ' '])}
    </Typography.Text>
  );
};

const ScaRules = () => {
  const can = useCan();
  const { selectedMerchantAccount } = useMerchantAccount();

  const { data, loading } = useQuery(FallbackSettingsQuery, {
    variables: { id: selectedMerchantAccount },
    skip: !selectedMerchantAccount,
  });

  const [updateSettings] = useMutation(FallbackSettingsMutation);

  const [openId, setOpenId] = useState(null);
  const handleOpenId = (ruleId) => () => setOpenId(ruleId);
  const handleClose = () => setOpenId(null);

  const rules = useMemo(() => omitTypename(data?.merchantAccount.fallbackSettings.rules) || [], [data]);

  const handleSort = useCallback(
    (sourceIndex, destinationIndex) => {
      // reorder rules
      const newRules = reorder(rules, sourceIndex, destinationIndex);
      updateSettings({
        variables: {
          id: selectedMerchantAccount,
          input: {
            rules: newRules,
          },
        },
        optimisticResponse: {
          updateMerchantAccountFallbackSettings: {
            __typename: 'MerchantAccount',
            id: selectedMerchantAccount,
            fallbackSettings: {
              ...data.merchantAccount.fallbackSettings,
              rules: newRules,
            },
          },
        },
      });
    },
    [rules, updateSettings],
  );

  const currency = referenceCurrencies[data?.merchantAccount.reference_currency ?? 'EUR'];

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <SourceBranchIcon />
          {formatEndUnderline('Optimizer')}
        </Space>
      }
      headerContent={<MerchantAccountSelector />}
      centerHeader
    >
      <SiderPage
        defaultOpen
        isSiderHidden={openId === null}
        siderContent={<SiderForm key={openId} ruleId={openId} onClose={handleClose} />}
        hideToggle
      >
        <div className={classNames({ [styles.noMerchantPage]: !selectedMerchantAccount })}>
          {!selectedMerchantAccount && (
            <div className={styles.noMerchantMessage}>Please select a merchant account in the selector above</div>
          )}
          {!data ? (
            <div className={styles.loader}>
              <Loader size="large" />
            </div>
          ) : (
            [
              <NavButtons key="nav-buttons" />,
              <Row key="body" className={styles.rowList} justify="space-around">
                <Col md={16} lg={12} xl={8}>
                  {can('update', 'fallback-settings') && (
                    <Button
                      className={styles.editButton}
                      icon={<PlusCircleOutlined />}
                      size="large"
                      block
                      onClick={handleOpenId(-1)}
                    >
                      Create a new SCA rule
                    </Button>
                  )}
                  <List itemLayout="vertical" loading={loading}>
                    <SortableList droppableId="scaRules" type="scaRule" label="SCA Rules" onSort={handleSort}>
                      {data?.merchantAccount.fallbackSettings.rules.map((rule, index) => (
                        <SortableItem
                          key={`rule-${index + 1}`}
                          id={`rule-${index + 1}`}
                          index={index}
                          isDragDisabled={!can('update', 'fallback-settings')}
                        >
                          <List.Item
                            className={styles.listItem}
                            size="large"
                            onClick={handleOpenId(index)}
                            extra={
                              can('update', 'fallback-settings') && (
                                <div className={styles.dragIcon} title="Drag rules to manage order priorities">
                                  <div className={styles.listIndexIndicator}>{index + 1}</div>
                                  <Handle />
                                </div>
                              )
                            }
                          >
                            <List.Item.Meta
                              title={rule.name}
                              description={
                                <Typography.Text type="secondary" ellipsis title={rule.description}>
                                  {rule.description}
                                </Typography.Text>
                              }
                            />
                            <div style={{ display: 'grid' }}>
                              <ConditionText condition={rule.condition_1} currency={currency} />
                              {rule.condition_2 && (
                                <ConditionText
                                  condition={rule.condition_2}
                                  currency={currency}
                                  operator={rule.logical_operator}
                                />
                              )}
                              <Typography.Text type="secondary" ellipsis>
                                then&nbsp;
                                {rule.result.type === ResultType.CHALLENGE_PREFERENCE &&
                                  `Challenge - ${challengePreferences[rule.result.value].label}`}
                                {rule.result.type === ResultType.SCA_EXEMPTION_REASON &&
                                  `Exemption reason - ${exemptionReasons[rule.result.value].label}`}
                              </Typography.Text>
                            </div>
                          </List.Item>
                        </SortableItem>
                      ))}
                    </SortableList>
                  </List>
                </Col>
              </Row>,
            ]
          )}
        </div>
      </SiderPage>
    </Page>
  );
};

export default ScaRules;
