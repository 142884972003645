import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import { useTranslation } from 'react-i18next';
import SelectBranchInput from '../Common/SelectBranchInput/SelectBranchInput';
import {Block} from '../../../../../../Common/Sider';
import FormButtons from '../Common/FormButtons/FormButtons';

const AuthenticationLevelBranchInput = (props) => {
  const { splitter, branch, handleChange, removeBranch } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const AUTH_LEVELS = [
    {
      value: '3ds',
    },
    {
      value: 'no3ds',
    },
  ];

  useEffect(() => {
    form.setFieldValue('levels', branch?.condition ?? []);
    setHasChanged(false);
  }, [branch])

  const label = t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.authenticationLevel.label`);

  const disabledOptions = branch ? [] : splitter.branches.reduce((acc, branchElem) => [...acc, ...branchElem.condition], []);

  return (
    <Block
      title={`${label} condition`}
      description='Select levels in the following list'
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          handleChange([values.levels]);
          setHasChanged(false);
        }}
        onValuesChange={() => {
          setHasChanged(true)
        }}
      >
        <Form.Item name='levels'>
          <SelectBranchInput
            options={AUTH_LEVELS?.map((authLevel) => ({
              value: authLevel.value,
              label: t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.authenticationLevel.options.${authLevel.value}`),
              search: `${authLevel.value} ${t(`andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.authenticationLevel.options.${authLevel.value}`)}`,
              disabled: disabledOptions.includes(authLevel.value)
            })).filter((authLevel) => authLevel.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)}
            placeholder="Select levels in the list"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            unique
          />
        </Form.Item>
        <FormButtons
          branch={branch}
          splitter={splitter}
          removeBranch={removeBranch}
          showSave={hasChanged}
        />
      </Form>
    </Block>
  );
};

export default AuthenticationLevelBranchInput;
