import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useMutation as useApolloMutation } from '@apollo/client/react/hooks/useMutation';
import Column from '../../../../../Common/NavigationContainer/Column/Column';
import TextInputElem from '../../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import ButtonElem from '../../../../../Common/NavigationContainer/Column/ButtonElem/ButtonElem';
import { useMerchantAccount } from '../../../../merchantAccount.context';
import { ColumnsContext, MutatingContext } from '../../../../../Common/NavigationContainer/NavigationContainer';
import { createApiKeyMutation } from './query';
import SelectElem from '../../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';

const APICreation = (props) => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const { activeColumns, setActiveColumns } = useContext(ColumnsContext);

  const [createApiKey, { loading }] = useApolloMutation(createApiKeyMutation);

  const [name, setName] = useState('');
  const [privacy, setPrivacy] = useState(null);

  const { setIsMutating } = useContext(MutatingContext);

  useEffect(() => {
    setIsMutating(loading);
    return () => {
      setIsMutating(false);
    };
  }, [loading]);

  return (
    <Column id="addCredentials" {...props}>
      <TextInputElem
        id="apiCredentialsName"
        key="apiCredentialsName"
        title="API key name"
        autoFocus
        value={name}
        handleChange={(e) => setName(e.target.value)}
      />
      <SelectElem
        title="Privacy"
        options={[
          {
            name: 'Public',
            id: 'public',
          },
          {
            name: 'Private',
            id: 'private',
          },
        ]}
        value={privacy}
        handleChange={(value) => setPrivacy(value)}
        placeholder="Select public or private"
      />
      {name && privacy && (
        <ButtonElem
          key="apiKeyCreateButton"
          handleClick={() =>
            createApiKey({
              variables: {
                merchantAccount: selectedMerchantAccount,
                input: {
                  name,
                  is_public: privacy === 'public',
                },
              },
            }).then(({ data }) => {
              message.success('Successfully created');
              const newId =
                data?.addApiKeyMerchantAccount?.api_keys?.[data?.addApiKeyMerchantAccount?.api_keys.length - 1]?.id;
              setActiveColumns([...activeColumns.slice(0, activeColumns.indexOf('addCredentials')), newId]);
            })
          }
          title="Create"
        />
      )}
    </Column>
  );
};

export default APICreation;
