import React, {useEffect, useState} from 'react';
import FileDocumentEditIcon from '@2fd/ant-design-icons/lib/FileDocumentEdit';
import {Tooltip} from 'antd';
import BlockHelperIcon from '@2fd/ant-design-icons/lib/BlockHelper';
import classnames from 'classnames';
import {useLazyQuery} from '@apollo/client';
import styles from '../Content.module.scss';
import {partnerQuery} from './query';

const ContractContent = (props) => {
  const { contract, isActive, isParentActive, blocking } = props;
  const [partner, setPartner] = useState(contract?.partner);

  const [getPartner] = useLazyQuery(partnerQuery, {
    onCompleted: (response) => setPartner(response.partner)
  });

  useEffect(() => {
    if (contract?.partner) {
      getPartner({
        variables: {
          id: contract.partner.id ?? contract.partner
        }
      })
    }
    if (!contract) {
      setPartner(null);
    }
  }, [contract])

  if (blocking) {
    return (
      <div
        role='presentation'
        className={classnames(styles.root, {[styles.active]: isActive}, {[styles.parentActive]: isParentActive})}
      >
        <div
          className={styles.icon}
        >
          <BlockHelperIcon
            style={{
              color: 'rgb(245, 34, 45)'
            }}
          />
        </div>
        <div
          className={styles.text}
        >
          Block payment process
        </div>
      </div>
    )
  }

  return (
    <div
      key={contract?.id}
      role='presentation'
      className={classnames(styles.root, {[styles.active]: isActive}, {[styles.parentActive]: isParentActive})}
    >
      {partner?.company?.theme?.icon ? (
        <div
          className={styles.img}
        >
          <img src={partner.company.theme.icon} width={32} height={32} alt={partner.name}/>
        </div>
      ) : (
        <div
          className={styles.icon}
        >
          <FileDocumentEditIcon />
        </div>
      )}
      <div
        className={styles.text}
      >
        <div
          className={styles.textContent}
        >
          <Tooltip
            title={contract?.name ? null : contract?.id || contract?._id}
          >
            <div
              className={styles.title}
            >
              {contract ? contract?.name || contract?.id || contract?._id : 'Select contract'}
            </div>
          </Tooltip>
          <div
            className={styles.type}
          >
            {partner?.name ?? 'Services'}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ContractContent;