import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Drawer, Form, Input, message, Select, Space, Switch } from 'antd';

import { CompanyMerchantAccountListQuery, UserCreateMutation } from './query';
import { search } from '../../../../../util/string';
import { useMe } from '../../../../../contexts/me.context';
import { ProfileListQuery } from '../../../Admin/AccessControl/query';
import { useResetFormOnCloseModal } from '../../../Common/Form/utils';
import styles from './UserCreate.module.scss';
import { compareByProp } from '../../../../../util/array';
import useNavigateWithSearch from '../../../../../util/navigate';
import { useProgramManagerId } from '../../../../../contexts/app.context';
import { useCan } from '../../../../../contexts/ability.context';

const UserCreate = ({ visible, onCancel }) => {
  const navigate = useNavigateWithSearch();
  const [programManagerId] = useProgramManagerId();

  const [form] = Form.useForm();

  const me = useMe();
  const can = useCan();

  const profilesQuery = useQuery(ProfileListQuery, {
    context: {
      headers: {
        company: me.companies[0].company.id,
      },
    },
  });

  const companyMerchantAccountListQuery = useQuery(CompanyMerchantAccountListQuery, {
    variables: { companyId: me.companies[0].company.id },
  });

  const [create, { loading }] = useMutation(UserCreateMutation, {
    context: { headers: { company: me.companies[0].company.id } },
    refetchQueries: ['TeamUserListQuery'],
    onCompleted: ({ createMerchantUser }) => {
      message.success('User successfully created');
      navigate(`/teams/users/${createMerchantUser.id}`);
      onCancel();
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error(error.message);
    },
  });

  useResetFormOnCloseModal({
    form,
    visible,
  });

  const submit = (values) => {
    create({
      variables: {
        input: {
          ...values,
          company: me.companies[0].company.id,
          programManager: programManagerId,
        },
      },
    });
  };

  return (
    <Drawer
      title="Create user"
      open={visible}
      onClose={onCancel}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 350, width: '80%' }} // responsive width
      width="100%"
    >
      <Form form={form} layout="vertical" name="userForm" onFinish={submit} className={styles.userForm}>
        <Form.Item name="email" label="Email" rules={[{ required: true }, { type: 'email' }]}>
          <Input placeholder="email@address.com" />
        </Form.Item>
        <Form.Item name="first_name" label="Firstname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label="Lastname" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone">
          <Space>
            <Form.Item name={['phone', 'code']} noStyle>
              <Input style={{ width: 65 }} placeholder="31" prefix="+" />
            </Form.Item>
            <Form.Item name={['phone', 'number']} noStyle>
              <Input placeholder="123456789" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item name="authorizedMerchantAccounts" label="Authorized merchant accounts" initialValue={[]}>
          <Select
            loading={companyMerchantAccountListQuery.loading}
            options={companyMerchantAccountListQuery.data?.merchantCompany.merchantAccounts
              .map(({ id, name }) => ({
                value: id,
                label: name,
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
            mode="multiple"
            showSearch
            filterOption={(inputValue, option) => search(inputValue, option.label)}
          />
        </Form.Item>
        <Form.Item
          name="aclProfile"
          label="Profile"
          rules={[{ required: true }]}
          initialValue={!can('update-rights', 'user') ? me.companies[0].aclProfile.id : undefined}
        >
          <Select
            disabled={!can('update-rights', 'user')}
            loading={profilesQuery.loading}
            options={profilesQuery.data?.merchantAclProfiles
              .map((p) => ({
                value: p.id,
                label: p.name,
              }))
              .sort(compareByProp('label', 'asc', 'string'))}
          />
        </Form.Item>
        <Form.Item name="isGdpr" label="Access Personal Data" valuePropName="checked">
          <Switch defaultChecked={false} disabled={!can('update-rights', 'user')} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button type="text" disabled={loading} onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" size="large" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserCreate;
