import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Space } from 'antd';
import AccountMultipleIcon from '@2fd/ant-design-icons/lib/AccountMultiple';
import { NavigateWithSearch } from '../../../util/navigate';
import Page from '../Common/Page/Page';
import UserList from './UserList/UserList';
import AccountList from './AccountList/AccountList';
import { formatEndUnderline } from '../Brainpower/Common/utils';

const Teams = () => (
  <Page
    title={
      <Space style={{ fontSize: 20 }}>
        <AccountMultipleIcon />
        {formatEndUnderline('Teams')}
      </Space>
    }
  >
    <Routes>
      <Route path="users/:userId" element={<UserList />} />
      <Route path="users" element={<UserList />} />
      <Route path="accounts/:accountId" element={<AccountList />} />
      <Route path="accounts" element={<AccountList />} />
      <Route path="*" element={<NavigateWithSearch to="users" />} />
    </Routes>
  </Page>
);

export default Teams;
