import React from 'react';
import classnames from 'classnames';
import { Space } from 'antd';
import BellIcon from '@2fd/ant-design-icons/lib/Bell';
import Column from '../../Common/NavigationContainer/Column/Column';
import Elem from '../../Common/NavigationContainer/Column/Elem/Elem';
import NavigationContainer from '../../Common/NavigationContainer/NavigationContainer';
import styles from './APINotifications.module.scss';
import Integration from './Integration/Integration';
import Security from './Security/Security';
import Notifications from './Notifications/Notifications';
import Urls from './Urls/Urls';
import { useCan } from '../../../../contexts/ability.context';
import { useMerchantAccount } from '../merchantAccount.context';
import Page from '../../Common/Page/Page';
import MerchantAccountSelector from '../MerchantAccountSelector/MerchantAccountSelector';
import { formatEndUnderline } from '../../Brainpower/Common/utils';
import PaymentProcedures from './PaymentProcedures/PaymentProcedures';
import HostedPageSettings from './HostedPageSettings/HostedPageSettings';

const APINotifications = () => {
  const { selectedMerchantAccount } = useMerchantAccount();

  const can = useCan();

  return (
    <Page
      title={
        <Space style={{ fontSize: 20 }}>
          <BellIcon />
          {formatEndUnderline('API & Notifications')}
        </Space>
      }
      headerContent={<MerchantAccountSelector stackOnly />}
      centerHeader
    >
      <div
        className={classnames(styles.apiNotifications, {
          [styles.noMerchantAccount]: !selectedMerchantAccount,
        })}
      >
        {!selectedMerchantAccount && (
          <div className={styles.noMerchantMessage}>Please select a merchant account in the selector above</div>
        )}
        <NavigationContainer
          columnSteps={{
            md: 5,
            sm: 3,
          }}
          navigationId="api"
        >
          <Column id="root">
            {can('read', 'integration') && (
              <Elem
                id="integration"
                title="Integration environment"
                value="CMS, version, integration type"
                subColumn={<Integration />}
              />
            )}
            {can('read', 'api_key') && (
              <Elem
                id="security"
                title="Security"
                value="API credentials, authentication"
                subColumn={<Security />}
              />
            )}
            {can('read', 'webhook') && (
              <Elem
                id="notifications"
                title="Notifications"
                value="Set up notifications, retry policy"
                subColumn={<Notifications />}
              />
            )}
            {can('read', 'payment-procedure') && (
              <Elem
                id="paymentProcedures"
                title="Payment procedures"
                value="Edit your payment procedures"
                subColumn={<PaymentProcedures />}
              />
            )}
            {can('read', 'hosted-page-settings') && (
              <Elem
                id="hostedPageSettings"
                title="Hosted page settings"
                value="Configure the hosted page layout"
                subColumn={<HostedPageSettings />}
              />
            )}
            {can('read', 'redirection_url') && (
              <Elem
                id="redirectUrls"
                title="Redirect URLs"
                value="Status, signature and passphrase"
                subColumn={<Urls />}
              />
            )}
          </Column>
        </NavigationContainer>
      </div>
    </Page>
  );
};

export default APINotifications;
