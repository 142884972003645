import React, { useCallback, useEffect } from 'react';
import { useMutation as useApolloMutation } from '@apollo/client';
import { useLazyQuery as useApolloLazyQuery } from '@apollo/client/react/hooks/useLazyQuery';
import SelectElem from '../../../../../Common/NavigationContainer/Column/SelectElem/SelectElem';
import Column from '../../../../../Common/NavigationContainer/Column/Column';
import TextInputElem from '../../../../../Common/NavigationContainer/Column/TextInputElem/TextInputElem';
import { useCan } from '../../../../../../../contexts/ability.context';
import { apiKeyQuery, updateApiKeyMutation } from './query';
import Elem from '../../../../../Common/NavigationContainer/Column/Elem/Elem';

const CredentialForm = ({ apiKey: { id }, isLoading, ...props }) => {
  const can = useCan();

  const [updateApiKey] = useApolloMutation(updateApiKeyMutation);

  const [fetchKey, { data: { apiKey } = { apiKey: null } }] = useApolloLazyQuery(apiKeyQuery);

  useEffect(() => {
    fetchKey({
      variables: {
        id,
      },
    });
  }, [id]);

  const updateApiKeyField = (input) =>
    updateApiKey({
      variables: {
        id: apiKey.id,
        input,
      },
      optimisticResponse: {
        updateApiKey: {
          ...apiKey,
          ...input,
        },
      },
    });

  const scrollOnCreate = useCallback(
    (node) => {
      node?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    },
    [apiKey?.status],
  );

  return (
    <Column id={`editCredential${apiKey?.id}`} {...props}>
      <TextInputElem
        callbackRef={scrollOnCreate}
        id="apiCredentialsName"
        key="apiCredentialsName"
        title="API key name"
        defaultValue={apiKey?.name}
        handleValid={(value) => {
          if (value !== apiKey?.name) {
            updateApiKeyField({
              name: value,
            });
          }
        }}
        locked={!can('update', 'api_key')}
      />
      <SelectElem
        id="privacy"
        key="privacy"
        title="Privacy"
        value={apiKey?.is_public ? 'public' : 'private'}
        options={[
          {
            name: 'Private',
            id: 'private',
          },
          {
            name: 'Public',
            id: 'public',
          },
        ]}
        locked
      />
      <SelectElem
        id={`${apiKey?.id}-status`}
        key={`${apiKey?.id}-status`}
        title="Status"
        options={[
          {
            id: 'active',
            name: 'Active',
          },
          {
            id: 'inactive',
            name: 'Inactive',
          },
        ]}
        value={apiKey?.status}
        handleChange={(value) => {
          if (value !== apiKey?.status) {
            updateApiKeyField({
              status: value,
            });
          }
        }}
        isLoading={isLoading}
        allowClear={false}
        locked={!can('update', 'api_key')}
      />
      <Elem
        id="value"
        key="value"
        title="API key"
        value={apiKey?.key}
        canCopy
      />
    </Column>
  );
};

export default CredentialForm;
