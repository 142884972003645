import React from 'react';
import { Skeleton } from 'antd';

const CheckboxSkeleton = () => (
  <div
    style={{
      marginBottom: 8,
    }}
  >
    <Skeleton.Button
      active
      size="small"
      shape="square"
      block={false}
      style={{
        width: 24,
        height: 24,
      }}
    />
    <Skeleton.Button
      active
      size="small"
      shape="square"
      block={false}
      style={{
        marginLeft: 8,
        width: Math.floor(Math.random() * 220 + 40),
        height: 24,
      }}
    />
  </div>
);

export default CheckboxSkeleton;
