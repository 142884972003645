import React, {useState} from 'react';
import {Button, DatePicker, Drawer, Form, Input, Radio, Select, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from '@apollo/client';
import CloseCircleIcon from '@2fd/ant-design-icons/lib/CloseCircle';
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import currencyList from 'norbr-shared-lib/constants/currencies/list';
import issuerList from 'norbr-shared-lib/constants/issuers/list';
import {paymentMethodsQuery, testRouterMutation} from './query';
import {useMerchantAccount} from '../../../merchantAccount.context';
import {
  AUTHENTICATION_INDICATOR_LIST
} from '../../../../../../constants/AUTHENTICATION_INDICATORS';
import {COUNTRY_LIST} from '../../../../../../constants/COUNTRIES';
import { compareByProp } from '../../../../../../util/array';
import TestRouterResult from './TestRouterResult/TestRouterResult';
import MultiSelectDropdown from '../../../../Common/MultiSelectDropdown/MultiSelectDropdown';
import styles from './TestRouterDrawer.module.scss';

const TestRouterDrawer = ({visible, onClose}) => {
  const { t } = useTranslation();
  const { merchantAccount } = useMerchantAccount();
  const [form] = Form.useForm();

  const [optionalFieldsShown, setOptionalFieldsShown] = useState([]);

  const {data: { paymentMethods } = { paymentMethods: [] }, loading: loadingPaymentMethods } = useQuery(paymentMethodsQuery);

  const [testRouter, { loading: loadingTest, data: { testRouter: testRouterData } = { testRouter: undefined } }] = useMutation(testRouterMutation);

  const optionalFields = [
    {
      key: 'card_issuer',
      component:
        <Form.Item
          key='card_issuer'
          name='card_issuer'
          label={t('testRouter.card_issuer')}
          hidden={!optionalFieldsShown.includes('card_issuer')}
          className={styles.optionalInput}
        >
          <Select
            options={[
              {
                value: 'UNKNOWN',
                label: 'None',
              },
              ...issuerList?.map(({ id, label: issuerLabel }) => ({
                value: id,
                label: issuerLabel,
              }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))
            ]}
            showSearch
            optionFilterProp='label'
          />
        </Form.Item>,
    },
    {
      key: 'card_6_digits',
      component:
        <Form.Item
          name='card_6_digits'
          label={t('testRouter.card_6_digits')}
          hidden={!optionalFieldsShown.includes('card_6_digits')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>,
    },
    {
      key: 'merchant_store_id',
      component:
        <Form.Item
          name='merchant_store_id'
          label={t('testRouter.merchant_store_id')}
          hidden={!optionalFieldsShown.includes('merchant_store_id')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>,
    },
    {
      key: 'customer_id',
      component:
        <Form.Item
          name='customer_id'
          label={t('testRouter.customer_id')}
          hidden={!optionalFieldsShown.includes('customer_id')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>,
    },
    {
      key: 'customer_email',
      component:
        <Form.Item
          name='customer_email'
          label='Customer email'
          hidden={!optionalFieldsShown.includes('customer_email')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>,
    },
    {
      key: 'customer_ip',
      component:
        <Form.Item
          name='customer_ip'
          label='Customer IP'
          hidden={!optionalFieldsShown.includes('customer_ip')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>,
    },
    {
      key: 'basket_content',
      component:
        <Form.Item
          label='Basket'
          hidden={!optionalFieldsShown.includes('basket_content')}
          className={styles.optionalInput}
        >
          <Form.List
            name='basket_content'
          >
            {(fields, {add, remove}) => (
              <Space
                direction='vertical'
                style={{
                  width: '100%',
                }}
              >
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex' }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'product_sku']}
                      rules={[{ required: true, message: 'Product SKU is required.' }]}
                      noStyle
                    >
                      <Input placeholder='Product SKU' />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item
                  noStyle
                >
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add product
                  </Button>
                </Form.Item>
              </Space>
            )}
          </Form.List>
        </Form.Item>,
    },
    {
      key: 'customer_zip_code',
      component:
        <Form.Item
          name='customer_zip_code'
          label='Customer zip code'
          hidden={!optionalFieldsShown.includes('customer_zip_code')}
          className={styles.optionalInput}
        >
          <Input />
        </Form.Item>
    },
    {
      key: 'merchant_data',
      component:
        <Form.Item
          label='Merchant data'
          hidden={!optionalFieldsShown.includes('merchant_data')}
          className={styles.optionalInput}
        >
          <Form.List
            name='merchant_data'
          >
            {(fields, {add, remove}) => (
              <Space
                direction='vertical'
                style={{
                  display: 'flex',
                }}
              >
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: 8,
                        marginBottom: 0,
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'key']}
                        rules={[{ required: true, message: 'Merchant data key is required.' }]}
                        style={{
                          width: 120,
                          marginBottom: 0,
                        }}
                      >
                        <Input
                          placeholder='Key'
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        rules={[{ required: true, message: 'Merchant data type is required.' }]}
                        style={{
                          width: 100,
                          marginBottom: 0,
                        }}
                      >
                        <Select
                          placeholder='Field type'
                          options={[
                            {
                              value: 'number',
                              label: 'Number',
                            },
                            {
                              value: 'boolean',
                              label: 'Boolean',
                            },
                            {
                              value: 'date',
                              label: 'Date',
                            },
                            {
                              value: 'string',
                              label: 'String',
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) => {
                          if (prevValues.merchant_data[index]?.type !== nextValues.merchant_data[index]?.type && prevValues?.merchant_data?.length === nextValues?.merchant_data?.length) {
                            form.setFieldValue(['merchant_data', index, 'value'], null);
                            return true;
                          }
                          return false;
                        }}
                        style={{
                          flexGrow: 1,
                          marginBottom: 0,
                        }}
                      >
                        {(({ getFieldValue }) => {
                          switch (getFieldValue(['merchant_data', index, 'type'])) {
                            case 'boolean':
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                  noStyle
                                  rules={[{ required: true, message: 'Merchant data value is required.' }]}
                                >
                                  <Radio.Group>
                                    <Radio.Button value='true'>True</Radio.Button>
                                    <Radio.Button value='false'>False</Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                              );
                            case 'string':
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                  noStyle
                                  rules={[{ required: true, message: 'Merchant data value is required.' }]}
                                >
                                  <Input />
                                </Form.Item>
                              );
                            case 'date':
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                  noStyle
                                  rules={[{ required: true, message: 'Merchant data value is required.' }]}
                                >
                                  <DatePicker />
                                </Form.Item>
                              );
                            case 'number':
                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                  noStyle
                                  rules={[{ required: true, message: 'Merchant data value is required.' }]}
                                >
                                  <Input type='number' />
                                </Form.Item>
                              );
                            default:
                              return null;
                          }
                        })}
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  )
                })}
                <Form.Item
                  noStyle
                >
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </Space>
            )}
          </Form.List>
        </Form.Item>,
    },
    {
      key: 'card_country',
      component:
        <Form.Item
          name='card_country'
          label='Card country'
          rules={[{ required: true }]}
          hidden={!optionalFieldsShown.includes('card_country')}
          className={styles.optionalInput}
        >
          <Select
            options={[
              {
                label: 'None',
                value: 'XX'
              },
              ...COUNTRY_LIST
            ]}
            showSearch
            optionFilterProp='label'
          />
        </Form.Item>,
    },
    {
      key: 'customer_ip_country',
      component:
        <Form.Item
          name='customer_ip_country'
          label='Customer IP country'
          rules={[{ required: true }]}
          hidden={!optionalFieldsShown.includes('customer_ip_country')}
          className={styles.optionalInput}
        >
          <Select
            options={[
              {
                label: 'None',
                value: 'XX'
              },
              ...COUNTRY_LIST
            ]}
            showSearch
            optionFilterProp='label'
          />
        </Form.Item>,
    },
  ];

  return (
    <Drawer
      title="Routing rules testing module"
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      contentWrapperStyle={{ maxWidth: 540, width: '80%' }} // responsive width
      width="100%"
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          testRouter({
            variables: {
              input: {
                ...values,
                merchant_account: merchantAccount.id,
                company_id: merchantAccount.company.id,
              },
            }
          })
        }}
        initialValues={{
          basket_content: [],
          card_issuer: 'UNKNOWN',
          card_country: 'XX',
          customer_ip_country: 'XX',
          merchant_data: [],
          payment_method_type: 'card',
          payment_channel: 'e-commerce',
          currency: merchantAccount.reference_currency,
        }}
      >
        <Form.Item
          name='payment_channel'
          label={t('testRouter.payment_channel')}
          rules={[{ required: true }]}
        >
          <Select
            options={['e-commerce', 'recurring', 'moto', 'pos'].map(pc => ({
              label: t(`andMe.paymentNetwork.merchantContract.channels.${pc}`),
              value: pc,
            }))}
          />
        </Form.Item>
        <Form.Item
          name='payment_method_name'
          label={t('testRouter.payment_method_name')}
          rules={[{ required: true }]}
        >
          <Select
            options={paymentMethods.map(pm => ({
              label: pm.name,
              value: pm.id,
            })).sort(compareByProp('label'))}
            loading={loadingPaymentMethods}
            showSearch
            optionFilterProp='label'
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, nextValues) => prevValues.payment_method_name !== nextValues.payment_method_name}
          noStyle
        >
          {(({ getFieldValue, setFieldValue }) => {
            setFieldValue('payment_method_type', paymentMethods?.find(pm => pm.id === getFieldValue('payment_method_name'))?.type)
            return (
              <Form.Item
                name='payment_method_type'
                label={t('testRouter.payment_method_type')}
                rules={[{required: true}]}
              >
                <Select
                  options={[...new Set(paymentMethods.map(pm => pm.type))].map(pmt => ({
                    label: t(
                      `andMe.paymentNetwork.routingMatrix.routingRules.ruleEditor.splitters.paymentMethodType.options.${pmt}`,
                    ),
                    value: pmt,
                  })).sort(compareByProp('label'))}
                  showSearch
                  optionFilterProp='label'
                  disabled
                />
              </Form.Item>
            )
          })}
        </Form.Item>
        <Space>
          <Form.Item
            name='reference_currency_amount'
            label={t('testRouter.reference_currency_amount')}
            rules={[{ required: true }]}
          >
            <Input type='number'/>
          </Form.Item>
          <Form.Item
            name='currency'
            label={t('testRouter.currency')}
            rules={[{ required: true }]}
          >
            <Select
              options={currencyList.map(cu => ({
                label: `[${cu.alpha}] ${cu.label} (${cu.symbol})`,
                value: cu.id,
              }))}
              showSearch
              optionFilterProp='label'
            />
          </Form.Item>
        </Space>
        <Form.Item
          name='authentication_indicator'
          label={t('testRouter.authentication_indicator')}
          rules={[{ required: true }]}
        >
          <Select
            options={AUTHENTICATION_INDICATOR_LIST}
          />
        </Form.Item>
        {optionalFields
          .sort((a, b) => optionalFieldsShown.indexOf(a.key) - optionalFieldsShown.indexOf(b.key))
          .map(field =>
            <div
              className={styles.optionalInputContainer}
            >
              {field.component}
              <Button
                className={styles.removeButton}
                icon={<CloseCircleIcon />}
                onClick={() => {
                  setOptionalFieldsShown(optionalFieldsShown.filter(fieldKey => fieldKey !== field.key))
                  form.resetFields([field.key])
                }}
                type="text"
                hidden={!optionalFieldsShown.includes(field.key)}
              />
            </div>
          )}
        <Form.Item>
          <MultiSelectDropdown
            options={optionalFields.map(field => ({
              key: field.key,
              label: t(`testRouter.${field.key}`),
            })).sort(compareByProp('label'))}
            onSelect={(value) => {
              if (value === 'merchant_data') {
                form.setFieldValue('merchant_data', [{}]);
              }
              setOptionalFieldsShown([
                ...optionalFieldsShown,
                value,
              ])
            }}
            onDeselect={(value) => {
              form.resetFields([value])
              setOptionalFieldsShown(optionalFieldsShown.filter(field => field !== value))
            }}
            value={optionalFieldsShown}
          >
            <Button block icon={<PlusCircleOutlined />} style={{ margin: '20px 0' }}>
              ADD FIELD
            </Button>
          </MultiSelectDropdown>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Space>
            <Button
              size="large"
              onClick={() => {
                setOptionalFieldsShown([])
                form.resetFields()
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loadingTest}
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {testRouterData !== undefined && <TestRouterResult data={testRouterData} />}
    </Drawer>
    )
};

export default TestRouterDrawer;