import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Skeleton } from 'antd';
import IconsContainer from '../Common/IconsContainer/IconsContainer';
import styles from './ImgUploaderElem.module.scss';
import ImgUploader from '../../../Uploader/ImgUploader';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const ImgUploaderElem = ({
  isError,
  isValid,
  isWarning,
  handleChange,
  title,
  gridColumn,
  gridRow,
  value,
  closeNextColumns,
  locked,
  defaultValue,
  isLoading,
  nbColumns,
  callbackRef,
  regexp,
  info,
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue ?? value ?? '');

  const [isRegexpError, setIsRegexpError] = useState(false);

  const prevGridColumn = usePrevious(gridColumn);

  // Temp trick to force re-render to refresh prevGridColumn
  const [, setTemp] = useState(null);

  useEffect(() => {
    setTemp({});
  }, [prevGridColumn, gridColumn]);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value || value === '') {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (regexp) {
      const error = !regexp.test(currentValue);
      setIsRegexpError(error);
    }
  }, [currentValue]);

  return (
    <div
      ref={callbackRef}
      className={classnames(styles.root, {
        [styles.isLoading]: isLoading,
        [styles.oob]: gridColumn <= 0,
        [styles.isColumnActive]: gridColumn === Math.ceil(nbColumns / 2),
        [styles.isAppearing]: prevGridColumn === undefined,
        [styles.isMovingLeft]: prevGridColumn - gridColumn < 0,
        [styles.isMovingRight]: prevGridColumn - gridColumn > 0,
      })}
      style={{
        gridRow,
        gridColumn,
      }}
      onClick={closeNextColumns}
      onKeyDown={closeNextColumns}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.title}>{title}</div>
      <div className={classnames(styles.inputContainer)}>
        {(() => {
          if (isLoading) {
            return (
              <div className={classnames(styles.inputRoot, styles.valueContainer)}>
                <Skeleton.Input
                  active
                  loading
                  style={{
                    width: 100,
                    height: '22px',
                  }}
                />
              </div>
            );
          }
          return (
            <>
              <div className={classnames(styles.inputRoot)}>
                <ImgUploader value={value} onChange={handleChange} info={info} disabled={locked} />
              </div>
              <IconsContainer
                isError={isError || isRegexpError}
                isValid={isValid}
                isWarning={isWarning}
                locked={locked}
              />
            </>
          );
        })()}
      </div>
    </div>
  );
};

export default ImgUploaderElem;
